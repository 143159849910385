import React from 'react';

const FinancialProgress: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.448 2.744h3.387A2.744 2.744 0 0018.09 0H3a2.744 2.744 0 002.744 2.744h6.202c1.374 0 2.29.47 2.865 1.075.194.203.358.43.49.676H8.1a2.744 2.744 0 002.744 2.744h9.603a2.745 2.745 0 00-2.194-2.689 6.099 6.099 0 00-.805-1.806zM15.06 8.917h3.061a5.976 5.976 0 01-1.323 2.19c-1.14 1.196-2.796 1.926-4.852 1.926H8.63c.27 2.372 1.245 4.135 2.65 5.418 1.143 1.043 2.865 1.812 4.752 2.3 1.438.373 2.41 1.828 1.974 3.249-3.28-.364-6.329-1.471-8.576-3.523-2.28-2.082-3.62-5.034-3.62-8.816 0-.758.615-1.372 1.373-1.372h4.763c1.374 0 2.29-.47 2.865-1.074.09-.095.173-.194.25-.298z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FinancialProgress;
