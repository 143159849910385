import React from 'react';

const Gallery = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.182 19.09a1.09 1.09 0 01-1.091 1.092H9a1.09 1.09 0 01-1.09-1.091V18h3.272v1.09zM15 0a1.09 1.09 0 011.091 1.09 3.273 3.273 0 003.273 3.274 1.09 1.09 0 110 2.181 3.273 3.273 0 00-3.273 3.273 1.09 1.09 0 11-2.182 0 3.273 3.273 0 00-3.273-3.273 1.09 1.09 0 110-2.181A3.273 3.273 0 0013.91 1.09C13.91.488 14.398 0 15 0zm0 4.364c-.31.413-.678.78-1.09 1.09.412.31.78.678 1.09 1.09.31-.412.678-.78 1.09-1.09A5.489 5.489 0 0115 4.365z"
        fill="currentColor"
      />
      <path
        d="M8.746 4.364H3v17.454C3 23.023 3.977 24 5.182 24h8.727a2.182 2.182 0 002.182-2.182v-10.11c-.321.186-.694.292-1.091.292s-.77-.106-1.09-.292v10.11H5.181V6.545h3.564a2.172 2.172 0 01-.291-1.09c0-.398.106-.77.291-1.091z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Gallery;
