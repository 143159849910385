import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useQuery } from 'react-apollo';
import { Avatar, Popover, Icon } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import { Omit } from 'utility-types';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { USER_AVATAR_QUERY } from './queries';
import {
  UserAvatarInfo,
  UserAvatarInfoVariables,
} from '../../types/UserAvatarInfo';
import useTheme from '../../hooks/useTheme';
import useMedia from '../../hooks/useMedia';

interface Props
  extends Omit<AvatarProps, 'src' | 'srcSet' | 'children' | 'alt'> {
  userId: string;
  hideTooltip?: boolean;
  tooltipPlacement?: TooltipPlacement;
}

const UserAvatar: React.FC<Props> = ({
  userId,
  className,
  style,
  hideTooltip,
  tooltipPlacement,
  ...restProps
}) => {
  const theme = useTheme();

  const { isDesktop } = useMedia();

  const { data } = useQuery<UserAvatarInfo, UserAvatarInfoVariables>(
    USER_AVATAR_QUERY,
    {
      variables: {
        id: userId,
      },
    },
  );

  const content = useMemo(() => {
    if (data) {
      if (data.user.profileImage) {
        return (
          <Avatar
            src={data.user.profileImage}
            alt={data.user.name}
            className={clsx('cursor-pointer object-cover', className)}
            {...restProps}
          />
        );
      }

      return (
        <Avatar
          alt={data.user.name}
          {...restProps}
          className={clsx('select-none cursor-pointer', className)}
          style={{
            fontSize: restProps.size === 24 ? 12 : undefined,
            backgroundColor: theme.primaryColor,
            ...style,
          }}
        >
          {data.user.name[0].toUpperCase()}
        </Avatar>
      );
    }
    return (
      <Avatar
        icon="user"
        className={clsx('cursor-pointer', className)}
        style={style}
        {...restProps}
      />
    );
  }, [className, data, restProps, style, theme.primaryColor]);

  if (isDesktop && !hideTooltip && data) {
    return (
      <Popover
        content={
          <div>
            <div className="text-gray-700 font-medium text-sm mb-2">
              {data.user.name}
            </div>

            <a href={`tel:+${data.user.phone}`} className="block mb-1">
              <div className="flex items-center text-gray-600">
                <Icon type="phone" className="mr-2" />
                <span className="text-xs">+{data.user.phone}</span>
              </div>
            </a>

            <a href={`mailto:${data.user.email}`}>
              <div className="flex items-center text-gray-600">
                <Icon type="mail" className="mr-2" />
                <span className="text-xs">{data.user.email}</span>
              </div>
            </a>
          </div>
        }
        placement={tooltipPlacement || 'top'}
      >
        {content}
      </Popover>
    );
  }

  return content;
};

UserAvatar.defaultProps = {
  size: 24,
  hideTooltip: false,
};

export default UserAvatar;
