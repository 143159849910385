import React, { cloneElement, useState, useCallback } from 'react';
import { Form, message, Drawer, InputNumber, DatePicker, Button } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useMutation, useApolloClient } from 'react-apollo';
import moment from 'moment';

import {
  CreateMRItem as CreateMRItemInterface,
  CreateMRItemVariables,
} from '../../../../../types/CreateMRItem';
import { MRItems, MRItemsVariables } from '../../../../../types/MRItems';
import ImageUploader from '../../../../../components/ImageUploader';
import {
  CREATE_MR_ITEM_MUTATION,
  MR_ITEMS_QUERY,
} from '../../../../../queries/mrItems';
import {
  PROJECTS_QUERY,
  PROJECT_BASIC_INFO_FRAGMENT,
} from '../../../../../queries/project';
import QueryAsyncSelect from '../../../../../components/QueryAsyncSelect';
import { Projects } from '../../../../../types/Projects';
import { ProjectBasicInfoFragment } from '../../../../../types/ProjectBasicInfoFragment';

interface Props {
  type: string;
  typeLabel: string;
  trigger: JSX.Element;
  form: WrappedFormUtils;
}

const CreateMRItem: React.FC<Props> = ({
  type,
  typeLabel,
  trigger,
  form: { resetFields, getFieldDecorator, validateFields, getFieldValue },
}) => {
  const client = useApolloClient();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
    resetFields();
  }, [resetFields]);

  const [createMRItemMutation, { loading }] = useMutation<
    CreateMRItemInterface,
    CreateMRItemVariables
  >(CREATE_MR_ITEM_MUTATION, {
    update: (cache, { data }) => {
      if (data) {
        let mrItems;
        try {
          mrItems = cache.readQuery<MRItems, MRItemsVariables>({
            query: MR_ITEMS_QUERY,
          });
        } catch (cacheReadError) {
          mrItems = undefined;
        }

        if (mrItems) {
          cache.writeQuery<MRItems, MRItemsVariables>({
            query: MR_ITEMS_QUERY,
            data: {
              mrItems: [...mrItems.mrItems, data.createMRItem],
            },
          });
        }
      }
    },
    onCompleted: () => {
      message.success(
        <div className="inline-flex space-x-4">
          <span>Refresh the page to see updated changes</span>
          <Button
            size="small"
            onClick={() => {
              window.location.reload();
            }}
            type="primary"
          >
            Update
          </Button>
        </div>,
        0,
      );
      handleDrawerClose();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields((fieldErrors, values) => {
        if (!fieldErrors) {
          const { entryDate, progress, documents, projectId } = values as {
            entryDate: moment.Moment;
            progress: number;
            projectId: string;
            documents?: Array<string>;
          };
          createMRItemMutation({
            variables: {
              projectId,
              type,
              entryDate: entryDate.valueOf(),
              progress,
              documents,
            },
          });
        }
      });
    },
    [createMRItemMutation, type, validateFields],
  );

  return (
    <>
      {cloneElement(trigger, { onClick: handleDrawerOpen })}
      <Drawer
        visible={open}
        title={`Create ${typeLabel} entry`}
        onClose={handleDrawerClose}
        destroyOnClose
        width={320}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Select Project">
            {getFieldDecorator('projectId', {
              rules: [{ required: true, message: 'Project is required' }],
            })(
              <QueryAsyncSelect
                query={PROJECTS_QUERY}
                placeholder="Select Project"
                extractOptions={(projects: Projects) => {
                  return projects.projects.map((project) => ({
                    value: project.id,
                    label: project.name,
                  }));
                }}
              />,
            )}
          </Form.Item>
          <Form.Item label={`${typeLabel} (in Km)`}>
            {getFieldDecorator('progress', {
              rules: [{ required: true, message: 'Progress is required' }],
              initialValue: getFieldValue('projectId')
                ? client.readFragment<ProjectBasicInfoFragment>({
                    fragment: PROJECT_BASIC_INFO_FRAGMENT,
                    id: getFieldValue('projectId'),
                  })?.projectLength
                : undefined,
            })(
              <InputNumber placeholder="Progress" style={{ width: '100%' }} />,
            )}
          </Form.Item>
          <Form.Item label={`Date of ${typeLabel}`}>
            {getFieldDecorator('entryDate')(
              <DatePicker placeholder="Entry Date" style={{ width: '100%' }} />,
            )}
          </Form.Item>
          {type !== 'WIP' && (
            <Form.Item label="Documents">
              {getFieldDecorator('documents', {
                valuePropName: 'fileList',
              })(
                <ImageUploader
                  multiple
                  accept="image/jpg,image/jpeg,image/png,application/pdf"
                />,
              )}
            </Form.Item>
          )}
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default Form.create<Props>({ name: 'createMRItem' })(CreateMRItem);
