import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.35929C6.59736 3.35929 2.21765 8.01874 2.21765 13.7665C2.21765 16.4852 3.1961 18.9581 4.8008 20.8127L3.16918 22.4106C1.20248 20.1376 0 17.1001 0 13.7665C0 6.71574 5.37258 1 12 1C14.6069 1 17.0227 1.88575 18.9916 3.3896L17.34 5.12217C15.8796 4.04313 14.1277 3.35929 12 3.35929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7824 13.7703C21.7824 10.8898 20.6839 8.28453 18.9055 6.39893L20.471 4.72787C22.6497 7.03803 24 10.2375 24 13.7703H21.7824Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7497 4.1258L12.9005 14.6042L11.3324 12.9359L21.1816 2.45752L22.7497 4.1258Z"
        fill="currentColor"
      />
      <path
        d="M15.3424 13.7667C15.3424 15.7305 13.846 17.3225 12 17.3225C10.1541 17.3225 8.65764 15.7305 8.65764 13.7667C8.65764 11.8028 10.1541 10.2108 12 10.2108C13.846 10.2108 15.3424 11.8028 15.3424 13.7667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Dashboard;
