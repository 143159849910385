import React from 'react';

const Report: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 105 105" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.625 17.5C29.4647 17.5 28.3519 17.9609 27.5314 18.7814C26.7109 19.6019 26.25 20.7147 26.25 21.875V83.125C26.25 84.2853 26.7109 85.3981 27.5314 86.2186C28.3519 87.0391 29.4647 87.5 30.625 87.5H74.375C75.5353 87.5 76.6481 87.0391 77.4686 86.2186C78.2891 85.3981 78.75 84.2853 78.75 83.125L78.75 41.1872L55.0633 17.5005L30.625 17.5ZM21.3442 12.5942C23.8056 10.1328 27.144 8.75 30.625 8.75L59 9C61.3553 11.4181 59.5 9.5 61.2505 11.3133M61.2505 11.3133L87.5 37.5C87.5 40 87.4995 38.8651 87.5 41.1853V83.125C87.5 86.606 86.1172 89.9444 83.6558 92.4058C81.1944 94.8672 77.856 96.25 74.375 96.25H30.625C27.144 96.25 23.8056 94.8672 21.3442 92.4058C18.8828 89.9444 17.5 86.606 17.5 83.125V21.875C17.5 18.394 18.8828 15.0556 21.3442 12.5942M65.625 43.75C68.0412 43.75 70 45.7088 70 48.125V74.375C70 76.7912 68.0412 78.75 65.625 78.75C63.2088 78.75 61.25 76.7912 61.25 74.375V48.125C61.25 45.7088 63.2088 43.75 65.625 43.75ZM52.5 52.5C54.9162 52.5 56.875 54.4588 56.875 56.875V74.375C56.875 76.7912 54.9162 78.75 52.5 78.75C50.0838 78.75 48.125 76.7912 48.125 74.375V56.875C48.125 54.4588 50.0838 52.5 52.5 52.5ZM39.375 61.25C41.7912 61.25 43.75 63.2088 43.75 65.625V74.375C43.75 76.7912 41.7912 78.75 39.375 78.75C36.9588 78.75 35 76.7912 35 74.375V65.625C35 63.2088 36.9588 61.25 39.375 61.25Z"
      fill="currentColor"
    />
  </svg>
);

export default Report;
