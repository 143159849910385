import React, { useState, useCallback } from 'react';
import { Dropdown, Menu, Icon, message, Modal } from 'antd';
import { useApolloClient, useMutation } from 'react-apollo';

import { UserDetailInfoFragment } from '../../../../../types/UserDetailInfoFragment';
import EditUserForm from './EditUserForm';
import AssignProjectForm from './AssignProjectForm';
import {
  USER_DETAIL_INFO_FRAGEMENT,
  DELETE_USER_MUTATION,
  USERS_DETAIL_INFO_QUERY,
} from '../../../../../queries/user';
import UserDetailComponent from '../../../../../components/UserDetail';
import usePermission from '../../../../../hooks/usePermission';
import EditUserPermission from './EditUserPermission';
import {
  DeleteUser,
  DeleteUserVariables,
} from '../../../../../types/DeleteUser';
import {
  UsersDetailInfo,
  UsersDetailInfoVariables,
} from '../../../../../types/UsersDetailInfo';

interface Props {
  userId: string;
  onDelete: () => void;
}

const UserDetail: React.FC<Props> = ({ userId, onDelete }) => {
  const { canUpdateUser, canDeleteUser } = usePermission();

  const client = useApolloClient();

  const user = client.readFragment<UserDetailInfoFragment>({
    fragment: USER_DETAIL_INFO_FRAGEMENT,
    fragmentName: 'UserDetailInfoFragment',
    id: userId,
  });

  const [deleteUserMutation] = useMutation<DeleteUser, DeleteUserVariables>(
    DELETE_USER_MUTATION,
    {
      update: (cache, { data }) => {
        if (data) {
          let usersData;
          try {
            usersData = cache.readQuery<
              UsersDetailInfo,
              UsersDetailInfoVariables
            >({
              query: USERS_DETAIL_INFO_QUERY,
            });
          } catch (cacheReadError) {
            usersData = undefined;
          }

          if (usersData) {
            cache.writeQuery<UsersDetailInfo, UsersDetailInfoVariables>({
              query: USERS_DETAIL_INFO_QUERY,
              data: {
                users: usersData.users.filter(
                  (userData) => userData.id !== data.deleteUser.id,
                ),
              },
            });
          }
        }
      },
      onCompleted: () => {
        message.info('User deleted successfully');
        onDelete();
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [mode, setMode] = useState<
    'view' | 'edit' | 'assignProjects' | 'editPermissions'
  >('view');

  const handleEditUserClick = useCallback(() => {
    setMode('edit');
  }, []);

  const handleAssignProjectsClick = useCallback(() => {
    setMode('assignProjects');
  }, []);

  const handleUpdatePermissionsUserClick = useCallback(() => {
    setMode('editPermissions');
  }, []);

  const handleDeleteUser = useCallback(() => {
    if (user) {
      deleteUserMutation({ variables: { userId: user.id } });
    }
  }, [deleteUserMutation, user]);

  const handleDeleteUserClick = useCallback(() => {
    Modal.confirm({
      title: 'Delete User',
      icon: <Icon type="delete" />,
      content:
        'User once deleted cannot be recovered. Do you want to continue?',
      onOk: handleDeleteUser,
    });
  }, [handleDeleteUser]);

  const setViewMode = useCallback(() => {
    setMode('view');
  }, []);

  if (user) {
    if (mode === 'edit') {
      return (
        <EditUserForm user={user} onEdit={setViewMode} onCancel={setViewMode} />
      );
    }

    if (mode === 'assignProjects') {
      return (
        <AssignProjectForm
          user={user}
          onSubmit={setViewMode}
          onCancel={setViewMode}
        />
      );
    }

    if (mode === 'editPermissions') {
      return <EditUserPermission user={user} onUpdate={setViewMode} />;
    }

    return (
      <div className="flex flex-col h-full">
        <div className="flex-1 mb-4 overflow-x-hidden overflow-y-auto">
          <UserDetailComponent
            userId={userId}
            dropDownMenu={
              canUpdateUser || canDeleteUser ? (
                <Dropdown
                  overlay={
                    <Menu>
                      {canUpdateUser ? (
                        <Menu.Item onClick={handleUpdatePermissionsUserClick}>
                          Update User Permissions
                        </Menu.Item>
                      ) : null}
                      {canUpdateUser ? (
                        <Menu.Item onClick={handleAssignProjectsClick}>
                          Assign Projects
                        </Menu.Item>
                      ) : null}
                      {canUpdateUser ? (
                        <Menu.Item onClick={handleEditUserClick}>
                          Edit User
                        </Menu.Item>
                      ) : null}
                      {canDeleteUser ? (
                        <Menu.Item onClick={handleDeleteUserClick}>
                          Delete User
                        </Menu.Item>
                      ) : null}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Icon type="more" />
                </Dropdown>
              ) : undefined
            }
          />
        </div>
      </div>
    );
  }

  return null;
};

export default UserDetail;
