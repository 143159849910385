import React, { useCallback, useState, useContext } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, Icon, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import validator from 'validator';
import { get } from 'lodash-es';

import AuthContext from '../../../contexts/AuthContext';

interface Props {
  form: WrappedFormUtils;
  onLogin?: () => void;
}

const LoginForm: React.FC<Props> = ({
  form: { getFieldDecorator, validateFields },
  onLogin,
}) => {
  const [loading, setLoading] = useState<'LOGIN' | 'LOGIN_WITH_OTP' | null>(
    null,
  );

  const { signInWithUsernameAndPassword } = useContext(AuthContext);

  const handleLogin = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          setLoading('LOGIN');
          const { success, error } = await signInWithUsernameAndPassword(
            values.username,
            values.password,
          );
          if (success && onLogin) {
            onLogin();
          } else if (error) {
            message.error(error);
            setLoading(null);
          }
        }
      });
    },
    [onLogin, signInWithUsernameAndPassword, validateFields],
  );

  const handleLoginWithOTP = useCallback(() => {
    validateFields(['username'], async fieldErrors => {
      if (!fieldErrors) {
        setLoading('LOGIN_WITH_OTP');
        try {
          // TODO: login with OTP
        } catch (error) {
          const errorMessage = get(
            error,
            'response.data.message',
            'Something went wrong. Please try again',
          );
          message.error(errorMessage);
          setLoading(null);
        }
      }
    });
  }, [validateFields]);

  return (
    <Form onSubmit={handleLogin}>
      <Form.Item>
        {getFieldDecorator('username', {
          // @ts-ignore
          validate: [
            {
              trigger: 'onChange',
              rules: [
                {
                  required: true,
                  message: 'Username is required',
                },
              ],
            },
            {
              trigger: 'onBlur',
              rules: [
                {
                  validator: async (rules: any, value: string) => {
                    const isValid =
                      validator.isEmail(value) ||
                      // TODO: Check local before sending data
                      validator.isMobilePhone(value, 'en-IN');

                    if (!isValid) {
                      throw new Error(
                        'Username must be a valid phone number or email',
                      );
                    }
                  },
                  message: 'Username must be a valid phone number or email',
                },
              ],
            },
          ],
          validateFirst: true,
        })(
          <Input
            placeholder="Email/Phone Number"
            prefix={<StyledIcon type="user" />}
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          // @ts-ignore
          validate: [
            {
              trigger: 'onChange',
              rules: [
                {
                  required: true,
                  message: 'Password is required',
                },
              ],
            },
            {
              trigger: 'onBlur',
              rules: [
                {
                  min: 8,
                  message: 'Password should be 8 characters long',
                },
              ],
            },
          ],
        })(
          <Input.Password
            placeholder="Password"
            prefix={<StyledIcon type="lock" />}
          />,
        )}
      </Form.Item>
      <Form.Item>
        <StyledButton
          type="primary"
          htmlType="submit"
          loading={loading === 'LOGIN'}
        >
          Login
        </StyledButton>
      </Form.Item>
      <StyledButton
        type="link"
        htmlType="button"
        loading={loading === 'LOGIN_WITH_OTP'}
        onClick={handleLoginWithOTP}
      >
        Login with OTP
      </StyledButton>
    </Form>
  );
};

export default Form.create<Props>({ name: 'login' })(LoginForm);

const StyledButton = styled(Button)`
  &&& {
    width: 100%;
  }
`;

const StyledIcon = styled(Icon)`
  opacity: 0.5;
`;
