import React, { useEffect, useCallback, useState } from 'react';
import { message, Button } from 'antd';

import * as serviceWorker from '../../serviceWorker';

const ServiceWorker: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleUpdate = useCallback(
    (registration: ServiceWorkerRegistration) => {
      if (registration.waiting) {
        const newServiceWorker = registration.waiting;
        message.info({
          content: (
            <div className="inline-flex items-center">
              <span className="mr-3">New Update available</span>
              <Button
                size="small"
                loading={loading}
                onClick={() => {
                  newServiceWorker.addEventListener('statechange', event => {
                    if ((event.target as ServiceWorker).state === 'activated') {
                      window.location.reload();
                    }
                  });
                  setLoading(true);
                  newServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                }}
                type="primary"
              >
                Update
              </Button>
            </div>
          ),
          duration: 0,
        });
      }
    },
    [loading],
  );

  useEffect(() => {
    serviceWorker.register({ onUpdate: handleUpdate });
  }, [handleUpdate]);

  return null;
};

export default ServiceWorker;
