import { User } from '../types';

export const isModuleEnabled = (user: User | undefined, moduleName: string) => {
  if (user) {
    // Special check for master dashboard, to ensure that the user masterdashboard is only
    // visible to the users for whose organisation it has been enabled in the background
    if (
      moduleName === 'MASTER_DASHBOARD' &&
      !user.organisation.showMasterDashboard
    ) {
      return false;
    }

    if (user.role === 'ORG_ADMIN' || user.role === 'SUPER_ADMIN') {
      return true;
    }

    const userPermission = user.permissions.find(
      (permission) => permission.module === moduleName,
    )?.scope.length;

    return !!userPermission;
  }

  return false;
};

export const isUserEnabled = (
  user: User,
  moduleName: string,
  scope: 'READ' | 'UPDATE' | 'DELETE' | 'CREATE',
) => {
  if (user) {
    if (user.role === 'ORG_ADMIN' || user.role === 'SUPER_ADMIN') {
      return true;
    }

    const modulePermission = user.permissions.find(
      (permission) => permission.module === moduleName,
    );
    if (modulePermission) {
      return modulePermission.scope.includes(scope);
    }
  }
  return false;
};
