import React, { useContext } from 'react';
import ReactIntercom from 'react-intercom';

import AuthContext from '../../contexts/AuthContext';

const Intercom: React.FC = () => {
  const { user, leadInfoCaptured } = useContext(AuthContext);

  let userInfo;

  const isDemoVersion = !!(
    process.env.REACT_APP_DEMO_USER_NAME && process.env.REACT_APP_DEMO_PASSWORD
  );

  if (isDemoVersion) {
    if (leadInfoCaptured) {
      userInfo = {
        id: leadInfoCaptured.email,
        email: leadInfoCaptured.email,
        name: leadInfoCaptured.name,
        phone: leadInfoCaptured.phone,
      };
    } else {
      userInfo = undefined;
    }
  } else {
    userInfo = {
      user_id: user ? user.id : undefined,
      email: user ? user.email : undefined,
      name: user ? user.name : undefined,
      phone: user && user.phone ? `+${user.phone}` : undefined,
      tags: user ? [user.role] : undefined,
      avatar:
        user && user.profileImage
          ? { type: 'avatar', image_url: user.profileImage }
          : undefined,
    };
  }

  return (
    <ReactIntercom appID={process.env.REACT_APP_INTERCOM_ID} {...userInfo} />
  );
};

export default Intercom;
