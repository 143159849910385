import React from 'react';

const Analytics: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect y="14.4" width="4.8" height="9.6" fill="currentColor" />
        <rect
          x="9.59998"
          y="7.19995"
          width="4.8"
          height="16.8"
          fill="currentColor"
        />
        <rect x="19.2" width="4.8" height="24" fill="currentColor" />
      </g>
    </svg>
  );
};

export default Analytics;
