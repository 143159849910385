import React, { useState, useCallback, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { message, Modal, Button, Input } from 'antd';
import update from 'immutability-helper';
import _ from 'lodash';

import OrgChart from './Orgchart';
import useGroups from '../../../hooks/useGroups';
import {
  CREATE_GROUP_MUTATION,
  DELETE_GROUP_MUTATION,
  UPDATE_GROUP_MUTATION,
  GROUPS_QUERY,
  GROUP_INFO_FRAGMENT,
} from '../queries';
import { CreateGroup, CreateGroupVariables } from '../../../types/CreateGroup';
import { DeleteGroup, DeleteGroupVariables } from '../../../types/DeleteGroup';
import { UpdateGroup, UpdateGroupVariables } from '../../../types/UpdateGroup';
import { Groups, GroupsVariables } from '../../../types/Groups';
import { GroupInfoFragment } from '../../../types/GroupInfoFragment';
import IndeterminateProgress from '../../../components/IndeterminateProgress';

/** NOTE- every node is a Group
 *  The code is written in such way that you first need to get the parent node (the root node in our case)
 *  then use, useGroups hook get all of its children.
 */

type NodeType = {
  id: string;
  name: string;
  parentId: string | null;
};

type DeleteChildren = (
  node: NodeType,
  allNodes: NodeType[],
  stopNodeId: number,
) => DeleteChildren | NodeType[];

interface Props {
  rootGroupData: GroupInfoFragment;
  readonly?: boolean;
  disableRootEdit?: boolean;
  disableEditNodes?: boolean;
}

const OrgChartIndex: React.FC<Props> = ({
  rootGroupData,
  readonly,
  disableRootEdit,
  disableEditNodes,
}) => {
  const { id, name: rootName } = rootGroupData;
  const rootNode = { id, name: rootName, parentId: null };

  const [nodes, setNodes] = useState<NodeType[]>([rootNode]);

  /** used by modal for naming new node */
  const [name, setName] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);

  /** provides parentId for creating a new node */
  const [selectedNode, setSelectedNode] = useState<NodeType>(rootNode);

  const onNodeClick = useCallback((curSelectedNode: NodeType) => {
    setSelectedNode(() => curSelectedNode);
  }, []);

  // fetching all children
  const data = useGroups(rootGroupData.id);

  useEffect(() => {
    if (data) {
      setNodes((prev) => [...prev, ...(data as Array<NodeType>)]);
    }
  }, [data]);

  /** creating a node */
  const [createGroupMutation, { loading: createGroupLoading }] = useMutation<
    CreateGroup,
    CreateGroupVariables
  >(CREATE_GROUP_MUTATION, {
    update: (cache, { data: groupData }) => {
      /** Updating Grand parent about the addition of a newly created child in their own child */
      if (groupData && selectedNode) {
        const parentNode = cache.readFragment<GroupInfoFragment>({
          fragment: GROUP_INFO_FRAGMENT,
          fragmentName: 'GroupInfoFragment',
          id: selectedNode.id,
        });
        if (parentNode) {
          cache.writeFragment<GroupInfoFragment>({
            fragment: GROUP_INFO_FRAGMENT,
            fragmentName: 'GroupInfoFragment',
            id: selectedNode.id,
            data: {
              ...parentNode,
              children: parentNode.children
                ? [
                    ...parentNode.children,
                    {
                      id: groupData.createGroup.id,
                      name: groupData.createGroup.name,
                      __typename: 'Group',
                    },
                  ]
                : [
                    {
                      id: groupData.createGroup.id,
                      name: groupData.createGroup.name,
                      __typename: 'Group',
                    },
                  ],
            },
          });
        }

        /** Updating parent of the newly created child / node */
        let childGroups;
        try {
          childGroups = cache.readQuery<Groups, GroupsVariables>({
            query: GROUPS_QUERY,
            variables: { parentGroupId: selectedNode.id },
          });
        } catch (cacheReadError) {
          childGroups = undefined;
        }

        if (childGroups) {
          cache.writeQuery<Groups, GroupsVariables>({
            query: GROUPS_QUERY,
            variables: { parentGroupId: selectedNode.id },
            data: {
              groups: [...childGroups.groups, groupData.createGroup],
            },
          });
        }
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Group created successfully');
    },
  });

  const createChild = useCallback(async () => {
    if (selectedNode && name !== '') {
      const parentId: string | null = selectedNode.id;
      const newNodeName: string = name;

      if (parentId && newNodeName !== '') {
        const { data: newNodeData } = await createGroupMutation({
          variables: { name: newNodeName, parentId },
        });

        if (newNodeData) {
          const newNode: NodeType = {
            id: newNodeData.createGroup.id,
            name: newNodeData.createGroup.name,
            parentId,
          };

          setNodes((prev) => [...prev, newNode]);
        }
      }
    }
  }, [createGroupMutation, selectedNode, name]);

  const handleSumbit = useCallback(async () => {
    await createChild();

    setName('');
    setShow(false);
  }, [createChild]);

  /** deleting a node */
  const [deleteGroupMutation, { loading: deleteGroupLoading }] = useMutation<
    DeleteGroup,
    DeleteGroupVariables
  >(DELETE_GROUP_MUTATION, {
    update: (cache, { data: deletedGroupData }) => {
      if (deletedGroupData && selectedNode && selectedNode.parentId) {
        let childGroups;

        try {
          childGroups = cache.readQuery<Groups, GroupsVariables>({
            query: GROUPS_QUERY,
            variables: { parentGroupId: selectedNode.parentId },
          });
        } catch (cacheReadError) {
          childGroups = undefined;
        }

        if (childGroups) {
          cache.writeQuery<Groups, GroupsVariables>({
            query: GROUPS_QUERY,
            variables: { parentGroupId: selectedNode.parentId },
            data: {
              groups: childGroups.groups.filter(
                (child) => child.id !== deletedGroupData.deleteGroup.id,
              ),
            },
          });
        }

        const parentNode = cache.readFragment<GroupInfoFragment>({
          fragment: GROUP_INFO_FRAGMENT,
          fragmentName: 'GroupInfoFragment',
          id: selectedNode.parentId,
        });
        if (parentNode) {
          cache.writeFragment<GroupInfoFragment>({
            fragment: GROUP_INFO_FRAGMENT,
            fragmentName: 'GroupInfoFragment',
            id: selectedNode.parentId,
            data: {
              ...parentNode,
              children:
                parentNode.children &&
                parentNode.children.filter(
                  (child) => child.id !== deletedGroupData.deleteGroup.id,
                ),
            },
          });
        }
      }
    },

    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Group Deleted successfully');
    },
  });

  // recursively deleting children node from state
  const deleteChildren = useCallback(
    (
      node: NodeType,
      allNodes: NodeType[],
      stopNodeId: string,
    ): DeleteChildren | NodeType[] => {
      const child = _.find(allNodes, { parentId: node.id });
      const parent = _.find(allNodes, { id: node.parentId as string });

      if (child) {
        return deleteChildren(child, allNodes, stopNodeId);
      }
      if (stopNodeId === node.id) {
        return allNodes;
      }

      _.remove(allNodes, { id: node.id });
      return deleteChildren(parent as NodeType, allNodes, stopNodeId);
    },
    [],
  );

  const deleteSelectedNode = useCallback(
    async (node: NodeType) => {
      const newData = deleteChildren(node, _.cloneDeep(nodes), node.id);

      await deleteGroupMutation({ variables: { id: node.id } });

      _.remove(newData as NodeType[], { id: node.id });

      setNodes(newData as NodeType[]);
    },
    [nodes, deleteChildren, deleteGroupMutation],
  );

  /** updating / editing a node */
  const [updateGroupMutation, { loading: updateGroupLoading }] = useMutation<
    UpdateGroup,
    UpdateGroupVariables
  >(UPDATE_GROUP_MUTATION, {
    update: (cache, { data: groupData }) => {
      /** Updating parent according to the changes in the current */
      if (groupData && selectedNode) {
        if (selectedNode.parentId !== null) {
          const parentNode = cache.readFragment<GroupInfoFragment>({
            fragment: GROUP_INFO_FRAGMENT,
            fragmentName: 'GroupInfoFragment',
            id: selectedNode.parentId,
          });
          if (parentNode && parentNode.children) {
            cache.writeFragment<GroupInfoFragment>({
              fragment: GROUP_INFO_FRAGMENT,
              fragmentName: 'GroupInfoFragment',
              id: selectedNode.parentId,
              data: {
                ...parentNode,
                children: [
                  ...parentNode.children.filter(
                    (child) => child.id !== groupData.updateGroup.id,
                  ),
                  {
                    id: groupData.updateGroup.id,
                    name: groupData.updateGroup.name,
                    __typename: 'Group',
                  },
                ],
              },
            });
          }
        }

        /** Updating the current node */
        const curNode = cache.readFragment<GroupInfoFragment>({
          fragment: GROUP_INFO_FRAGMENT,
          fragmentName: 'GroupInfoFragment',
          id: selectedNode.id,
        });
        if (curNode) {
          cache.writeFragment<GroupInfoFragment>({
            fragment: GROUP_INFO_FRAGMENT,
            fragmentName: 'GroupInfoFragment',
            id: selectedNode.id,
            data: {
              ...curNode,
              name: groupData.updateGroup.name,
            },
          });
        }
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Group updated successfully');
    },
  });

  const edit = useCallback(
    async (node: NodeType) => {
      await updateGroupMutation({
        variables: { id: node.id, name: node.name },
      });

      const editIdx = _.findIndex(nodes, { id: node.id });
      const updated = update(nodes, { [editIdx]: { $set: node } });
      setNodes(updated);
    },
    [nodes, updateGroupMutation],
  );

  return (
    <>
      {(rootGroupData.children &&
        rootGroupData.children.length > 0 &&
        nodes.length === 1) ||
      updateGroupLoading ||
      deleteGroupLoading ? (
        <IndeterminateProgress className="absolute top-0 left-0 right-0" />
      ) : null}

      <OrgChart
        data={nodes}
        editable={!readonly}
        disableEditNodes={disableEditNodes}
        rootEditable={!disableRootEdit}
        onNodeClick={onNodeClick}
        addNewChild={() => {
          setShow(true);
        }}
        deleteNode={deleteSelectedNode}
        editNode={edit}
      />

      {show ? (
        <Modal
          title="Create New Group"
          visible={show}
          onCancel={() => {
            setShow(false);
            setName('');
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setShow(false);
                setName('');
              }}
            >
              Cancel
            </Button>,
            <Button
              loading={createGroupLoading}
              key="submit"
              type="primary"
              disabled={name === ''}
              onClick={handleSumbit}
            >
              Submit
            </Button>,
          ]}
        >
          <Input
            value={name}
            placeholder="Group name"
            onChange={(event) => {
              setName(event.target.value);
            }}
            autoFocus
          />
        </Modal>
      ) : null}
    </>
  );
};

export default OrgChartIndex;
