import React from 'react';

const MaterialManagement = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 27H66.4419V37H23V27ZM15 19H23H66.4419H74.4419V27V37V38H82.4419H90.4419V46V56V64H82.4419H80.4419V68V78V86H72.4419H29H21V78V68V60H29H31V56V46V45H23H15V37V27V19ZM39 46H82.4419V56H39V46ZM72.4419 68H29V78H72.4419V68Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MaterialManagement;
