import React from 'react';
import { Divider, Skeleton, Result } from 'antd';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';

import { GROUP_QUERY } from '../queries';
import { Group, GroupVariables } from '../../../types/Group';

interface Props {
  groupId: string;
}

const GroupDetail: React.FC<Props> = ({ groupId }) => {
  const { data, error, loading } = useQuery<Group, GroupVariables>(
    GROUP_QUERY,
    {
      variables: { groupId },
    },
  );

  if (loading) {
    return (
      <>
        <Skeleton title avatar paragraph={{ rows: 2 }} />
        <Skeleton title paragraph={{ rows: 1 }} />
        <Skeleton title paragraph={{ rows: 1 }} />
      </>
    );
  }

  if (error) {
    return <Result status="warning" subTitle={error.message} />;
  }

  if (data) {
    const { group } = data;

    const groupProjects = group.projects || [];

    return (
      <div className="mb-4">
        <div className="text-sm text-heading-color">{group.name}</div>

        <Divider />

        <div className="mb-4 text-xs font-medium tracking-wider uppercase text-primary-color">
          Projects Assigned
        </div>
        {groupProjects.map((project, index) => (
          <Link
            key={project.id}
            to={`/projects/${project.id}/dashboard`}
            className={clsx(
              'block',
              index !== groupProjects.length - 1 ? 'mb-4' : undefined,
            )}
          >
            <div className="flex items-center">
              {project.photos && project.photos.length > 0 ? (
                <img
                  className="object-cover w-12 h-12 mr-4 rounded"
                  src={project.photos[0]}
                  alt={project.name}
                />
              ) : (
                <div className="flex items-center justify-center w-12 h-12 mr-4 rounded bg-light-primary-color">
                  <img
                    className="h-8"
                    src={require('../../../images/logo.svg')}
                    alt="BOCS"
                  />
                </div>
              )}
              <div>
                <div className="font-medium text-heading-color">
                  {project.name}
                </div>
                {project.startDate && project.targetDateOfCompletion ? (
                  <div className="text-sm">
                    {moment(project.startDate).format('YYYY')} -{' '}
                    {moment(project.targetDateOfCompletion).format('YYYY')}
                  </div>
                ) : null}
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  }
  return null;
};

export default GroupDetail;
