import React from 'react';

const GanttChart = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.926 1.6178L20.926 7.11011L1 7.11011L1 1.6178L20.926 1.6178Z"
        fill="currentColor"
      />
      <path
        d="M25 9.85627L25 15.3486L7.34613 15.3486L7.34613 9.85627L25 9.85627Z"
        fill="currentColor"
      />
      <path
        d="M22.9891 18.0947L22.9891 23.5871L14.581 23.5871L14.581 18.0947L22.9891 18.0947Z"
        fill="currentColor"
      />
      <path d="M1.00002 0L1 21L16 21" stroke="currentColor" />
    </svg>
  );
};

export default GanttChart;
