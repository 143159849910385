import React, { useMemo, useContext } from 'react';
import { ApolloProvider } from 'react-apollo';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  split,
  // IntrospectionFragmentMatcher,
} from 'apollo-boost';
import { toIdValue } from 'apollo-utilities';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { message } from 'antd';

import AuthContext from '../../contexts/AuthContext';
// TODO: Figure out InstrospectionFragmentMatcher
// import schema from '../../schema.json';

interface Props {}

const Apollo: React.FC<Props> = ({ children }) => {
  const { authToken, updateToken, signOut } = useContext(AuthContext);

  const client = useMemo(() => {
    const updateTokenAfterWare = new ApolloLink((operation, forward) => {
      return forward(operation).map((operationResponse) => {
        const context = operation.getContext() as
          | { response?: Response }
          | undefined;
        if (context && context.response) {
          const { headers } = context.response;
          const updatedToken = headers.get('x-jwt');
          if (updatedToken) {
            updateToken(updatedToken);
          }
        }

        return operationResponse;
      });
    });

    const logoutAfterWare = onError(({ networkError }) => {
      if (networkError) {
        if ((networkError as any).statusCode === 401) {
          message.error('Token expired. Please login to continue');
          signOut();
        }
      }
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          Authorization: authToken,
        },
      });

      return forward(operation);
    });

    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL,
    });

    const batchLink = new BatchHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL,
      headers: {
        batch: true,
      },
      batchMax: 10,
    });

    const link = split(
      (opertation) => opertation.getContext().batch === true,
      batchLink,
      httpLink,
    );

    return new ApolloClient({
      link: logoutAfterWare.concat(
        updateTokenAfterWare.concat(ApolloLink.from([authMiddleware, link])),
      ),
      cache: new InMemoryCache({
        dataIdFromObject: (object) => object.id,
        // fragmentMatcher: new IntrospectionFragmentMatcher({
        //   introspectionQueryResultData: schema,
        // }),
        cacheRedirects: {
          Query: {
            project: (_, args) =>
              toIdValue({ typename: 'Project', id: args.id }),
            drawing: (_, args) =>
              toIdValue({ typename: 'Drawing', id: args.id }),
            issue: (_, args) => toIdValue({ typename: 'Issue', id: args.id }),
            user: (_, args) => toIdValue({ typename: 'User', id: args.id }),
            driveItem: (_, args) =>
              toIdValue({ typename: 'DriveItem', id: args.id }),
            bill: (_, args) => toIdValue({ typename: 'Bill', id: args.id }),
            projectCategory: (_, args) =>
              toIdValue({ typename: 'ProjectCategory', id: args.id }),
            category: (_, args) =>
              toIdValue({ typename: 'Category', id: args.id }),
            projectElement: (_, args) =>
              toIdValue({ typename: 'ProjectElement', id: args.id }),
            physicalProgress: (_, args) =>
              toIdValue({ typename: 'PhysicalProgress', id: args.id }),
            materialGroup: (_, args) =>
              toIdValue({ typename: 'MaterialGroup', id: args.id }),
            materialItem: (_, args) =>
              toIdValue({ typename: 'MaterialItem', id: args.id }),
            checklistTemplate: (_, args) =>
              toIdValue({ typename: 'ChecklistTemplate', id: args.id }),
            checklistFolder: (_, args) =>
              toIdValue({ typename: 'ChecklistFolder', id: args.id }),
            rfi: (_, args) => toIdValue({ typename: 'RFI', id: args.id }),
            ncr: (_, args) => toIdValue({ typename: 'NCR', id: args.id }),
            group: (_, args) => toIdValue({ typename: 'Group', id: args.id }),
          },
        },
      }),
    });
  }, [authToken, signOut, updateToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
