import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AllUsers from './scenes/AllUsers';

interface Props extends RouteComponentProps {}

const Users: React.FC<Props> = () => {
  return <AllUsers />;
};

export default Users;
