import { useContext } from 'react';

import MediaContext from '../contexts/MediaContext';

const useMedia = () => {
  const { deviceSize, isMobile, isDesktop, isTablet } = useContext(
    MediaContext,
  );

  return {
    deviceSize,
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useMedia;
