import React, { useMemo, useCallback } from 'react';
import { Button, Col, Row, message, Result, Modal, Icon } from 'antd';
import { useQuery, useMutation } from 'react-apollo';
import { useHistory } from 'react-router';

import { Hierarchy } from '../../../components/icons';
import ProjectHierarchyCard from './ProjectHierarchyCard';
import CreateHierarchy from './CreateHierarchy';
import {
  CREATE_GROUP_MUTATION,
  DELETE_GROUP_MUTATION,
  GROUPS_QUERY,
} from '../../ProjectTreeDetailedView/queries';
import { DeleteGroup, DeleteGroupVariables } from '../../../types/DeleteGroup';
import { CreateGroup, CreateGroupVariables } from '../../../types/CreateGroup';
import { Groups } from '../../../types/Groups';
import Header from '../../../components/Header';
import IndeterminateProgress from '../../../components/IndeterminateProgress';
import useMedia from '../../../hooks/useMedia';

/** NOTE- Hierarchy is an alias for group (ex- root level group) */

interface Props {}

const SummaryView: React.FC<Props> = () => {
  const { data: allGroupsData, loading, error: allGroupsDataError } = useQuery<
    Groups
  >(GROUPS_QUERY);

  const history = useHistory();

  const { isDesktop } = useMedia();

  /** create a new hierarchy */
  const [createGroupMutation, { loading: createGroupLoading }] = useMutation<
    CreateGroup,
    CreateGroupVariables
  >(CREATE_GROUP_MUTATION, {
    update: (cache, { data: groupData }) => {
      if (groupData) {
        let allGroups;
        try {
          allGroups = cache.readQuery<Groups>({
            query: GROUPS_QUERY,
          });
        } catch (cacheReadError) {
          allGroups = undefined;
        }

        if (allGroups) {
          cache.writeQuery<Groups>({
            query: GROUPS_QUERY,
            data: {
              groups: [...allGroups.groups, groupData.createGroup],
            },
          });
        }
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: (groupData) => {
      if (isDesktop) {
        history.push(`/project-tree/heirarchy/${groupData.createGroup.id}`);
      }
      message.success('Hierarchy created successfully');
    },
  });

  const createNewHierarchy = useCallback(
    (name: string) => {
      createGroupMutation({ variables: { name } });
    },
    [createGroupMutation],
  );

  /** delete a hierarchy */
  const [deleteGroupMutation, { loading: deleteGroupLoading }] = useMutation<
    DeleteGroup,
    DeleteGroupVariables
  >(DELETE_GROUP_MUTATION, {
    update: (cache, { data: deletedGroupData }) => {
      if (deletedGroupData) {
        let allGroups;
        try {
          allGroups = cache.readQuery<Groups>({
            query: GROUPS_QUERY,
          });
        } catch (cacheReadError) {
          allGroups = undefined;
        }

        if (allGroups) {
          cache.writeQuery<Groups>({
            query: GROUPS_QUERY,
            data: {
              groups: [
                ...allGroups.groups.filter(
                  (group) => deletedGroupData.deleteGroup.id !== group.id,
                ),
              ],
            },
          });
        }
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Hierarchy deleted successfully');
    },
  });

  const deleteHierarchy = useCallback(
    async (id: string) => {
      await deleteGroupMutation({ variables: { id } });
    },
    [deleteGroupMutation],
  );

  const handleDeleteHierarchy = useCallback(
    (hierarchyId) => {
      Modal.confirm({
        title: 'Delete Hierarchy',
        content:
          'Hierarchy once deleted cannot be recovered. Do you want to continue?',
        okText: 'Yes',
        cancelText: 'No',
        icon: <Icon type="delete" className="fill-current text-error-color" />,
        onOk: () => deleteHierarchy(hierarchyId),
      });
    },
    [deleteHierarchy],
  );

  const content = useMemo(() => {
    if (allGroupsDataError) {
      return <Result status="warning" subTitle={allGroupsDataError.message} />;
    }

    if (allGroupsData) {
      return allGroupsData.groups.map((group) => {
        return (
          <Col xs={24} sm={12} lg={8} key={group.id}>
            <ProjectHierarchyCard
              groupId={group.id}
              groupName={group.name}
              deleteHierarchy={handleDeleteHierarchy}
            />
          </Col>
        );
      });
    }

    return null;
  }, [allGroupsDataError, allGroupsData, handleDeleteHierarchy]);

  return (
    <div className="relative max-w-5xl p-4 mx-auto">
      {loading || createGroupLoading || deleteGroupLoading ? (
        <IndeterminateProgress className="absolute top-0 left-0 right-0" />
      ) : null}
      

      <Header
        className="mb-6"
        label="Project Hierarchies"
        name="Project Hierarchies"
        icon={{
          component: Hierarchy,
        }}
        info={[
          {
            label: 'Project Tree',
            value: (allGroupsData && allGroupsData.groups.length) || 'N/A',
          },
        ]}
        actions={[
          <CreateHierarchy
            loading={createGroupLoading}
            createNewHierarchy={createNewHierarchy}
            key="New"
            trigger={
              <Button type="primary" icon="plus">
                Create Hierarchy
              </Button>
            }
          />,
        ]}
      />

      <Row gutter={[16, 16]} className="mb-4">
        {content}
      </Row>
    </div>
  );
};

export default SummaryView;
