import React from 'react';

const Cube = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.83818L11.4593 11.6759L12.1349 24L2 18.1623V5.83818Z"
        fill="currentColor"
      />
      <path
        d="M22.2698 5.83818L12.8105 11.6759L12.1349 24L22.2698 18.1623V5.83818Z"
        fill="currentColor"
      />
      <path
        d="M2.00004 5.51365L12.1349 10.7028L22.1309 5.50717L11.7971 0L2.00004 5.51365Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Cube;
