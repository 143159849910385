import React from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';

import Route from '../../components/Route';
import InstantReport from './scenes/InstantReport';

interface Props extends RouteComponentProps {}

const Reports: React.FC<Props> = () => {
  return (
    <Switch>
      <Route
        path="/projects/:projectId/reports/instant-report"
        exact
        protectedRoute
        component={InstantReport}
      />
    </Switch>
  );
};

export default Reports;
