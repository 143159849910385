import React, { useCallback } from 'react';
import { Modal, Form, Input, Icon, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useMutation } from 'react-apollo';

import { CHANGE_PASSWORD_MUTATION } from '../queries';
import {
  changePassword,
  changePasswordVariables,
} from '../../../types/changePassword';
import { UserDetailInfoFragment } from '../../../types/UserDetailInfoFragment';
import { USER_DETAIL_INFO_FRAGEMENT } from '../../../queries/user';

interface Props {
  visible: boolean;
  setVisible: (status: boolean) => void;
  form: WrappedFormUtils;
  className?: string;
  style?: React.CSSProperties;
}

const ChangePasswordModal: React.FC<Props> = ({
  visible,
  setVisible,
  form: { getFieldDecorator, resetFields, validateFields },
  form,
  className,
  style,
}) => {
  const [changePasswordMutation, { loading }] = useMutation<
    changePassword,
    changePasswordVariables
  >(CHANGE_PASSWORD_MUTATION, {
    update: (cache, { data }) => {
      if (data) {
        cache.writeFragment<UserDetailInfoFragment>({
          id: data.changePassword.id,
          fragment: USER_DETAIL_INFO_FRAGEMENT,
          fragmentName: 'UserDetailInfoFragment',
          data: data.changePassword,
        });
      }
    },
    onError: (error) => {
      if (error.message.endsWith('password')) {
        message.error('Invalid password');
      } else {
        message.error('Something went wrong. Please try again');
      }
    },
    onCompleted: () => {
      message.success('Password changed successfully');

      resetFields();
    },
  });

  const handleModalClose = useCallback(() => {
    setVisible(false);
    resetFields();
  }, [resetFields, setVisible]);

  const handleSubmit = useCallback(
    (
      event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>,
    ) => {
      event.preventDefault();
      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          const { oldPassword, newPassword } = values;

          await changePasswordMutation({
            variables: {
              changePasswordData: {
                currentPassword: oldPassword,
                newPassword,
              },
            },
          });

          handleModalClose();
        }
      });
    },
    [handleModalClose, validateFields, changePasswordMutation],
  );

  return (
    <div className={className} style={style}>
      <Modal
        visible={visible}
        onCancel={handleModalClose}
        title="Change Password"
        okText="Confirm"
        confirmLoading={loading}
        okButtonProps={{
          onClick: handleSubmit,
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Old Password">
            {getFieldDecorator('oldPassword', {
              rules: [{ required: true, message: 'Old password is required' }],
            })(
              <Input.Password
                placeholder="old password..."
                prefix={<Icon type="lock" className="opacity-50" />}
              />,
            )}
          </Form.Item>

          <Form.Item label="New Password" hasFeedback>
            {getFieldDecorator('newPassword', {
              rules: [
                { required: true, message: 'New password is required' },
                {
                  validator(rule, value, callback) {
                    if (!value || value.length >= 8) {
                      callback();
                    } else {
                      callback('Password must be at least 8 characters long');
                    }
                  },
                },
              ],
            })(
              <Input.Password
                placeholder="new password..."
                prefix={<Icon type="lock" className="opacity-50" />}
              />,
            )}
          </Form.Item>

          <Form.Item hasFeedback>
            {getFieldDecorator('confirmPassword', {
              rules: [
                { required: true, message: 'Confirmation is required' },
                {
                  validator(rule, value, callback) {
                    if (!value || value === form.getFieldValue('newPassword')) {
                      callback();
                    } else {
                      callback(
                        'The two passwords that you entered do not match',
                      );
                    }
                  },
                },
              ],
            })(
              <Input.Password
                placeholder="confirm New Password..."
                prefix={<Icon type="lock" className="opacity-50" />}
              />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Form.create<Props>({ name: 'changePasswordModal' })(
  ChangePasswordModal,
);
