import gql from 'graphql-tag';

import { USER_DETAIL_INFO_FRAGEMENT } from '../../queries/user';

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($changePasswordData: ChangePasswordInput!) {
    changePassword(changePasswordData: $changePasswordData) {
      ...UserDetailInfoFragment
    }
  }
  ${USER_DETAIL_INFO_FRAGEMENT}
`;
