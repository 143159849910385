import React from 'react';
import moment from 'moment';
import { Icon, Menu, Dropdown } from 'antd';
import clsx from 'clsx';

import { Link } from 'react-router-dom';
import { MRItemInfoFragment } from '../../../../../types/MRItemInfoFragment';
import NewInfo from '../../../../../components/NewInfo';
import { Project } from '../../../../../components/icons';
import DeleteMRItem from '../../../../../modules/MRDashboard/components/DeleteMRItem';
import useProjectId from '../../../../../hooks/useProjectId';
import EditMRItem from '../../../../../modules/MRDashboard/components/EditMRItem';

const typeMap = {
  CRS: {
    name: 'CRS Inspection Done',
  },
  LET: {
    name: 'Light Engine Trial Done',
  },
  WIP: {
    name: 'Work in Progress',
  },
};

interface Props {
  label: string;
  listItem: MRItemInfoFragment;
  type: string;
  className?: string;
  style?: React.CSSProperties;
}

const ItemsListItem: React.FC<Props> = ({ listItem, className, style }) => {
  const projectId = useProjectId() as string;

  const downloadDocsDropdownMenu =
    listItem.documents && listItem.documents.length > 0 ? (
      <Menu className="">
        {listItem.documents.map((document) => (
          <Menu.Item key={document}>
            <a
              href={document}
              key={document}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center space-x-2"
            >
              <Icon type="download" />
              <span className="text-sm">Download Document</span>
            </a>
          </Menu.Item>
        ))}
      </Menu>
    ) : null;

  return (
    <div
      className={clsx(
        'py-2 px-4 rounded border grid grid-cols-3 gap-4 relative',
        className,
      )}
      style={style}
    >
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item className="relative w-32 h-8">
              <EditMRItem
                trigger={
                  <span className="absolute inset-0 px-2 py-1">Edit Item</span>
                }
                typeLabel={
                  typeMap[listItem.type as keyof typeof typeMap].name as string
                }
                mrItem={listItem}
              />
            </Menu.Item>
            <Menu.Item className="relative w-32 h-8">
              <DeleteMRItem
                trigger={
                  <span className="absolute inset-0 px-2 py-1">
                    Delete Item
                  </span>
                }
                projectId={projectId}
                mrItem={listItem}
              />
            </Menu.Item>
          </Menu>
        }
      >
        <Icon type="more" className="absolute top-0 right-0 mt-4 mr-4" />
      </Dropdown>
      <NewInfo
        label="Project"
        content={
          <Link to={`/projects/${listItem.project.id}/dashboard`}>
            {listItem.project.name}
          </Link>
        }
        icon={{ component: Project }}
      />
      {listItem.entryDate ? (
        <NewInfo
          label="Date"
          content={moment(listItem.entryDate).format('Do MMM, YYYY')}
          icon={{ type: 'clock-circle' }}
        />
      ) : null}
      <NewInfo
        label="Progress"
        content={`${listItem.progress} RKm`}
        icon={{ type: 'rise' }}
      />
      <NewInfo
        label="CPD"
        content={listItem.cpd.name}
        icon={{ type: 'user' }}
      />
      {listItem.documents && listItem.documents.length > 0 ? (
        <NewInfo
          label="Documents"
          content={
            <Dropdown overlay={downloadDocsDropdownMenu}>
              <Icon type="download" />
            </Dropdown>
          }
          icon={{ type: 'container' }}
        />
      ) : null}
    </div>
  );
};

export default ItemsListItem;
