import React, { useState, useCallback, memo } from 'react';
import { Icon } from 'antd';
import { Map } from '../../../../../components/icons';

type MapType = 'light-v9' | 'dark-v9' | 'streets-v10' | 'satellite-streets-v10';

interface MapStyle {
  mapType: MapType;
  image: string;
  name: string;
}

interface Props {
  baseMap: MapType;
  setBaseMap: React.Dispatch<React.SetStateAction<MapType>>;
  className?: string;
  style?: React.CSSProperties;
}

const mapStyles: Array<MapStyle> = [
  {
    mapType: 'light-v9',
    name: 'Light',
    image: require('../../../../../images/light.png'),
  },
  {
    mapType: 'dark-v9',
    name: 'Dark',
    image: require('../../../../../images/dark.png'),
  },
  {
    mapType: 'streets-v10',
    name: 'Street',
    image: require('../../../../../images/streets.png'),
  },
  {
    mapType: 'satellite-streets-v10',
    name: 'Satellite',
    image: require('../../../../../images/satellite.jpeg'),
  },
];

const BaseMaps: React.FC<Props> = ({
  baseMap,
  setBaseMap,
  className,
  style,
}) => {
  const [opened, setOpened] = useState(false);

  const handleToggle = useCallback(() => {
    setOpened(openedState => !openedState);
  }, []);

  return (
    <div className={className} style={style}>
      <div className="relative w-10 h-10 z-10">
        {mapStyles.map((mapStyle, index) => {
          return (
            <div
              key={mapStyle.mapType}
              className="cursor-pointer absolute transition-500"
              style={{
                opacity: opened ? 1 : 0,
                transform: opened
                  ? `translate3d(calc(-${100 *
                      (mapStyles.length - index)}% - ${16 *
                      (mapStyles.length - index)}px), 0, 0)`
                  : `translate3d(0, 0, 0)`,
              }}
              onClick={() => {
                setBaseMap(mapStyle.mapType);
                setOpened(false);
              }}
            >
              {mapStyle.mapType === baseMap ? (
                <div className="w-10 h-10 absolute inset-0 rounded-full centered text-white bg-dark-background-color-opacity-75">
                  <Icon type="check" className="fill-current" />
                </div>
              ) : null}
              <img
                src={mapStyle.image}
                alt={mapStyle.name}
                className="w-10 h-10 rounded-full object-cover"
              />
            </div>
          );
        })}
        <div className="map-button absolute" onClick={handleToggle}>
          <Icon component={Map} className="fill-current" />
        </div>
      </div>
    </div>
  );
};

export default memo(BaseMaps);
