import gql from 'graphql-tag';
import { PROJECT_BASIC_INFO_FRAGMENT } from '../../../../queries/project';

export const OVERALL_MR_ITEM_STATS_QUERY = gql`
  query OverallMRItemStats {
    mrItemStats {
      totalCRS
      totalLET
      totalWIP
      totalWNS
    }
  }
`;

export const PROJECTS_WNS_STATS_QUERY = gql`
  query ProjectsWNSStats($cpdId: String) {
    mrItemStatsByProject(cpd: $cpdId) {
      project {
        ...ProjectBasicInfoFragment
      }
      stats {
        totalWNS
        totalCRS
        totalWIP
        totalLET
      }
    }
  }
  ${PROJECT_BASIC_INFO_FRAGMENT}
`;
