import React, { useMemo, useState, useCallback, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Result, Table, Button, Drawer, Icon } from 'antd';
import { range, capitalize } from 'lodash-es';
import clsx from 'clsx';

import { USERS_DETAIL_INFO_QUERY } from '../../../../queries/user';
import UserAvatar from '../../../../components/UserAvatar';
import Card from '../../../../components/Card';
import {
  UsersDetailInfo,
  UsersDetailInfoVariables,
} from '../../../../types/UsersDetailInfo';
import useMedia from '../../../../hooks/useMedia';
import UserDetail from './components/UserDetail';
import CreateUser from './components/CreateUser';
import usePermission from '../../../../hooks/usePermission';
import Header from '../../../../components/Header';
import AuthContext from '../../../../contexts/AuthContext';
import { User } from '../../../../types';
import { ORGANISATION_QUERY } from '../../../../queries/organisation';
import {
  Organisation,
  OrganisationVariables,
} from '../../../../types/Organisation';

const AllUsers: React.FC = () => {
  const { canCreateUser } = usePermission();

  const { isMobile } = useMedia();

  const { user } = useContext(AuthContext);
  const organisationId = (user as User).organisation._id;

  const { data: organisationData } = useQuery<
    Organisation,
    OrganisationVariables
  >(ORGANISATION_QUERY, {
    variables: {
      organisationId,
    },
  });

  const [activeUserId, setActiveUserId] = useState<string | undefined>(
    undefined,
  );

  const { loading, data: usersData, error } = useQuery<
    UsersDetailInfo,
    UsersDetailInfoVariables
  >(USERS_DETAIL_INFO_QUERY);

  const handleDrawerClose = useCallback(() => {
    setActiveUserId(undefined);
  }, []);

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className="">
          {range(5).map((val) => (
            <div className="flex items-center py-2 card" key={val}>
              <div className="mr-4 flex-2 skeleton" />
              <div className="flex-1 mr-4 skeleton" />
              <div className="mr-4 flex-1/2 skeleton" />
              <div className="mr-4 flex-1/2 skeleton" />
              <div className="mr-4 flex-1/2 skeleton" />
              <div className="mr-4 flex-1/2 skeleton" />
            </div>
          ))}
        </div>
      );
    }

    if (error) {
      return <Result status="warning" subTitle={error.message} />;
    }

    if (usersData) {
      if (isMobile) {
        return (
          <Card className="md:py-0 xs:py-0 md:px-0 xs:px-0">
            {usersData.users.map((orgUser, index) => (
              <div
                className={clsx(
                  'p-4',
                  index !== usersData.users.length - 1 ? 'border-b' : undefined,
                )}
                key={orgUser.id}
                onClick={() => {
                  setActiveUserId(orgUser.id);
                }}
              >
                <div className="flex items-center">
                  <span className="mr-4">
                    <UserAvatar
                      userId={orgUser.id}
                      shape="square"
                      size="large"
                    />
                  </span>
                  <div>
                    <div className="mb-1 text-sm font-medium text-heading-color">
                      {orgUser.name}
                    </div>
                    <div className="text-sm text-caption-color">
                      <span className="mr-1 font-medium">
                        {orgUser.role
                          .split('_')
                          .map((val) => capitalize(val))
                          .join(' ')}
                      </span>
                      {orgUser.designation ? (
                        <span>({orgUser.designation})</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex-1" />
                  <a
                    href={`tel:+${orgUser.phone}`}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="block mr-2"
                  >
                    <span className="p-2 border rounded text-primary-color">
                      <Icon type="phone" />
                    </span>
                  </a>
                  <a
                    href={`sms:+${orgUser.phone}`}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span className="p-2 border rounded text-primary-color">
                      <Icon type="message" />
                    </span>
                  </a>
                </div>
              </div>
            ))}
          </Card>
        );
      }
      return (
        <Card className="md:py-0 xs:py-0 md:px-0 xs:px-0">
          <Table
            rowKey="id"
            dataSource={usersData.users}
            pagination={false}
            columns={[
              {
                dataIndex: 'name',
                title: 'User',
                render: (text, record) => {
                  return (
                    <div className="flex items-center flex-1 mr-4">
                      <span className="mr-2">
                        <UserAvatar userId={record.id} />
                      </span>
                      <span>{record.name}</span>
                    </div>
                  );
                },
              },
              {
                dataIndex: 'contact',
                title: 'Contact',
                render: (text, record) => {
                  return (
                    <div>
                      {record.phone ? (
                        <a href={`tel:+${record.phone}`} className="block mb-1">
                          <div className="flex items-center text-sm font-medium">
                            <span className="mr-2">
                              <Icon type="phone" />
                            </span>
                            <span>+{record.phone}</span>
                          </div>
                        </a>
                      ) : null}
                      {record.phone ? (
                        <a href={`sms:+${record.phone}`} className="block mb-1">
                          <div className="flex items-center text-sm font-medium">
                            <span className="mr-2">
                              <Icon type="message" />
                            </span>
                            <span>+{record.phone}</span>
                          </div>
                        </a>
                      ) : null}
                      {record.email ? (
                        <a
                          href={`mailto:${record.email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block"
                        >
                          <div className="flex items-center text-sm">
                            <span className="mr-2">
                              <Icon type="mail" />
                            </span>
                            <span className="text-sm">{record.email}</span>
                          </div>
                        </a>
                      ) : null}
                    </div>
                  );
                },
              },
              {
                dataIndex: 'Role',
                title: 'Role',
                render: (text, record) => {
                  return (
                    <div>
                      {record.role
                        .split('_')
                        .map((val) => capitalize(val))
                        .join(' ')}
                    </div>
                  );
                },
              },
              {
                dataIndex: 'userType',
                title: 'Type',
              },
              {
                dataIndex: 'designation',
                title: 'Designation',
                render: (text, record) => {
                  return <div>{record.designation || 'N/A'}</div>;
                },
              },
              {
                dataIndex: 'projects',
                title: 'Projects Assigned',
                render: (text, record) => {
                  return <div>{(record.projects || []).length} Projects</div>;
                },
              },
            ]}
            onRow={(record) => {
              return {
                onClick: () => {
                  setActiveUserId(record.id);
                },
                style: {
                  cursor: 'pointer',
                },
              };
            }}
          />
        </Card>
      );
    }

    return null;
  }, [error, isMobile, loading, usersData]);
  return (
    <>
      <div className="max-w-5xl p-4 mx-auto">
        <Header
          label={organisationData ? organisationData.organisation.name : ''}
          name="Users"
          icon={{ type: 'usergroup-add' }}
          className="mb-6"
          actions={
            !isMobile && canCreateUser
              ? [
                  <CreateUser
                    key="create-user"
                    trigger={
                      <Button type="link" icon="plus">
                        Create User
                      </Button>
                    }
                  />,
                ]
              : undefined
          }
        />
        {content}
      </div>
      <Drawer
        visible={!!activeUserId}
        onClose={handleDrawerClose}
        width={320}
        title="User Info"
        bodyStyle={{
          height: 'calc(100vh - 55px)',
          overflowY: 'auto',
        }}
      >
        {activeUserId ? (
          <UserDetail userId={activeUserId} onDelete={handleDrawerClose} />
        ) : null}
      </Drawer>
    </>
  );
};

export default AllUsers;
