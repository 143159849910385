import React, { useState, useCallback, useEffect } from 'react';
import ReactPlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Input, message } from 'antd';
import clsx from 'clsx';
import { InputProps } from 'antd/lib/input';
import getLocation from '../../utils/getLocation';

interface Props {
  initialValue?: { latitude: number; longitude: number };
  onChange?: ({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) => void;
  className?: string;
  style?: React.CSSProperties;
  inputProps?: InputProps;
}

const PlacesAutocomplete: React.ForwardRefExoticComponent<Props> = React.forwardRef(
  (
    { initialValue, onChange, className, style, inputProps },
    ref: React.Ref<Input>,
  ) => {
    const [input, setInput] = useState('');

    useEffect(() => {
      const fetchInitialLocation = async () => {
        if (initialValue) {
          const intialLocation = await getLocation({
            lat: initialValue.latitude,
            lng: initialValue.longitude,
          });
          setInput(intialLocation || '');
        }
      };

      fetchInitialLocation();
    }, [initialValue]);

    const handleSelect = useCallback(
      async (address) => {
        try {
          setInput(address);
          const results = await geocodeByAddress(address);
          const { lat, lng } = await getLatLng(results[0]);
          if (onChange) {
            onChange({ latitude: lat, longitude: lng });
          }
        } catch (error) {
          message.error('Something went wrong. Please try again');
        }
      },
      [onChange],
    );

    return (
      <div className={clsx('relative', className)} style={style}>
        <ReactPlacesAutocomplete
          value={input}
          onChange={setInput}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <>
              <Input
                {...getInputProps()}
                {...inputProps}
                placeholder="Search for places..."
                className="w-full"
                ref={ref}
              />
              {suggestions.length > 0 ? (
                <div
                  className="absolute bottom-0 right-0 left-0 bg-white rounded-br rounded-bl border border-border-split-color z-50"
                  style={{ transform: 'translateY(100%)' }}
                >
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      {...getSuggestionItemProps(suggestion, {
                        className: clsx(
                          'p-2 truncate text-xs cursor-pointer hover:bg-primary-color hover:text-white leading-none',
                          suggestion.active
                            ? 'bg-primary-color text-white'
                            : '',
                        ),
                      })}
                    >
                      {suggestion.description}
                    </div>
                  ))}
                </div>
              ) : null}
            </>
          )}
        </ReactPlacesAutocomplete>
      </div>
    );
  },
);

export default PlacesAutocomplete;
