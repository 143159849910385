import React from 'react';
import Axios from 'axios';
import { get } from 'lodash-es';
import Logrocket from 'logrocket';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'querystring';

import AuthContext from '../../contexts/AuthContext';
import Loader from '../Loader';
import { User } from '../../types';

interface LeadInfo {
  name: string;
  organisation: string;
  email: string;
  phone: string;
}

interface Props extends RouteComponentProps {
  children: JSX.Element;
}

interface State {
  authVerified: boolean;
  user?: User;
  authToken?: string;
  leadInfoCaptured?: LeadInfo;
}

class Auth extends React.Component<Props, State> {
  state = {
    authVerified: false,
    user: undefined,
    authToken: undefined,
    leadInfoCaptured: undefined,
  };

  componentDidMount() {
    this._verifyUser();
  }

  _verifyUser = async () => {
    if (
      process.env.REACT_APP_DEMO_USER_NAME &&
      process.env.REACT_APP_DEMO_PASSWORD &&
      process.env.REACT_APP_LOCALSTORAGE_LEAD_INFO_KEY
    ) {
      await this.signInWithUsernameAndPassword(
        process.env.REACT_APP_DEMO_USER_NAME,
        process.env.REACT_APP_DEMO_PASSWORD,
      );
      const { leadInfoCaptured: leadInfoCapturedInLandingPage } = qs.parse(
        this.props.location.search.substring(1),
      );
      if (leadInfoCapturedInLandingPage) {
        window.localStorage.setItem(
          process.env.REACT_APP_LOCALSTORAGE_LEAD_INFO_KEY,
          JSON.stringify(leadInfoCapturedInLandingPage),
        );
      }
      const leadInfoStoredInLocalStorage = window.localStorage.getItem(
        process.env.REACT_APP_LOCALSTORAGE_LEAD_INFO_KEY,
      );
      if (leadInfoStoredInLocalStorage) {
        this.setState({
          leadInfoCaptured: JSON.parse(leadInfoStoredInLocalStorage),
          authVerified: true,
        });
      } else {
        this.setState({ leadInfoCaptured: undefined, authVerified: true });
      }
    }

    const localStorageAuthToken = window.localStorage.getItem(
      process.env.REACT_APP_LOCALSTORAGE_TOKEN_KEY,
    );

    const localStorageUserData = window.localStorage.getItem(
      process.env.REACT_APP_LOCALSTORAGE_USER_KEY,
    );
    let userData: User | undefined;
    if (localStorageUserData) {
      try {
        userData = JSON.parse(localStorageUserData) as User;
      } catch (error) {
        userData = undefined;
      }
    } else {
      userData = undefined;
    }

    if (localStorageAuthToken && userData) {
      this.setState(
        {
          authVerified: true,
          authToken: localStorageAuthToken,
          user: userData,
        },
        () => {
          this.updateLogrocketInfo(userData as User);
          this.refetchUserInfo();
        },
      );
    } else {
      this.setState({
        authVerified: true,
        authToken: undefined,
        user: undefined,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signInWithUsernameAndPassword = async (
    username: string,
    password: string,
  ) => {
    const api = Axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });
    try {
      const { data } = await api.post<{ jwt: string; account: User }>(
        '/login',
        {
          username,
          password,
          platform: 'DASHBOARD',
        },
      );
      this.setState(
        {
          authToken: data.jwt,
          user: data.account,
        },
        () => {
          window.localStorage.setItem(
            process.env.REACT_APP_LOCALSTORAGE_TOKEN_KEY,
            data.jwt,
          );
          window.localStorage.setItem(
            process.env.REACT_APP_LOCALSTORAGE_USER_KEY,
            JSON.stringify(data.account),
          );
          this.updateLogrocketInfo(data.account);
        },
      );
      return {
        success: true,
        error: undefined,
      };
    } catch (error) {
      return {
        success: false,
        error: get(
          error,
          'response.data.message',
          'Something went wrong. Please try again',
        ),
      };
    }
  };

  signInWithPhoneNumber = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    phoneNumber: string,
  ) => {
    return {
      verifyOTP: async () => {},
      resendOTP: async () => {},
    };
  };

  signOut = async () => {
    this.setState({ authToken: undefined, user: undefined }, () => {
      window.localStorage.removeItem(
        process.env.REACT_APP_LOCALSTORAGE_TOKEN_KEY,
      );
      window.localStorage.removeItem(
        process.env.REACT_APP_LOCALSTORAGE_USER_KEY,
      );
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initiateResetPassword = async (email: string) => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetPassword = async (email: string, newPassword: string) => {};

  updateToken = (updatedToken: string) => {
    this.setState({ authToken: updatedToken }, () => {
      window.localStorage.setItem(
        process.env.REACT_APP_LOCALSTORAGE_TOKEN_KEY,
        updatedToken,
      );
    });
  };

  updateLogrocketInfo = (user: User) => {
    if (process.env.REACT_APP_LOGROCKET_ID) {
      Logrocket.identify(user.id, {
        name: user.name,
        email: user.email,
      });
    }
  };

  setLeadInfoCaptured = (leadInfoCaptured: LeadInfo) => {
    this.setState({ leadInfoCaptured }, () => {
      if (process.env.REACT_APP_LOCALSTORAGE_LEAD_INFO_KEY) {
        window.localStorage.setItem(
          process.env.REACT_APP_LOCALSTORAGE_LEAD_INFO_KEY,
          JSON.stringify(leadInfoCaptured),
        );
      }
    });
  };

  refetchUserInfo = async () => {
    try {
      const { authToken } = this.state;
      const { data } = await Axios.get<User>('/users/me', {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
          Authorization: authToken,
        },
      });
      this.setState({
        user: {
          ...data,
        },
      });
    } catch (error) {
      // DO NOTHING
    }
  };

  render() {
    const { children } = this.props;

    const { authToken, user, authVerified, leadInfoCaptured } = this.state;

    const {
      signInWithUsernameAndPassword,
      signInWithPhoneNumber,
      signOut,
      initiateResetPassword,
      resetPassword,
      updateToken,
      setLeadInfoCaptured,
    } = this;

    if (!authVerified) {
      return <Loader text="Verifying User" />;
    }

    return (
      <AuthContext.Provider
        value={{
          authToken,
          user,
          signInWithUsernameAndPassword,
          signInWithPhoneNumber,
          signOut,
          initiateResetPassword,
          resetPassword,
          updateToken,
          leadInfoCaptured,
          setLeadInfoCaptured,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default withRouter(Auth);
