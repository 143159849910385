import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Theme } from '../styles/theme';

const useTheme = () => {
  const theme: Theme = useContext(ThemeContext);

  return theme;
};

export default useTheme;
