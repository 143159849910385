import React from 'react';

const Leaderboard = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="28" width="12" height="20" fill="currentColor" />
      <rect x="36" y="15" width="12" height="33" fill="currentColor" />
      <rect x="18" width="12" height="48" fill="currentColor" />
    </svg>
  );
};

export default Leaderboard;
