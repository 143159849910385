import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { Result } from 'antd';

import OrgChartIndex from './OrgChartIndex';
import { Group, GroupVariables } from '../../../types/Group';
import { GROUP_QUERY } from '../queries';

interface Props {
  groupId: string;
  height: number;
}

const CreateHierarchyTree: React.FC<Props> = ({ groupId, height }) => {
  const { data, error } = useQuery<Group, GroupVariables>(GROUP_QUERY, {
    variables: { groupId },
  });

  const content = useMemo(() => {
    if (error) {
      return <Result subTitle={error.message} />;
    }

    if (data) {
      return (
        <OrgChartIndex
          rootGroupData={data.group}
          disableRootEdit
          readonly={false}
          disableEditNodes={false}
        />
      );
    }

    return null;
  }, [error, data]);

  return (
    <div className="w-full overflow-y-scroll" style={{ height }}>
      {content}
    </div>
  );
};

export default CreateHierarchyTree;
