import React, { useMemo, useState, useCallback } from 'react';
import { useQuery } from 'react-apollo';
import { Result, Row, Col, Empty } from 'antd';
import moment from 'moment';
import { groupBy, orderBy } from 'lodash-es';
import clsx from 'clsx';

import { USER_PHYSICAL_PROGRESSES_QUERY } from '../../../queries/physicalProgress';
import {
  UserPhysicalProgresses,
  UserPhysicalProgressesVariables,
} from '../../../types/UserPhysicalProgresses';
import Skeleton from '../../Skeleton';
import Divider from '../../Divider';
import ImageGallery from '../../ImageGallery';

interface Props {
  userId: string;
  className?: string;
  style?: React.CSSProperties;
}

const ProgressUpdates: React.FC<Props> = ({ userId, className, style }) => {
  const [activeImage, setActiveImage] = useState<string | undefined>(undefined);

  const { loading, data, error } = useQuery<
    UserPhysicalProgresses,
    UserPhysicalProgressesVariables
  >(USER_PHYSICAL_PROGRESSES_QUERY, {
    variables: {
      userId,
    },
  });

  const allImages = data
    ? data.physicalProgresses.data.flatMap((progress) =>
        (progress.photos || []).map((image) => ({
          image,
          progressInfo: progress,
        })),
      )
    : [];

  const handleImageGalleryClose = useCallback(() => {
    setActiveImage(undefined);
  }, []);

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className={className} style={style}>
          <Skeleton title paragraph={{ rows: 2 }} />
          <Skeleton title paragraph={{ rows: 2 }} />
          <Skeleton title paragraph={{ rows: 2 }} />
        </div>
      );
    }

    if (error) {
      return (
        <Result
          status="warning"
          subTitle={error.message}
          className={className}
          style={style}
        />
      );
    }

    if (data) {
      const entryDateGroups = groupBy(
        data.physicalProgresses.data,
        (progressUpdate) => progressUpdate.entryDate,
      );

      const orderedGroups = orderBy(
        Object.keys(entryDateGroups),
        (val) => val,
        'desc',
      );

      return (
        <div className={className} style={style}>
          {orderedGroups.length > 0 ? (
            orderedGroups.map((date, index) => (
              <div
                key={date}
                className={clsx(
                  index !== orderedGroups.length - 1 ? 'mb-4' : undefined,
                )}
              >
                <div className="text-xs font-medium mb-2 bg-light-primary-color text-primary-color px-2 py-1">
                  {moment(Number.parseInt(date, 10)).format('DD MMM YYYY')}
                </div>
                {entryDateGroups[date].map(
                  (progressUpdate, progressUpdateIndex) => (
                    <div
                      key={progressUpdate.id}
                      className={clsx(
                        progressUpdateIndex !== entryDateGroups[date].length - 1
                          ? 'mb-2'
                          : undefined,
                      )}
                    >
                      <div className="text-xs">
                        {progressUpdate.workItem.name} -{' '}
                        <span className="font-medium text-heading-color">
                          {progressUpdate.progress}{' '}
                          {progressUpdate.workItem.scopeUnit}
                        </span>
                      </div>
                      {progressUpdate.photos &&
                      progressUpdate.photos.length > 0 ? (
                        <div className="mt-2">
                          <Row className="-my-2" gutter={8} type="flex">
                            {progressUpdate.photos.map((photo) => (
                              <Col className="my-2" span={6} key={photo}>
                                <div className="w-full pb-full relative rounded overflow-hidden">
                                  <img
                                    src={photo}
                                    alt=""
                                    className="absolute inset-0 w-full h-full object-cover"
                                    onClick={() => {
                                      setActiveImage(photo);
                                    }}
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ) : null}
                      {progressUpdate.remark ? (
                        <div className="text-xs mt-2">
                          ({progressUpdate.remark})
                        </div>
                      ) : null}
                      {progressUpdateIndex !==
                      entryDateGroups[date].length - 1 ? (
                        <Divider className="mt-2" />
                      ) : null}
                    </div>
                  ),
                )}
              </div>
            ))
          ) : (
            <Empty description="No progress updated" />
          )}
        </div>
      );
    }

    return null;
  }, [className, data, error, loading, style]);

  return (
    <>
      <div className={className} style={style}>
        <div className="font-medium uppercase text-xs text-primary-color tracking-wider mb-4">
          Progress Update History
        </div>
        {content}
      </div>
      {allImages.length > 0 ? (
        <ImageGallery
          images={allImages}
          visible={!!activeImage}
          onClose={handleImageGalleryClose}
          startIndex={
            activeImage
              ? allImages.findIndex((image) => image.image === activeImage)
              : undefined
          }
        />
      ) : null}
    </>
  );
};

export default ProgressUpdates;
