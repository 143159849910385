import gql from 'graphql-tag';

export const PERMISSIONS_INFO_QUERY = gql`
  query PermissionsInfo {
    permissions {
      scope
      modules
    }
  }
`;
