import React from 'react';

const Annotate = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.09091C0 0.488417 0.488417 0 1.09091 0H22.3767C22.9792 0 23.4676 0.488417 23.4676 1.09091V12.2659C23.4676 12.8684 22.9792 13.3569 22.3767 13.3569C21.7742 13.3569 21.2858 12.8684 21.2858 12.2659V2.18182H2.18182V21.2858H11.7338C12.3363 21.2858 12.8247 21.7742 12.8247 22.3767C12.8247 22.9792 12.3363 23.4676 11.7338 23.4676H1.09091C0.488417 23.4676 0 22.9792 0 22.3767V1.09091ZM18.0606 12.1212C18.6631 12.1212 19.1515 12.6096 19.1515 13.2121V16.9697H22.9091C23.5116 16.9697 24 17.4581 24 18.0606C24 18.6631 23.5116 19.1515 22.9091 19.1515H19.1515V22.9091C19.1515 23.5116 18.6631 24 18.0606 24C17.4581 24 16.9697 23.5116 16.9697 22.9091V19.1515H13.2121C12.6096 19.1515 12.1212 18.6631 12.1212 18.0606C12.1212 17.4581 12.6096 16.9697 13.2121 16.9697H16.9697V13.2121C16.9697 12.6096 17.4581 12.1212 18.0606 12.1212Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Annotate;
