import { matchPath, useLocation } from 'react-router-dom';

const useProjectId = () => {
  const { pathname } = useLocation();
  const match = matchPath(pathname, {
    path: '/projects/:projectId',
    exact: false,
    strict: false,
  });

  if (match) {
    return (match.params as { projectId: string }).projectId;
  }

  return undefined;
};

export default useProjectId;
