import gql from 'graphql-tag';

export const GROUP_INFO_FRAGMENT = gql`
  fragment GroupInfoFragment on Group {
    id
    name
    children {
      id
      name
    }
    organisation {
      id
      name
    }
    projects {
      id
      name
      photos
      startDate
      targetDateOfCompletion
    }
  }
`;

export const GROUPS_QUERY = gql`
  query Groups($parentGroupId: String) {
    groups(parent: $parentGroupId) {
      ...GroupInfoFragment
    }
  }
  ${GROUP_INFO_FRAGMENT}
`;

export const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroup($name: String!, $parentId: String) {
    createGroup(createGroupData: { name: $name, parent: $parentId }) {
      ...GroupInfoFragment
    }
  }
  ${GROUP_INFO_FRAGMENT}
`;

export const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroup($id: String!, $name: String!) {
    updateGroup(id: $id, updateGroupData: { name: $name }) {
      ...GroupInfoFragment
    }
  }
  ${GROUP_INFO_FRAGMENT}
`;

export const DELETE_GROUP_MUTATION = gql`
  mutation DeleteGroup($id: String!) {
    deleteGroup(id: $id) {
      ...GroupInfoFragment
    }
  }
  ${GROUP_INFO_FRAGMENT}
`;

export const GROUP_QUERY = gql`
  query Group($groupId: String!) {
    group(id: $groupId) {
      ...GroupInfoFragment
    }
  }
  ${GROUP_INFO_FRAGMENT}
`;
