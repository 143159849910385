import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-virtualized/styles.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

import './styles/index.css';
import './styles/tailwind-output-styles.css';
import './styles/weather-icons.min.css';
import App from './App';

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root'),
);
