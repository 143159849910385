import React, { useContext, useCallback } from 'react';
import { useQuery } from 'react-apollo';
import { Spin, Icon, Dropdown, Menu } from 'antd';
import clsx from 'clsx';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';

import moment from 'moment';
import useProjectId from '../../../hooks/useProjectId';
import { PROJECTS_QUERY } from '../../../queries/project';
import { Projects } from '../../../types/Projects';
import AuthContext from '../../../contexts/AuthContext';
import { User } from '../../../types';
import { ORGANISATION_QUERY } from '../../../queries/organisation';
import {
  Organisation,
  OrganisationVariables,
} from '../../../types/Organisation';
import useTheme from '../../../hooks/useTheme';
import { PROJECT_ROUTES } from '../utils';

interface Props {
  drawerExpanded: boolean;
  className?: string;
  style?: React.CSSProperties;
  onProjectChange?: () => void;
}

const ProjectSelector: React.FC<Props> = ({
  className,
  style,
  drawerExpanded,
  onProjectChange,
}) => {
  const theme = useTheme();

  const { pathname } = useLocation();

  const projectId = useProjectId();

  const { user } = useContext(AuthContext);
  const organisationId = (user as User).organisation._id;

  const { loading: loadingProjects, data: projectsData } = useQuery<Projects>(
    PROJECTS_QUERY,
  );

  const { data: organisationData } = useQuery<
    Organisation,
    OrganisationVariables
  >(ORGANISATION_QUERY, {
    variables: {
      organisationId,
    },
  });

  const history = useHistory();

  const handleProjectSelect = useCallback(
    (selectedProjectId: string) => {
      const projectRoutesMatched = PROJECT_ROUTES.flatMap((route) =>
        route.children ? route.children : [route],
      ).filter((route) =>
        route.patterns.some((pattern) =>
          matchPath(pathname, { path: pattern, exact: true, strict: true }),
        ),
      );
      if (projectRoutesMatched.length > 0) {
        const { defaultRoute } = projectRoutesMatched[0];
        if (typeof defaultRoute === 'string') {
          history.push(defaultRoute);
        } else {
          history.push(defaultRoute(selectedProjectId));
        }
      } else {
        history.push(`/projects/${selectedProjectId}/dashboard`);
      }

      if (onProjectChange) {
        onProjectChange();
      }
    },
    [history, onProjectChange, pathname],
  );

  const projectSelected =
    projectId && projectsData
      ? projectsData.projects.find((project) => project.id === projectId)
      : undefined;

  return (
    <Dropdown
      trigger={['click']}
      disabled={!projectsData}
      overlay={
        <StyledMenu selectedKeys={projectId ? [projectId] : undefined}>
          {(projectsData ? projectsData.projects : []).map((project) => (
            <Menu.Item
              key={project.id}
              onClick={() => {
                handleProjectSelect(project.id);
              }}
            >
              <div className="flex items-center px-1 py-2">
                {project && project.photos && project.photos.length > 0 ? (
                  <img
                    className="object-cover w-8 h-8 mr-3 rounded bg-primary-color min-w-8 min-h-8"
                    src={project.photos[0]}
                    alt={project.name}
                  />
                ) : (
                  <div className="flex items-center justify-center w-8 h-8 mr-3 rounded bg-light-primary-color min-w-8 min-h-8">
                    <img
                      src={require('../../../images/logo.svg')}
                      alt="BOCS"
                      className="h-6"
                    />
                  </div>
                )}
                <div>
                  <div className="truncate">{project.name}</div>
                  {project.targetDateOfCompletion ? (
                    <div
                      className="text-xs leading-snug"
                      style={{ color: theme.captionColor }}
                    >
                      Ends in{' '}
                      {moment(project.targetDateOfCompletion).format(
                        'MMM, YYYY',
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </Menu.Item>
          ))}
        </StyledMenu>
      }
    >
      <div
        className={clsx('flex items-center p-4 overflow-hidden', className)}
        style={style}
      >
        {projectSelected &&
        projectSelected.photos &&
        projectSelected.photos.length > 0 ? (
          <img
            className="object-cover w-8 h-8 mr-3 rounded bg-primary-color min-w-8 min-h-8"
            src={projectSelected.photos[0]}
            alt={projectSelected.name}
          />
        ) : (
          <div className="flex items-center justify-center w-8 h-8 mr-3 rounded bg-light-primary-color min-w-8 min-h-8">
            <img
              src={require('../../../images/logo.svg')}
              alt="BOCS"
              className="h-6"
            />
          </div>
        )}
        <div
          className={clsx(
            'flex items-center cursor-pointer transition-250 transition-ease-in',
            drawerExpanded
              ? 'flex-1 opacity-100 pointer-events-auto'
              : 'flex-none opacity-0 pointer-events-none',
          )}
        >
          <div className="flex-1 overflow-hidden">
            <div className="text-sm truncate text-heading-color max-w-40">
              {projectSelected ? projectSelected.name : 'Select Project'}
            </div>
            {organisationData ? (
              <div className="text-xs truncate text-caption-color max-w-40">
                {organisationData.organisation.name}
              </div>
            ) : (
              <div className="w-12 h-3 skeleton" />
            )}
          </div>
          {loadingProjects ? (
            <Spin size="small" indicator={<Icon type="loading" spin />} />
          ) : (
            <Icon type="down" className="text-xs" />
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export default ProjectSelector;

const StyledMenu = styled(Menu)`
  &&& {
    .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
      background-color: transparent;
    }
  }
`;
