import React, { useCallback } from 'react';
import { FlyToInterpolator, ViewportProps } from 'react-map-gl';
import { Icon } from 'antd';

interface Props {
  setViewport: React.Dispatch<React.SetStateAction<ViewportProps>>;
  className?: string;
  style?: React.CSSProperties;
}

const MapControlPanel: React.FC<Props> = ({
  setViewport,
  className,
  style,
}) => {

  const handleZoomIn = useCallback(() => {
    setViewport(viewportState => ({
      ...viewportState,
      zoom: Math.min(viewportState.zoom + 1, viewportState.maxZoom),
      transitionInterpolator: new FlyToInterpolator(),
    }));
  }, [setViewport]);

  const handleZoomOut = useCallback(() => {
    setViewport(viewportState => ({
      ...viewportState,
      zoom: Math.max(viewportState.zoom - 1, viewportState.minZoom),
      transitionInterpolator: new FlyToInterpolator(),
    }));
  }, [setViewport]);

  return (
    <div className={className} style={style}>
      <div className="mb-2 map-button" onClick={handleZoomIn}>
        <Icon type="plus" className="fill-current" />
      </div>
      <div className="mb-2 map-button" onClick={handleZoomOut}>
        <Icon type="minus" className="fill-current" />
      </div>
    </div>
  );
};

export default MapControlPanel;
