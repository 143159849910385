import React, { useState, useCallback, cloneElement } from 'react';
import { Drawer } from 'antd';

import CreateUserForm from './CreateUserForm';

interface Props {
  trigger: JSX.Element;
}

const CreateUser: React.FC<Props> = ({ trigger }) => {
  const [drawerOpened, setDrawerOpened] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpened(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpened(false);
  }, []);

  return (
    <>
      {cloneElement(trigger, {
        onClick: handleDrawerOpen,
      })}
      <Drawer
        onClose={handleDrawerClose}
        visible={drawerOpened}
        destroyOnClose
        title="Create User"
        width={320}
      >
        <CreateUserForm onCreate={handleDrawerClose} />
      </Drawer>
    </>
  );
};

export default CreateUser;
