import React, { cloneElement, useState, useCallback } from 'react';
import { Form, message, Drawer, InputNumber, DatePicker, Button } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useMutation } from 'react-apollo';
import moment from 'moment';
import ImageUploader from '../../../components/ImageUploader';
import {
  UpdateMRItemMutation,
  UpdateMRItemMutationVariables,
} from '../../../types/UpdateMRItemMutation';
import { MRItemInfoFragment } from '../../../types/MRItemInfoFragment';
import {
  UPDATE_MR_ITEM_MUTATION,
  MR_ITEM_INFO_FRAGMENT,
} from '../../../queries/mrItems';

interface Props {
  typeLabel: string;
  mrItem: MRItemInfoFragment;
  trigger: JSX.Element;
  form: WrappedFormUtils;
}

const EditMRItem: React.FC<Props> = ({
  typeLabel,
  mrItem,
  trigger,
  form: { resetFields, getFieldDecorator, validateFields },
}) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
    resetFields();
  }, [resetFields]);

  const [createMRItemMutation, { loading }] = useMutation<
    UpdateMRItemMutation,
    UpdateMRItemMutationVariables
  >(UPDATE_MR_ITEM_MUTATION, {
    update: (cache, { data }) => {
      if (data) {
        cache.writeFragment<MRItemInfoFragment>({
          fragment: MR_ITEM_INFO_FRAGMENT,
          fragmentName: 'MRItemInfoFragment',
          id: mrItem.id,
          data: data.updateMRItem,
        });
      }
    },
    onCompleted: () => {
      message.success(
        <div className="inline-flex space-x-4">
          <span>Refresh the page to see updated changes</span>
          <Button
            size="small"
            onClick={() => {
              window.location.reload();
            }}
            type="primary"
          >
            Update
          </Button>
        </div>,
        0,
      );
      handleDrawerClose();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields((fieldErrors, values) => {
        if (!fieldErrors) {
          const { entryDate, progress, documents } = values as {
            entryDate: moment.Moment;
            progress: number;
            documents?: Array<string>;
          };
          createMRItemMutation({
            variables: {
              mrItemId: mrItem.id,
              entryDate: entryDate.valueOf(),
              progress,
              documents,
            },
          });
        }
      });
    },
    [createMRItemMutation, mrItem.id, validateFields],
  );

  return (
    <>
      {cloneElement(trigger, { onClick: handleDrawerOpen })}
      <Drawer
        visible={open}
        title={`Edit ${typeLabel} entry`}
        onClose={handleDrawerClose}
        destroyOnClose
        width={320}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label={`${typeLabel} (in Km)`}>
            {getFieldDecorator('progress', {
              rules: [{ required: true, message: 'Progress is required' }],
              initialValue: mrItem.progress,
            })(
              <InputNumber placeholder="Progress" style={{ width: '100%' }} />,
            )}
          </Form.Item>
          <Form.Item label={`Date of ${typeLabel}`}>
            {getFieldDecorator('entryDate', {
              initialValue: mrItem.entryDate
                ? moment(mrItem.entryDate)
                : undefined,
            })(
              <DatePicker placeholder="Entry Date" style={{ width: '100%' }} />,
            )}
          </Form.Item>
          <Form.Item label="Documents">
            {getFieldDecorator('documents', {
              valuePropName: 'fileList',
              initialValue: mrItem.documents
                ? mrItem.documents.map((document) => ({
                    uid: document,
                    name: document,
                    status: 'done',
                    url: document,
                  }))
                : undefined,
            })(
              <ImageUploader
                multiple
                accept="image/jpg,image/jpeg,image/png,application/pdf"
              />,
            )}
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default Form.create<Props>({ name: 'editMRItem' })(EditMRItem);
