import React from 'react';
import { useQuery } from 'react-apollo';
import { Result, Icon } from 'antd';

import {
  UserLeaderboardScore,
  UserLeaderboardScoreVariables,
} from '../../../types/UserLeaderboardScore';
import { USER_LEADERBOARD_SCORE_QUERY } from '../../../queries/user';
import Skeleton from '../../Skeleton';
import { Gallery, IssueManagement } from '../../icons';
import deltaDuration from '../../../utils/deltaDuration';

interface Props {
  userId: string;
  className?: string;
  style?: React.CSSProperties;
}

const LeaderBoardScore: React.FC<Props> = ({ userId, className, style }) => {
  const { loading, data, error } = useQuery<
    UserLeaderboardScore,
    UserLeaderboardScoreVariables
  >(USER_LEADERBOARD_SCORE_QUERY, {
    variables: {
      userId,
    },
  });

  if (loading) {
    return (
      <Skeleton
        title
        paragraph={{ rows: 2 }}
        className={className}
        style={style}
      />
    );
  }

  if (error) {
    return (
      <Result
        status="warning"
        subTitle={error.message}
        className={className}
        style={style}
      />
    );
  }

  if (data) {
    return (
      <div className={className} style={style}>
        <div className="flex items-center mb-4">
          <span className="mr-2 text-primary-color">
            <Icon type="hourglass" />
          </span>
          {data.userAdditionalInfo.lastUpdatedAt ? (
            <span>
              <span>
                Progress Updated{' '}
                <span className="font-medium">
                  {deltaDuration(data.userAdditionalInfo.lastUpdatedAt)}
                </span>
              </span>
            </span>
          ) : (
            <span className="text-error-color">Not Started</span>
          )}
        </div>
        <div className="flex items-center mb-4">
          <span className="mr-2 text-primary-color">
            <Icon component={Gallery} />
          </span>
          <span>
            <span className="font-medium">
              {data.userAdditionalInfo.photos.total}
            </span>{' '}
            Photos Uploaded
          </span>
        </div>
        <div className="flex items-center">
          <span className="mr-2 text-primary-color">
            <Icon component={IssueManagement} />
          </span>
          <span>
            <span className="font-medium">
              {data.userAdditionalInfo.issues.total}
            </span>{' '}
            Issues Raised
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default LeaderBoardScore;
