import React, { useMemo, useState, useCallback } from 'react';
import { useQuery } from 'react-apollo';
import { Row, Col, Result, Drawer, Button } from 'antd';
import { range } from 'lodash-es';

import ProjectCard from './components/ProjectCard';
import { Projects } from '../../../../types/Projects';
import { PROJECTS_QUERY } from '../../../../queries/project';
import EditProject from '../../../../components/EditProject';
import CreateProject from '../../../../components/CreateProject';
import usePermission from '../../../../hooks/usePermission';
import useMedia from '../../../../hooks/useMedia';

const AllProjects = () => {
  const { isMobile } = useMedia();

  const { canCreateProject } = usePermission();

  const [activeProjectId, setActiveProjectID] = useState<string | undefined>(
    undefined,
  );

  const handleDrawerClose = useCallback(() => {
    setActiveProjectID(undefined);
  }, []);

  const { loading, error, data } = useQuery<Projects>(PROJECTS_QUERY);

  const content = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={16} className="-my-2">
          {range(6).map((val) => (
            <Col
              xl={6}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              key={val}
              className="my-2"
            >
              <div
                className="overflow-hidden rounded shadow"
                style={{ opacity: 1 - val * 0.1 }}
              >
                <div className="h-32 skeleton" />
                <div className="p-4">
                  <div className="w-full h-4 mb-3 skeleton" />
                  <div className="w-7/12 h-4 skeleton" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      );
    }

    if (error) {
      return <Result status="warning" subTitle={error.message} />;
    }

    if (data) {
      return (
        <Row gutter={16} className="-my-2" type="flex">
          {data.projects.map((project) => (
            <Col
              className="my-2"
              xl={6}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              key={project.id}
            >
              <ProjectCard
                project={project}
                onEditProjectClick={setActiveProjectID}
              />
            </Col>
          ))}
        </Row>
      );
    }

    return null;
  }, [data, error, loading]);

  return (
    <>
      <div className="max-w-5xl p-4 mx-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="text-lg font-semibold text-heading-color">
            Projects Assigned
          </div>
          {canCreateProject && !isMobile ? (
            <CreateProject
              trigger={
                <Button type="primary" icon="plus">
                  Create Project
                </Button>
              }
            />
          ) : null}
        </div>
        {content}
      </div>
      <Drawer
        visible={!!activeProjectId}
        title="Edit Project"
        onClose={handleDrawerClose}
        width={320}
      >
        {activeProjectId ? (
          <EditProject
            projectId={activeProjectId}
            onUpdate={handleDrawerClose}
          />
        ) : null}
      </Drawer>
    </>
  );
};

export default AllProjects;
