import React, { cloneElement, useCallback } from 'react';
import { Modal, Icon, message, Button } from 'antd';
import { useMutation } from 'react-apollo';
import { MRItemInfoFragment } from '../../../types/MRItemInfoFragment';
import {
  DeleteMRItem as DeleteMRItemInterface,
  DeleteMRItemVariables,
} from '../../../types/DeleteMRItem';
import { MRItems, MRItemsVariables } from '../../../types/MRItems';
import {
  DELETE_MR_ITEM_MUTATION,
  MR_ITEMS_QUERY,
} from '../../../queries/mrItems';

interface Props {
  trigger: JSX.Element;
  projectId: string;
  mrItem: MRItemInfoFragment;
}

const DeleteMRItem: React.FC<Props> = ({ trigger, projectId, mrItem }) => {
  const [deleteMRItemMutation] = useMutation<
    DeleteMRItemInterface,
    DeleteMRItemVariables
  >(DELETE_MR_ITEM_MUTATION, {
    variables: { mrItemId: mrItem.id },
    update: (cache, { data }) => {
      if (data) {
        let mrItems;
        try {
          mrItems = cache.readQuery<MRItems, MRItemsVariables>({
            query: MR_ITEMS_QUERY,
            variables: {
              projectId,
            },
          });
        } catch (cacheReadError) {
          mrItems = undefined;
        }

        if (mrItems) {
          cache.writeQuery<MRItems, MRItemsVariables>({
            query: MR_ITEMS_QUERY,
            variables: {
              projectId,
            },
            data: {
              mrItems: mrItems.mrItems.filter(
                (item) => item.id !== data.deleteMRItem.id,
              ),
            },
          });
        }
      }
    },
    onCompleted: () => {
      message.success(
        <div className="inline-flex space-x-4">
          <span>Refresh the page to see updated changes</span>
          <Button
            size="small"
            onClick={() => {
              window.location.reload();
            }}
            type="primary"
          >
            Update
          </Button>
        </div>,
        0,
      );
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleDeleteMRItem = useCallback(() => {
    Modal.confirm({
      title: 'Delete Item',
      content: 'Item once deleted, can be recovered. Do you want to continue?',
      icon: <Icon type="delete" />,
      onOk: () => {
        deleteMRItemMutation();
      },
    });
  }, [deleteMRItemMutation]);

  return cloneElement(trigger, { onClick: handleDeleteMRItem });
};

export default DeleteMRItem;
