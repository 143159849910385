import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { map, range, groupBy } from 'lodash-es';
import { Icon, Drawer } from 'antd';
import clsx from 'clsx';

import TreeControlPanel from './TreeControlPanel';
import { Body } from '../../../components/Typography';
import ActiveProjectGroup from './ActiveProjectGroup';
import MediaContext from '../../../contexts/MediaContext';
import AppShellContext from '../../../contexts/AppShellContext';
import {
  CONTRACTED_DRAWER_WIDTH,
  EXPANDED_DRAWER_WIDTH,
} from '../../../styles/common';

type NodeType = {
  id: string;
  name: string;
  parentId: string | null;
};

interface Dictionary<T> {
  [id: string]: T;
}

/** getTR utility fx for generating lines */

interface getTRType {
  item: NodeType;
  allItems: Dictionary<NodeType[]>;
  addNew: () => void;
  deleteNode: (node: NodeType) => void;
  editNode: (node: NodeType) => void;
  addSpace: boolean;
  editingNodeId: string;
  changeEditingNodeId: (curEditingNodeId: string) => void;
  editable: boolean;
  rootEditable?: boolean;
  disableEditNodes?: boolean;
  onNodeClick: (curSelectedNode: NodeType) => void;
}

function getTR(allArgs: getTRType) {
  const {
    item,
    allItems,
    addNew,
    deleteNode,
    editNode,
    addSpace,
    editingNodeId,
    changeEditingNodeId,
    editable,
    rootEditable,
    disableEditNodes,
    onNodeClick,
  } = allArgs;

  const children = allItems[item.id];
  const colspan = (children ? children.length : 1) * 2;

  const lines = map(range(0, colspan), (l, idx) => {
    if (idx === 0) {
      return <td key={idx} className="border-r" />;
    }
    if (idx + 1 === colspan) {
      return <td key={idx} className="border-l" />;
    }
    if (idx % 2 === 0) {
      return <td key={idx} className="border-t border-r" />;
    }

    return <td key={idx} className="border-t border-l" />;
  });

  return (
    <table
      className={clsx('mx-auto border-separate', addSpace ? 'px-2' : undefined)}
      style={{ borderSpacing: 0 }}
    >
      <tbody>
        <tr className="node">
          <td colSpan={colspan} className="box-border p-0 align-top">
            <NodeBox
              onNodeClick={onNodeClick}
              node={item}
              addNew={addNew}
              deleteNode={deleteNode}
              editNode={editNode}
              editingNodeId={editingNodeId}
              changeEditingNodeId={changeEditingNodeId}
              editable={editable}
              rootEditable={rootEditable}
              disableEditNodes={disableEditNodes}
            />
          </td>
        </tr>

        {children && (
          <tr className="h-6">
            <td colSpan={colspan} className="box-border p-0 align-top">
              <div className="w-0 h-6 m-auto border-l border-r" />
            </td>
          </tr>
        )}

        {children && children.length > 1 && <tr className="h-6">{lines}</tr>}

        {children && children.length === 1 && (
          <tr className="h-6">
            <td colSpan={colspan} className="box-border p-0 align-top">
              <div className="w-0 h-6 m-auto border-l border-r" />
            </td>
          </tr>
        )}

        <tr>
          {map(children, (curItem: NodeType) => {
            return (
              <td
                key={curItem.id}
                colSpan={2}
                className="box-border p-0 align-top"
              >
                {getTR({
                  item: curItem,
                  allItems,
                  addNew,
                  deleteNode,
                  editNode,
                  addSpace: children.length > 1,
                  editingNodeId,
                  changeEditingNodeId,
                  editable,
                  rootEditable,
                  disableEditNodes,
                  onNodeClick,
                })}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}

/** OrgChart */

interface OrgChartProps {
  data: NodeType[];
  editable: boolean;
  disableEditNodes?: boolean;
  rootEditable?: boolean;
  onNodeClick: (curSelectedNode: NodeType) => void;
  addNewChild: () => void;
  deleteNode: (node: NodeType) => void;
  editNode: (node: NodeType) => void;
}

const OrgChart: React.FC<OrgChartProps> = ({
  data,
  addNewChild,
  deleteNode,
  editNode,
  editable,
  rootEditable,
  disableEditNodes,
  onNodeClick,
}) => {
  const { viewportWidth, deviceSize } = useContext(MediaContext);

  const { drawerExpanded } = useContext(AppShellContext);

  const [scale, setScale] = useState<number>(1); // inlcuding and between 1.4 - 1

  const [editingNodeId, setEditingNodeId] = useState<string>('');

  const changeEditingNodeId = useCallback(
    (curEditingNodeId: string) => {
      if (curEditingNodeId !== editingNodeId) {
        setEditingNodeId(curEditingNodeId);
      }
    },
    [editingNodeId, setEditingNodeId],
  );

  const groupedData = groupBy(data, 'parentId');

  const tables = useMemo(
    () =>
      map(groupedData.null, (item) => {
        // it is groupData['null'] - null is the index of the root Node
        return (
          <div className="inline-block align-top" key={item.id}>
            <div style={{ transform: `scale(${scale})` }}>
              {getTR({
                item,
                allItems: groupedData,
                addNew: addNewChild,
                deleteNode,
                editNode,
                addSpace: true,
                editingNodeId,
                changeEditingNodeId,
                editable,
                rootEditable,
                disableEditNodes,
                onNodeClick,
              })}
            </div>
          </div>
        );
      }),
    [
      addNewChild,
      changeEditingNodeId,
      deleteNode,
      disableEditNodes,
      editNode,
      editable,
      editingNodeId,
      groupedData,
      onNodeClick,
      rootEditable,
      scale,
    ],
  );

  const appContainerWidth =
    deviceSize === 'xl' || deviceSize === 'xxl'
      ? viewportWidth -
        (drawerExpanded ? EXPANDED_DRAWER_WIDTH : CONTRACTED_DRAWER_WIDTH)
      : viewportWidth;

  return (
    <div className="" style={{ maxWidth: appContainerWidth }}>
      <TreeControlPanel
        className="absolute top-0 right-0 z-10 pt-8 pr-8 -ml-8"
        curScale={scale}
        updateScale={(change) =>
          setScale((prev) => +(prev + change).toFixed(1))
        }
      />

      <div className="w-full h-full p-4 mx-auto overflow-auto text-center min-h-40">
        {tables}
      </div>
    </div>
  );
};

/** NodeBox */

interface NodeBoxProps {
  onNodeClick: (curSelectedNode: NodeType) => void;
  node: NodeType;
  addNew: () => void;
  deleteNode: (node: NodeType) => void;
  editNode: (node: NodeType) => void;
  editingNodeId: null | string;
  changeEditingNodeId: (curEditingNodeId: string) => void;
  editable: boolean;
  disableEditNodes?: boolean;
  rootEditable?: boolean;
}

const NodeBox: React.FC<NodeBoxProps> = ({
  node,
  addNew,
  deleteNode,
  editNode,
  changeEditingNodeId,
  editingNodeId,
  editable,
  rootEditable,
  disableEditNodes,
  onNodeClick,
}) => {
  const [curNode, setCurNode] = useState<NodeType>(node);

  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (editingNodeId !== curNode.id) {
      setEditMode(false);
    }
  }, [curNode, editingNodeId]);

  const deleteCurNode = useCallback(() => {
    deleteNode(curNode);
  }, [deleteNode, curNode]);

  const editCurNode = useCallback(async () => {
    if (disableEditNodes) return;

    await onNodeClick(curNode);
    setEditMode(true);
    changeEditingNodeId(curNode.id);
  }, [
    disableEditNodes,
    setEditMode,
    changeEditingNodeId,
    curNode,
    onNodeClick,
  ]);

  return (
    <div className="inline-block">
      <Drawer
        title="Edit Group"
        visible={editMode}
        onClose={() => {
          setEditMode(false);
        }}
        width={320}
        bodyStyle={{
          height: 'calc(100vh - 55px)',
          overflowY: 'auto',
        }}
      >
        {editMode ? (
          <ActiveProjectGroup
            group={curNode}
            updateName={async (updatedName: string) => {
              await setCurNode({ ...node, name: updatedName });
              await editNode({ ...node, name: updatedName });
            }}
            deleteNode={async () => {
              await deleteCurNode();
            }}
          />
        ) : null}
      </Drawer>
      <div
        className="w-48 h-20 p-4 bg-white rounded shadow cursor-pointer"
        onClick={() => {
          onNodeClick(curNode);
          setEditMode(true);
          return (curNode.parentId !== null ||
            (curNode.parentId === null && rootEditable)) &&
            editable
            ? editCurNode
            : undefined;
        }}
      >
        <div className="flex flex-col h-full">
          <div className="flex-1 mb-0">
            <Body ellipsis className="w-full">
              {curNode.name}
              <br />
            </Body>
          </div>

          {editable && (
            <div className="flex items-center justify-center w-full px-6 -mb-2 text-primary-color">
              <Icon
                type="plus"
                onClick={async (event) => {
                  event.stopPropagation();
                  await onNodeClick(curNode);
                  addNew();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrgChart;
