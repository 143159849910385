declare global {
  interface Window {
    Intercom: (methodName: string, args?: any) => void;
  }
}

export const hideIntercomBubble = () => {
  if (window && window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }
};

export const showIntercomBubble = () => {
  if (window && window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: false,
    });
  }
};

export const showIntercomChat = () => {
  if (window && window.Intercom) {
    window.Intercom('show');
  }
};
