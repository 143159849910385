import React from 'react';

const RFI = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 106 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5 4.00019C72.5 5.68044 72.5 7.60024 72.5 8.90014V13.8008L92 13.8007C92 19.9601 92 24.44 92 13.8007V87.3001C92 91.1988 90.4592 94.9377 87.7164 97.6945C84.9737 100.451 81.2538 102 77.375 102H28.625C24.7462 102 21.0263 100.451 18.2836 97.6945C15.5408 94.9377 14 91.1988 14 87.3001L14.0003 13.8008C14.0002 16.8829 14.0003 14.9507 14.0003 13.8008H33.5V4.00056C34.6143 4.00006 35.6823 4.00099 38.3745 4.00071L57.875 4.00086C58.3578 4.00086 58.9152 4.00087 59.5182 4.00088C62.146 4.00094 65.6415 4.00102 67.623 4.00019C68.9163 3.99965 70.8286 4.00045 72.5 4.00019ZM33.5 23.6007H23.7501L23.75 87.3001C23.75 88.5997 24.2636 89.846 25.1778 90.7649C26.0921 91.6838 27.3321 92.2001 28.625 92.2001H77.375C78.6679 92.2001 79.9079 91.6838 80.8221 90.7649C81.7364 89.846 82.25 88.5997 82.25 87.3001V23.6007H72.5L72.5 28.5007C72.5 31.2068 70.3174 33.4006 67.625 33.4006H38.375C35.6826 33.4006 33.5 31.2068 33.5 28.5006L33.5 23.6007ZM43.25 23.6007H62.75L62.75 13.8008C61.6336 13.8008 60.5123 13.8008 59.5163 13.8008C58.9138 13.8008 58.3571 13.8008 57.875 13.8008L43.25 13.8007L43.25 23.6007ZM33.5 53.0004C33.5 50.2943 35.6826 48.1005 38.375 48.1005H43.2987C43.2987 53.0004 43.2987 50.2943 43.2987 53.0004C43.2987 55.7066 43.2987 53.0004 43.2987 57.9004H38.4237C35.7314 57.9004 33.5 55.7066 33.5 53.0004ZM72.5 53.0004C72.5 50.2943 70.3174 48.1005 67.625 48.1005H48.125C48.125 53.0004 48.125 50.2943 48.125 53.0004C48.125 55.7066 48.125 53.0004 48.125 57.9004H67.625C70.3174 57.9004 72.5 55.7066 72.5 53.0004ZM33.5 72.6003C33.5 69.8941 35.6826 67.7003 38.375 67.7003H43.2987C43.2987 71.7596 43.2987 69.8941 43.2987 72.6003C43.2987 75.3064 43.2987 75.6796 43.2987 77.5002H38.375C35.6826 77.5002 33.5 75.3064 33.5 72.6003ZM48.125 72.6003C48.125 69.8941 48.125 71.7596 48.125 67.7003H67.625C70.3174 67.7003 72.5 69.8941 72.5 72.6003C72.5 75.3064 70.3174 77.5002 67.625 77.5002H48.125C48.125 75.1196 48.125 75.3064 48.125 72.6003Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RFI;
