import gql from 'graphql-tag';

export const PROJECT_LOCATION_DETAIL_FRAGMENT = gql`
  fragment ProjectLocationDetailFragment on Project {
    id
    status
    name
    startDate
    agencies {
      id
      name
    }
    location {
      coordinates
      type
    }
    cummProgressPercentage
    cummPlannedProgressPercentage
    targetDateOfCompletion
    projectedTDC
  }
`;

export const PROJECT_LOCATIONS_DETAIL_QUERY = gql`
  query ProjectLocationsDetail {
    projects {
      ...ProjectLocationDetailFragment
    }
  }
  ${PROJECT_LOCATION_DETAIL_FRAGMENT}
`;

export const PROJECT_LOCATION_DRAWER_DETAIL_QUERY = gql`
  query ProjectLocationDrawerDetail($projectId: String!) {
    project(id: $projectId) {
      ...ProjectLocationDetailFragment
    }
  }
  ${PROJECT_LOCATION_DETAIL_FRAGMENT}
`;