import React from 'react';
import { Icon } from 'antd';
import clsx from 'clsx';
import { CustomIconComponentProps } from 'antd/lib/icon';

interface Props {
  icon: {
    type?: string;
    component?: React.ComponentType<CustomIconComponentProps>;
  };
  label: string;
  content: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const NewInfo: React.FC<Props> = ({
  icon,
  label,
  content,
  className,
  style,
}) => {
  return (
    <div className={clsx('flex items-baseline', className)} style={style}>
      <div className="mr-2 text-primary-color">
        <Icon component={icon.component} type={icon.type} />
      </div>
      <div className="flex-1">
        <div className="mb-1 font-medium tracking-wider uppercase text-caption text-primary-color">
          {label}
        </div>
        <div className="text-xs">{content}</div>
      </div>
    </div>
  );
};

export default NewInfo;
