import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Dropdown, Menu, Tooltip, Button, Icon, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { capitalize } from 'lodash';

import ProjectSelector from './components/ProjectSelector';
import { PROJECT_ROUTES, ORGANISATION_ROUTES } from './utils';
import MenuOption from './components/MenuOption';
import UserAvatar from '../UserAvatar';
import AuthContext from '../../contexts/AuthContext';
import {
  showIntercomChat,
  hideIntercomBubble,
  showIntercomBubble,
} from '../../utils/intercom';
import useProjectId from '../../hooks/useProjectId';
import useMedia from '../../hooks/useMedia';
import AppShellContext from '../../contexts/AppShellContext';
import Notifications from './components/Notifications';
import ChangePasswordModal from './components/ChangePasswordModal';
import { isModuleEnabled } from '../../utils/permission';

const isDemo =
  process.env.REACT_APP_DEMO_PASSWORD && process.env.REACT_APP_DEMO_USER_NAME;

interface Props {}

const AppShell: React.FC<Props> = ({ children }) => {
  const projectId = useProjectId();

  const { user, signOut, leadInfoCaptured } = useContext(AuthContext);

  const { isDesktop } = useMedia();

  const [passwordModalVisible, setPasswordModalVisible] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [drawerExpanded, setDrawerExpanded] = useState(false);

  const handleDrawerClose = useCallback(() => {
    setDrawerVisible(false);
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawerVisible((visibleState) => !visibleState);
  }, []);

  const toggleDrawerExpansion = useCallback(() => {
    setDrawerExpanded((expandedState) => !expandedState);
  }, []);

  const handleProductTourClick = useCallback(() => {
    window.location.href = 'https://demo.bocs.work/?product_tour_id=90428';
  }, []);

  const closeDrawerOnSmallerDevices = useCallback(() => {
    if (!isDesktop) {
      handleDrawerClose();
    }
  }, [handleDrawerClose, isDesktop]);

  useEffect(() => {
    if (!isDemo && user) {
      hideIntercomBubble();
    }
    return () => {
      showIntercomBubble();
    };
  }, [user]);

  useEffect(() => {
    if (!isDesktop) {
      setDrawerExpanded(true);
    }
  }, [isDesktop]);

  if (isDemo && !leadInfoCaptured) {
    return children as JSX.Element;
  }

  if (!user) {
    return children as JSX.Element;
  }

  const menuContent = (
    <div
      className={clsx(
        'h-full bg-white flex flex-col transition-250 transition-ease-in',
        drawerExpanded ? 'w-64' : 'w-16',
      )}
    >
      <ProjectSelector
        drawerExpanded={drawerExpanded}
        onProjectChange={closeDrawerOnSmallerDevices}
      />
      <div
        className="flex-1 overflow-auto"
        onClick={closeDrawerOnSmallerDevices}
      >
        {(projectId ? PROJECT_ROUTES : ORGANISATION_ROUTES)
          .filter((route) => {
            return route.moduleName
              ? isModuleEnabled(user, route.moduleName)
              : true;
          })
          .map((route) => (
            <MenuOption
              menuOption={route}
              key={route.id}
              drawerExpanded={drawerExpanded}
            />
          ))}
      </div>
      {isDesktop ? (
        <button
          className="flex items-center justify-center py-2 border-t border-border-split-color focus:outline-none"
          onMouseDown={toggleDrawerExpansion}
          type="button"
        >
          <Icon
            type="left"
            style={{
              transform: drawerExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
            className="transition-250 transition-ease-in"
          />
        </button>
      ) : null}
    </div>
  );

  return (
    <AppShellContext.Provider value={{ drawerExpanded, setDrawerExpanded }}>
      <>
        <div className="flex w-screen h-screen bg-body-background-color">
          {isDesktop ? (
            <div className="relative z-20 h-full shadow">{menuContent}</div>
          ) : null}
          <div
            className="flex flex-col flex-1 transition-ease-in transition-250"
            style={{
              maxWidth: isDesktop
                ? drawerExpanded
                  ? 'calc(100vw - 16rem)'
                  : 'calc(100vw - 1.25rem)'
                : '100vw',
            }}
          >
            <div className="relative z-10 flex items-center h-12 px-4 bg-white shadow">
              {!isDesktop ? (
                <button
                  type="button"
                  className="px-2 py-1 -mx-2"
                  onClick={toggleDrawer}
                >
                  <Icon type="menu" />
                </button>
              ) : null}
              <div className="flex-1" />
              <Tooltip title="Home" placement="bottom">
                <Link to="/" className="mr-4 text-base">
                  <Icon type="home" />
                </Link>
              </Tooltip>
              <Link to="/notifications" className="mr-4 text-base">
                <Notifications />
              </Link>
              {isDemo ? (
                <Tooltip title="Product Tour" placement="bottom">
                  <Button
                    icon="rocket"
                    type="primary"
                    onClick={handleProductTourClick}
                    className="mr-4"
                  >
                    View Tour
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Help & Support" placement="bottom">
                  <button
                    className="mr-4 text-base cursor-pointer focus:outline-none text-caption-color"
                    type="button"
                    onClick={showIntercomChat}
                  >
                    <Icon
                      type="question-circle"
                      className="fill-current"
                      theme="filled"
                    />
                  </button>
                </Tooltip>
              )}
              {user && !isDemo && (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <Link to="/profile">
                          <div className="mb-1">{user.name}</div>
                          <div className="flex items-center justify-between space-x-3">
                            {user.userType ? (
                              <span className="px-2 text-xs font-medium rounded text-primary-color bg-light-primary-color">
                                {user.userType}
                              </span>
                            ) : null}
                            <span className="px-2 text-xs font-medium bg-green-100 rounded text-success-color">
                              {user.role
                                .split('_')
                                .map((val) => capitalize(val))
                                .join(' ')}
                            </span>
                          </div>
                        </Link>
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item onClick={() => setPasswordModalVisible(true)}>
                        Change Password
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item onClick={signOut}>Logout</Menu.Item>
                    </Menu>
                  }
                >
                  <UserAvatar userId={user.id} size="small" hideTooltip />
                </Dropdown>
              )}
            </div>
            <div className="flex-1 w-full overflow-x-auto overflow-y-auto">
              {children}
            </div>
          </div>
        </div>
        {!isDesktop ? (
          <Drawer
            visible={drawerVisible}
            bodyStyle={{ padding: 0, overflow: 'hidden', height: '100%' }}
            placement="left"
            closable={false}
            maskClosable
            onClose={handleDrawerClose}
          >
            {menuContent}
          </Drawer>
        ) : null}
        {passwordModalVisible ? (
          <ChangePasswordModal
            visible={passwordModalVisible}
            setVisible={(status: boolean) => {
              setPasswordModalVisible(status);
            }}
          />
        ) : null}
      </>
    </AppShellContext.Provider>
  );
};

export default AppShell;
