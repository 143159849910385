import React, { useMemo, useContext } from 'react';
import { useQuery } from 'react-apollo';
import clsx from 'clsx';
import { Result, Table } from 'antd';
import { PROJECTS_WNS_STATS_QUERY } from '../queries';
import { ProjectsWNSStats } from '../../../../../types/ProjectsWNSStats';
import TableLoader from '../../../../../components/TableLoader';
import { CPD_MR_ITEM_STATS_QUERY } from '../../../../../modules/MRDashboard/queries';
import {
  CPDMrItemStats,
  CPDMrItemStatsVariables,
} from '../../../../../types/CPDMrItemStats';
import AuthContext from '../../../../../contexts/AuthContext';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const WNSStats: React.FC<Props> = ({ className, style }) => {
  const { user } = useContext(AuthContext);

  const { data: overallMRItemStatsData } = useQuery<
    CPDMrItemStats,
    CPDMrItemStatsVariables
  >(CPD_MR_ITEM_STATS_QUERY, {
    variables: {
      cpdId: user?.id || '',
    },
  });

  const itemStats = overallMRItemStatsData
    ? overallMRItemStatsData.mrItemStats.totalWNS
    : undefined;

  const { data, error, loading } = useQuery<ProjectsWNSStats>(
    PROJECTS_WNS_STATS_QUERY,
  );

  const content = useMemo(() => {
    if (loading) {
      return <TableLoader columnCount={2} rowCount={4} />;
    }

    if (error) {
      return <Result status="warning" subTitle={error.message} />;
    }

    if (data) {
      return (
        <div className="space-y-4">
          <Table
            rowKey="id"
            dataSource={data.mrItemStatsByProject}
            className="overflow-hidden border rounded-md"
            columns={[
              {
                dataIndex: 'project.name',
                title: <span className="table-title">Project Name</span>,
                render: (text) => (
                  <span className="text-xs font-medium text-heading-color">
                    {text}
                  </span>
                ),
              },
              {
                dataIndex: 'stats.totalWNS',
                title: (
                  <span className="table-title">Work Not Started for</span>
                ),
                render: (text) => (
                  <div className="flex items-center justify-between space-x-4">
                    <span className="text-xs">
                      {text === null || text === undefined
                        ? 'N/A'
                        : `${text} RKm`}
                    </span>
                  </div>
                ),
              },
            ]}
            scroll={{ y: 300 }}
            pagination={false}
          />
        </div>
      );
    }
    return null;
  }, [data, error, loading]);

  return (
    <div
      className={clsx('bg-white rounded-md shadow', className)}
      style={style}
    >
      <div className="flex items-center justify-between px-4 py-2 border-b">
        <div className="font-medium text-heading-color">Work Not Started</div>
        <div className="w-6 h-6 bg-red-500 rounded-full" />
      </div>
      <div className="p-4">
        <div className="mb-4 text-lg font-semibold text-heading-color">
          {itemStats || 0} RKm
        </div>
        {content}
      </div>
    </div>
  );
};

export default WNSStats;
