import React, { useContext } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, Switch, Link, Redirect } from 'react-router-dom';

import LoginForm from './components/LoginForm';
import Card from '../../components/Card';
import { Heading, Caption } from '../../components/Typography';
import Route from '../../components/Route';
import AuthContext from '../../contexts/AuthContext';
import ResetPasswordForm from './components/ResetPasswordForm';

interface Props extends RouteComponentProps {}

const Login: React.FC<Props> = ({ match: { url } }) => {
  const { user, authToken } = useContext(AuthContext);

  if (user && authToken) {
    const redirectTo = '/';
    return (
      <Redirect
        to={{
          pathname: redirectTo,
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>BOCS - Login</title>
      </Helmet>
      <div className="flex items-center justify-center w-full h-screen bg-dark-background-color">
        <div className="w-80">
          <Card className="xs:mx-4 sm:mx-4">
            <Switch>
              <Route
                path={url}
                exact
                render={() => (
                  <>
                    <HeaderContainer>
                      <StyledHeading level={3}>Login</StyledHeading>
                      <Link to={`${url}/reset-password`}>
                        <StyledCaption>Forgot Password?</StyledCaption>
                      </Link>
                    </HeaderContainer>
                    <LoginForm />
                  </>
                )}
              />
              <Route
                path={`${url}/reset-password`}
                render={() => (
                  <>
                    <HeaderContainer>
                      <StyledHeading level={3}>Reset Password</StyledHeading>
                      <Link to={url}>
                        <StyledCaption>Back to Login</StyledCaption>
                      </Link>
                    </HeaderContainer>
                    <ResetPasswordForm />
                  </>
                )}
              />
            </Switch>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Login;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledHeading = styled(Heading)`
  &&& {
    margin-bottom: 0;
  }
`;

const StyledCaption = styled(Caption)`
  &&&& {
    color: inherit;
  }
`;
