import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'antd';

import omitProps from '../../utils/omitProps';

type Level = 1 | 2 | 3;

interface Props extends React.ComponentProps<typeof Typography.Paragraph> {
  paragraph?: boolean;
  level?: Level;
}

const Body: React.FC<Props> = ({ paragraph, level, ...restProps }) => {
  if (paragraph) {
    return <StyledParagraph level={level} {...restProps} />;
  }

  return (
    <StyledText level={level} {...restProps} ellipsis={!!restProps.ellipsis} />
  );
};

Body.defaultProps = {
  paragraph: false,
  level: 2,
};

export default Body;

const textStyles = css<Props>`
  font-size: ${({ level }) =>
    level === 2 ? 14 : level === 3 ? 12 : undefined}px;
  font-weight: 400;
  color: ${({ theme, strong }) =>
    strong ? theme.headingColor : theme.textColor};
`;

const StyledParagraph = styled(omitProps(Typography.Paragraph, ['level']))<{
  level?: Level;
}>`
  &&& {
    ${textStyles};
  }
`;

const StyledText = styled(omitProps(Typography.Text, ['level']))<{
  level?: Level;
}>`
  &&& {
    ${textStyles};
  }
`;
