import React, { cloneElement, useCallback, useState } from 'react';
import { Form, Modal, InputNumber, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useMutation } from 'react-apollo';

import { ProjectBasicInfoFragment } from '../../../../../types/ProjectBasicInfoFragment';
import {
  UPDATE_PROJECT,
  PROJECT_BASIC_INFO_FRAGMENT,
} from '../../../../../queries/project';
import {
  UpdateProject,
  UpdateProjectVariables,
} from '../../../../../types/UpdateProject';

interface Props {
  project: ProjectBasicInfoFragment;
  trigger: JSX.Element;
  form: WrappedFormUtils;
}

const UpdateProjectLength: React.FC<Props> = ({
  trigger,
  project,
  form: { resetFields, validateFields, getFieldDecorator },
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalVisible(false);
    resetFields();
  }, [resetFields]);

  const [updateProjectMutation, { loading }] = useMutation<
    UpdateProject,
    UpdateProjectVariables
  >(UPDATE_PROJECT, {
    update: (cache, { data }) => {
      if (data) {
        cache.writeFragment<ProjectBasicInfoFragment>({
          id: project.id,
          fragment: PROJECT_BASIC_INFO_FRAGMENT,
          fragmentName: 'ProjectBasicInfoFragment',
          data: data.updateProject,
        });
      }
    },
    onCompleted: () => {
      handleModalClose();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleSubmit = useCallback(
    (
      event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>,
    ) => {
      event.preventDefault();
      validateFields((fieldErrors, values) => {
        if (!fieldErrors) {
          const { projectLength } = values as { projectLength?: number };
          updateProjectMutation({
            variables: { projectId: project.id, projectLength },
          });
        }
      });
    },
    [project.id, updateProjectMutation, validateFields],
  );

  return (
    <>
      {cloneElement(trigger, { onClick: handleModalOpen })}
      <Modal
        visible={modalVisible}
        onCancel={handleModalClose}
        okButtonProps={{ onClick: handleSubmit, loading }}
        title="Update Project Length"
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Project Length">
            {getFieldDecorator('projectLength', {
              initialValue: project.projectLength,
            })(
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Project Length"
              />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Form.create<Props>({ name: 'updateProjectLength' })(
  UpdateProjectLength,
);
