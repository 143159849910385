import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useMutation } from 'react-apollo';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

import { NotificationFragment } from '../../../types/NotificationFragment';
import UserAvatar from '../../../components/UserAvatar';
import DurationFromNow from '../../../components/DurationFromNow';
import {
  MarkReadNotification,
  MarkReadNotificationVariables,
} from '../../../types/MarkReadNotification';
import {
  MARK_READ_NOTIFICATION_MUTATION,
  NOTIFICATIONS_QUERY,
} from '../../../queries/notification';
import {
  Notifications,
  NotificationsVariables,
} from '../../../types/Notifications';

interface Props {
  notification: NotificationFragment;
  className?: string;
  style?: React.CSSProperties;
}

const NotificationCard: React.FC<Props> = ({
  notification,
  className,
  style,
}) => {
  const history = useHistory();

  const [markReadMutation] = useMutation<
    MarkReadNotification,
    MarkReadNotificationVariables
  >(MARK_READ_NOTIFICATION_MUTATION, {
    variables: {
      notificationId: notification.id,
    },
    update: (cache, { data }) => {
      if (data) {
        let allNotifications;
        try {
          allNotifications = cache.readQuery<
            Notifications,
            NotificationsVariables
          >({
            query: NOTIFICATIONS_QUERY,
            variables: {
              page: 1,
            },
          });
        } catch (cacheReadError) {
          allNotifications = undefined;
        }

        if (allNotifications) {
          cache.writeQuery<Notifications, NotificationsVariables>({
            query: NOTIFICATIONS_QUERY,
            variables: {
              page: 1,
            },
            data: {
              notifications: {
                ...allNotifications.notifications,
                data: allNotifications.notifications.data.map(
                  (cachedNotification) => {
                    if (cachedNotification.id === notification.id) {
                      return {
                        ...cachedNotification,
                        read: true,
                      };
                    }

                    return cachedNotification;
                  },
                ),
                stats: {
                  ...allNotifications.notifications.stats,
                  unread: allNotifications.notifications.stats.unread
                    ? allNotifications.notifications.stats.unread - 1
                    : allNotifications.notifications.stats.unread,
                },
              },
            },
          });
        }
      }
    },
    optimisticResponse: {
      updateNotification: {
        __typename: 'Notification',
        ...notification,
        read: true,
      },
    },
    onCompleted: () => {
      redirectToNotificationLink();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const redirectToNotificationLink = useCallback(() => {
    if (notification.type === 'DRAWING') {
      history.push(
        `/projects/${notification.project.id}/drawings/${notification.data.target}`,
      );
    } else if (notification.type === 'ISSUE') {
      history.push(
        `/projects/${notification.project.id}/issues/${notification.data.target}`,
      );
    } else if (notification.type === 'DRIVE') {
      if (notification.data.itemType === 'FOLDER') {
        history.push(
          `/projects/${notification.project.id}/drive/my-drive/${notification.data.target}`,
        );
      } else {
        window.open(notification.data.externalUrl, '_blank');
      }
    } else if (notification.type === 'BILL') {
      history.push(
        `/projects/${notification.project.id}/financial-progress/bills/${notification.data.target}`,
      );
    } else if (notification.type === 'RFI') {
      history.push(
        `/projects/${notification.project.id}/rfi/rfis/${notification.data.target}`,
      );
    } else if (notification.type === 'NCR') {
      history.push(
        `/projects/${notification.project.id}/ncr/ncrs/${notification.data.target}`,
      );
    }
  }, [history, notification]);

  const handleNotificationClick = useCallback(() => {
    if (notification.read) {
      redirectToNotificationLink();
    } else {
      markReadMutation();
    }
  }, [markReadMutation, notification.read, redirectToNotificationLink]);

  return (
    <div
      key={notification.id}
      className={clsx(
        'p-4 flex items-center cursor-pointer',
        notification.read ? undefined : 'bg-gray-100',
        className,
      )}
      style={style}
      onMouseDown={handleNotificationClick}
    >
      {notification.actor ? (
        <span className="mr-3">
          <UserAvatar userId={notification.actor.id} size={32} />
        </span>
      ) : null}
      <div className="overflow-hidden text-sm">
        <div className="mb-1 font-medium text-heading-color">
          {notification.message}
        </div>
        {notification.type === 'ISSUE' ? (
          <div className="mb-1 text-xs truncate">
            {notification.data.description}
          </div>
        ) : null}
        {notification.type === 'DRIVE' ? (
          <div className="mb-1 text-xs truncate">{notification.data.name}</div>
        ) : null}
        {notification.type === 'DRAWING' ? (
          <div className="mb-1 text-xs truncate">{notification.data.name}</div>
        ) : null}
        <DurationFromNow timestamp={notification.createdAt}>
          {(duration) => (
            <div className="font-medium uppercase text-caption">{duration}</div>
          )}
        </DurationFromNow>
      </div>
    </div>
  );
};

export default NotificationCard;
