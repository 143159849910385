import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Menu } from 'antd';

import Card from '../../../../../components/Card';
import { Caption, Heading } from '../../../../../components/Typography';
import useMedia from '../../../../../hooks/useMedia';
import getThumbnail from '../../../../../utils/getThumbnail';
import { ProjectBasicInfoFragment } from '../../../../../types/ProjectBasicInfoFragment';
import usePermission from '../../../../../hooks/usePermission';

interface Props {
  project: ProjectBasicInfoFragment;
  onEditProjectClick?: (projectId: string) => void;
}

const ProjectCard: React.FC<Props> = ({ project, onEditProjectClick }) => {
  const { isMobile } = useMedia();

  const { canEditProject } = usePermission();

  return (
    <StyledLink to={`/projects/${project.id}/dashboard`}>
      <Container>
        <ImageContainer>
          {project.photos && project.photos.length > 0 ? (
            <Image
              src={
                getThumbnail({
                  url: project.photos[0],
                  mimeType: 'image/*',
                }).url
              }
            />
          ) : (
            <Thumbnail
              src={require('../../../../../images/project-thumbnail.svg')}
              style={{ width: isMobile ? '25%' : '50%' }}
            />
          )}
        </ImageContainer>
        <ContentContainer>
          <HeadingContainer>
            <div className="flex items-center justify-between group">
              <StyledHeading level={4}>{project.name}</StyledHeading>
              {canEditProject && onEditProjectClick ? (
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className="opacity-50 hover:opacity-100"
                >
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          onClick={() => {
                            onEditProjectClick(project.id);
                          }}
                        >
                          Edit Project
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Icon type="more" />
                  </Dropdown>
                </span>
              ) : null}
            </div>
            {project.startDate && project.targetDateOfCompletion ? (
              <StyledCaption>
                {moment(project.startDate).year()} -
                {moment(project.targetDateOfCompletion).year()}
              </StyledCaption>
            ) : null}
          </HeadingContainer>

          <Spacer />

          {project.targetDateOfCompletion ? (
            <InfoContainer>
              <Caption uppercase>TDC</Caption>
              <Spacer />
              <Caption uppercase>
                {moment(project.targetDateOfCompletion).format('DD MMM, YYYY')}
              </Caption>
            </InfoContainer>
          ) : null}
        </ContentContainer>
      </Container>
    </StyledLink>
  );
};

export default ProjectCard;

const Container = styled(Card)`
  &&& {
    padding: 0 !important;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.borderSplitColor};
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Thumbnail = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const HeadingContainer = styled.div`
  margin-bottom: 24px;

  &&& {
    & > * + * {
      margin-top: 4px;
    }
  }
`;

const StyledHeading = styled(Heading)`
  &&& {
    margin-bottom: 0;
    display: inline;
  }
`;

const StyledCaption = styled(Caption)`
  &&& {
    display: block;
  }
`;

const InfoContainer = styled.div<{ secondary?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 16px;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const StyledLink = styled(Link)`
  display: block;
  height: 100%;
`;
