import React from 'react';
import styled from 'styled-components';
import Icon, { CustomIconComponentProps } from 'antd/lib/icon';
import { Tooltip } from 'antd';
import clsx from 'clsx';

import { Caption, Heading } from '../Typography';
import useMedia from '../../hooks/useMedia';

type IconType = {
  type?: string;
  component?:
    | React.ComponentClass<CustomIconComponentProps>
    | React.FC<CustomIconComponentProps>;
};

interface Props {
  label: string;
  name: string | JSX.Element;
  icon: IconType;
  info?: Array<{
    value: JSX.Element | string | number;
    label: JSX.Element | string | number;
  }>;
  breadcrumb?: JSX.Element;
  actions?: Array<JSX.Element | null>;
  level?: 1 | 2;
  className?: string;
  style?: React.CSSProperties;
  loadingInfo?: boolean;
}

const Header: React.FC<Props> = ({
  label,
  name,
  level,
  icon,
  info,
  className,
  style,
  actions,
  breadcrumb,
}) => {
  const { isMobile } = useMedia();

  return (
    <div className={className} style={style}>
      <StyledCaption level={2} uppercase>
        {label}
      </StyledCaption>
      <HeadingContainer>
        <StyledIcon
          type={icon.type}
          component={icon.component}
          level={isMobile ? 2 : level}
        />
        <Tooltip title={name}>
          <StyledHeading
            level={isMobile ? 3 : level === 1 ? 2 : 3}
            ellipsis={{ rows: 2 }}
          >
            {name}
          </StyledHeading>
        </Tooltip>
        <Spacer />
        {actions}
      </HeadingContainer>
      {breadcrumb ? (
        <BreadcrumbContainer>{breadcrumb}</BreadcrumbContainer>
      ) : null}
      {info ? (
        <InfoContainer>
          {info.map((item, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                <div
                  className={clsx(
                    index !== info.length - 1
                      ? 'mr-4 md:mr-8 xl:mr-16'
                      : undefined,
                  )}
                >
                  <div className="font-medium tracking-wide uppercase text-caption">
                    {item.label}
                  </div>
                  <InfoValue level={3}>{item.value}</InfoValue>
                </div>
                {index !== info.length - 1 ? <Divider /> : null}
              </React.Fragment>
            );
          })}
        </InfoContainer>
      ) : null}
    </div>
  );
};

Header.defaultProps = {
  level: 1,
};

export default Header;

const StyledCaption = styled(Caption)`
  &&& {
    display: block;
    margin-bottom: 8px;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 16px;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const StyledIcon = styled(Icon)<{ level?: 1 | 2 }>`
  font-size: ${({ level }) => (level === 1 ? 32 : 24)}px;
`;

const StyledHeading = styled(Heading)`
  &&& {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const BreadcrumbContainer = styled.div`
  margin-top: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

const InfoValue = styled(Heading)`
  &&& {
    margin-bottom: 0;
    margin-top: 4px;
  }
`;

const Divider = styled.div`
  margin-right: 16px;
  background-color: ${({ theme }) => theme.headingColor};
  opacity: 0.1;
  width: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
`;
