import React, { useState, useCallback, useContext } from 'react';
import { Button, Form, Row, Col, Input, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { isEmail } from 'validator';
import axios from 'axios';
import { get } from 'lodash-es';

import AuthContext from '../../../contexts/AuthContext';

interface Props {
  form: WrappedFormUtils;
}

const LeadInfoModal: React.FC<Props> = ({
  form: { getFieldDecorator, validateFields },
}) => {
  const { setLeadInfoCaptured } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (
      event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>,
    ) => {
      event.preventDefault();
      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          setLoading(true);
          const leadInfo = {
            name: values.name,
            organisation: values.organisation,
            email: values.email,
            phone: values.phone,
          };
          try {
            await axios.post('/leads', leadInfo, {
              baseURL: process.env.REACT_APP_API_BASE_URL,
            });
            setLeadInfoCaptured(leadInfo);
            message.success(
              'Our team would contact you soon. Please enjoy your demo',
            );
          } catch (error) {
            const errorMessage = get(
              error,
              'response.data.message',
              'Something went wrong. Please try again',
            );
            message.error(errorMessage);
          } finally {
            setLoading(false);
          }
        }
      });
    },
    [setLeadInfoCaptured, validateFields],
  );

  return (
    <div className="p-4 rounded bg-white">
      <Form colon={false} onSubmit={handleSubmit}>
        <Row gutter={16} className="mb-4">
          <Col span={12}>
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Name is required' }],
              })(<Input placeholder="Name" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Organisation">
              {getFieldDecorator('organisation', {
                rules: [
                  { required: true, message: 'Organisation is required' },
                ],
              })(<Input placeholder="Organisation Name" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                // @ts-ignore
                validate: [
                  {
                    trigger: 'onChange',
                    rules: [
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ],
                  },
                  {
                    trigger: 'onBlur',
                    rules: [
                      {
                        validator: async (rules: any, value: string) => {
                          const isValid = isEmail(value);

                          if (!isValid) {
                            throw new Error('Email is not valid');
                          }
                        },
                        message: 'Email is not valid',
                      },
                    ],
                  },
                ],
                validateFirst: true,
              })(<Input placeholder="Email" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone">
              {getFieldDecorator('phone', {
                rules: [{ required: true, message: 'Phone is required' }],
              })(<Input placeholder="Phone" />)}
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          key="submit"
          htmlType="submit"
          onClick={handleSubmit}
          loading={loading}
          className="w-full"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Form.create({ name: 'leadInfo' })(LeadInfoModal);
