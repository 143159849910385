import gql from 'graphql-tag';

export const GALLERY_PROGRESS_INFO_FRAGMENT = gql`
  fragment GalleryProgressInfoFragment on PhysicalProgress {
    id
    entryDate
    photos
    remark
    progress
    author {
      id
      name
      profileImage
      phone
      email
    }
    geoLocation {
      type
      id
      properties
      geometry {
        type
        coordinates
      }
    }
    workItem {
      id
      name
      scopeUnit
    }
    projectElement {
      id
      name
    }
    category {
      id
      name
    }
  }
`;

export const LATEST_PHYSICAL_PROGRESS_QUERY = gql`
  query LatestPhysicalProgress($projectId: String!, $page: Int!) {
    physicalProgresses(
      project: $projectId
      limit: 20
      page: $page
      photos: true
      sort: "entryDate"
      order: "desc"
    ) {
      data {
        ...GalleryProgressInfoFragment
      }
      stats {
        totalPages
        page
        hasMore
      }
    }
  }
  ${GALLERY_PROGRESS_INFO_FRAGMENT}
`;

export const WORKING_ITEM_PHYSICAL_PROGRESSES_QUERY = gql`
  query WorkingItemPhysicalProgresses(
    $workingItemId: String!
    $page: Int!
    $limit: Int!
  ) {
    physicalProgresses(
      workItem: $workingItemId
      limit: $limit
      page: $page
      photos: true
      sort: "entryDate"
      order: "desc"
    ) {
      data {
        ...GalleryProgressInfoFragment
      }
      stats {
        totalPages
        page
        hasMore
      }
    }
  }
  ${GALLERY_PROGRESS_INFO_FRAGMENT}
`;

export const PROJECT_ELEMENT_PHYSICAL_PROGRESSES_QUERY = gql`
  query ProjectElementPhysicalProgresses(
    $projectElementId: String!
    $page: Int!
    $limit: Int!
  ) {
    physicalProgresses(
      projectElement: $projectElementId
      limit: $limit
      page: $page
      photos: true
      sort: "entryDate"
      order: "desc"
    ) {
      data {
        ...GalleryProgressInfoFragment
      }
      stats {
        totalPages
        page
        hasMore
      }
    }
  }
  ${GALLERY_PROGRESS_INFO_FRAGMENT}
`;

export const PROJECT_PHYSICAL_PROGRESSES_QUERY = gql`
  query ProjectPhysicalProgresses(
    $projectId: String!
    $page: Int!
    $limit: Int!
  ) {
    physicalProgresses(
      project: $projectId
      limit: $limit
      page: $page
      photos: true
      sort: "entryDate"
      order: "desc"
    ) {
      data {
        ...GalleryProgressInfoFragment
      }
      stats {
        totalPages
        page
        hasMore
      }
    }
  }
  ${GALLERY_PROGRESS_INFO_FRAGMENT}
`;

export const MONTHLY_PHYSICAL_PROGRESSES_QUERY = gql`
  query MonthlyPhysicalProgresses($projectId: String!, $date: Float!) {
    physicalProgresses(project: $projectId, monthly: true, date: $date) {
      data {
        ...GalleryProgressInfoFragment
      }
    }
  }
  ${GALLERY_PROGRESS_INFO_FRAGMENT}
`;

export const PHYSICAL_PROGRESS_QUERY = gql`
  query PhysicalProgress($progressId: String!) {
    physicalProgress(id: $progressId) {
      ...GalleryProgressInfoFragment
    }
  }
  ${GALLERY_PROGRESS_INFO_FRAGMENT}
`;

export const USER_PHYSICAL_PROGRESSES_QUERY = gql`
  query UserPhysicalProgresses($userId: String!) {
    physicalProgresses(
      author: $userId
      limit: 20
      page: 1
      sort: "entryDate"
      order: "desc"
    ) {
      data {
        ...GalleryProgressInfoFragment
      }
    }
  }
  ${GALLERY_PROGRESS_INFO_FRAGMENT}
`;

export const DAILY_UPDATED_PHYSICAL_PROGRESSES = gql`
  query DailyUpdatedPhysicalProgresses(
    $projectId: String!
    $from: Float!
    $to: Float!
  ) {
    physicalProgresses(project: $projectId, from: $from, to: $to) {
      data {
        ...GalleryProgressInfoFragment
      }
    }
  }
  ${GALLERY_PROGRESS_INFO_FRAGMENT}
`;
