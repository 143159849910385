import React from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const IndeterminateProgress: React.FC<Props> = ({ className, style }) => {
  return (
    <div
      className={clsx('relative, w-full h-1 overflow-x-hidden', className)}
      style={style}
    >
      <div className="absolute opacity-25 h-1 w-full bg-primary-color" />
      <div className="absolute h-1 bg-primary-color inc" />
      <div className="absolute h-1 bg-primary-color dec" />
    </div>
  );
};

export default IndeterminateProgress;
