import React from 'react';

interface Props {
  style?: React.CSSProperties;
  className?: string;
}

const Divider: React.FC<Props> = ({ className, style }) => {
  return (
    <div
      className={`bg-border-split-color ${className || ''}`}
      style={{
        height: 1,
        ...style,
      }}
    />
  );
};

export default Divider;
