import gql from 'graphql-tag';

export const CPD_MR_ITEM_STATS_QUERY = gql`
  query CPDMrItemStats($cpdId: String!) {
    mrItemStats(cpd: $cpdId) {
      totalCRS
      totalLET
      totalWIP
      totalWNS
    }
  }
`;
