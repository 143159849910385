import React from 'react';

const Checklist = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 105 105" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 8.89543 18.8954 8 20 8H86.9585V94.5736L65.7321 84.2552L50.2901 92.8341L35.1179 84.4051L18 95.5V10ZM25.6621 15.6621V81.4032L34.8141 75.4713L50.2901 84.069L65.4963 75.6212L79.2964 82.3296V15.6621H25.6621Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.8035 37.6484C67.8035 38.2007 67.3558 38.6484 66.8035 38.6484H37.1553V31.9863C37.1553 31.434 37.603 30.9863 38.1553 30.9863H67.8035V37.6484Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.3104 56.8032C56.3104 57.3555 55.8627 57.8032 55.3104 57.8032H37.1553V51.1411C37.1553 50.5888 37.603 50.1411 38.1553 50.1411H56.3104V56.8032Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 8.89543 18.8954 8 20 8H86.9585V94.5736L65.7321 84.2552L50.2901 92.8341L35.1179 84.4051L18 95.5V10ZM25.6621 15.6621V81.4032L34.8141 75.4713L50.2901 84.069L65.4963 75.6212L79.2964 82.3296V15.6621H25.6621Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Checklist;
