import React from 'react';

const Location = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C12 24 20 12.4183 20 8C20 3.58173 16.4183 0 12 0C7.58173 0 4 3.58173 4 8C4 12.4183 12 24 12 24ZM12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Location;
