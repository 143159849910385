import React from 'react';

const IssueManagement: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.955 2.104a1.143 1.143 0 011.616 1.04v16c0 .83-.859 1.384-1.616 1.04L6.61 14.572H3.178a2.286 2.286 0 01-2.217-1.731L.035 9.135a1.143 1.143 0 011.108-1.42H6.61l12.345-5.611zm-.67 2.815L7.555 9.796c-.296.135-.619.205-.945.205H2.607l.571 2.286H6.61c.326 0 .649.07.946.204l10.73 4.878V4.919z"
        fill="currentColor"
      />
      <path
        d="M3.654 15.4l1.428 5.713a1.812 1.812 0 001.758-2.251l-.433-1.731a2.286 2.286 0 00-2.217-1.732h-.536zM23.508 13.139l-1.794 1.242V7.908l1.759 1.124c.328.21.527.573.527.963V12.2c0 .375-.184.726-.492.94z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IssueManagement;
