import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../components/Route';
import SummaryView from './components/SummaryView';
import ProjectTreeDetailedView from '../ProjectTreeDetailedView';

interface Props {}

const ProjectTree: React.FC<Props> = () => {
  return (
    <Switch>
      <Route
        path={[
          '/project-tree',
        ]}
        exact
        component={SummaryView}
        protectedRoute
      />
      <Route
        path="/project-tree/heirarchy/:groupId"
        component={ProjectTreeDetailedView}
        protectedRoute
      />
    </Switch>
  );
};

export default ProjectTree;
