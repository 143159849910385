import React, { useContext } from 'react';
import { RouteComponentProps, Redirect, Switch, Link } from 'react-router-dom';
import clsx from 'clsx';

import AuthContext from '../../contexts/AuthContext';
import { isModuleEnabled } from '../../utils/permission';
import Route from '../../components/Route';
import AllProjects from './scenes/AllProjects';
import MRData from './scenes/MRData';

interface Props extends RouteComponentProps {}

const Home: React.FC<Props> = ({
  history: {
    location: { pathname },
  },
}) => {
  const { user } = useContext(AuthContext);

  const isMasterDashboardEnabled = user
    ? isModuleEnabled(user, 'MASTER_DASHBOARD')
    : false;

  if (isMasterDashboardEnabled) {
    if (user?.organisation.masterDashboardVersion === 1) {
      return <Redirect to={{ pathname: '/master-dashboard' }} />;
    }
    if (user?.organisation.masterDashboardVersion === 2) {
      return <Redirect to={{ pathname: '/mr-dashboard' }} />;
    }
  }

  return (
    <>
      <div className="max-w-5xl p-4 mx-auto">
        <div className="flex p-2 bg-gray-200 rounded-md">
          <Link to="/">
            <div
              className={clsx(
                'mx-2 px-4 py-2 rounded text-sm font-medium my-1 sm:my-0',
                pathname === '/'
                  ? 'bg-gray-700 text-gray-100'
                  : 'text-gray-700',
              )}
            >
              All Projects
            </div>
          </Link>
          {user?.organisation.masterDashboardVersion === 2 ? (
            <Link to="/mr-data">
              <div
                className={clsx(
                  'mx-2 px-4 py-2 rounded text-sm font-medium my-1 sm:my-0',
                  pathname === '/mr-data'
                    ? 'bg-gray-700 text-gray-100'
                    : 'text-gray-700',
                )}
              >
                MR Data
              </div>
            </Link>
          ) : null}
        </div>
      </div>
      <Switch>
        <Route component={AllProjects} path="/" exact protectedRoute />
        {user?.organisation.masterDashboardVersion === 2 ? (
          <Route component={MRData} path="/mr-data" protectedRoute />
        ) : null}
      </Switch>
    </>
  );
};

export default Home;
