/* global google */
const getLocation = async ({
  lat,
  lng,
}: {
  lat: number;
  lng: number;
}): Promise<string | undefined> => {
  const geocoder = new google.maps.Geocoder();
  return new Promise(resolve => {
    geocoder.geocode({ location: { lat, lng } }, results => {
      if (results && results.length > 0) {
        resolve(results[results.length - 3].formatted_address);
      }

      resolve(undefined);
    });
  });
};

export default getLocation;
