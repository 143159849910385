import React, { useCallback } from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import validator from 'validator';
import { useMutation } from 'react-apollo';

import styled from 'styled-components';
import {
  CreateUser,
  CreateUserVariables,
} from '../../../../../types/CreateUser';
import {
  CREATE_USER_MUTATION,
  USERS_DETAIL_INFO_QUERY,
} from '../../../../../queries/user';
import {
  UsersDetailInfo,
  UsersDetailInfoVariables,
} from '../../../../../types/UsersDetailInfo';
import countryCodes from '../../../../../utils/countryCodes';

interface Props {
  form: WrappedFormUtils;
  onCreate: () => void;
}

const CreateUserForm: React.FC<Props> = ({
  form: { getFieldDecorator, validateFields },
  onCreate,
}) => {
  const [createUserMutation, { loading }] = useMutation<
    CreateUser,
    CreateUserVariables
  >(CREATE_USER_MUTATION, {
    update: (cache, { data }) => {
      if (data) {
        let usersData;
        try {
          usersData = cache.readQuery<
            UsersDetailInfo,
            UsersDetailInfoVariables
          >({
            query: USERS_DETAIL_INFO_QUERY,
          });
        } catch (cacheReadError) {
          usersData = undefined;
        }

        if (usersData) {
          cache.writeQuery<UsersDetailInfo, UsersDetailInfoVariables>({
            query: USERS_DETAIL_INFO_QUERY,
            data: {
              users: [...usersData.users, data.createUser.account],
            },
          });
        }
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('User data updated successfully');
      onCreate();
    },
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields((fieldErrors, values) => {
        if (!fieldErrors) {
          createUserMutation({
            variables: {
              name: values.name,
              email: values.email || undefined,
              countryCode: values.countryCode,
              phone:
                values.countryCode && values.phoneNumber
                  ? `${values.countryCode.replace('+', '')}${
                      values.phoneNumber
                    }`
                  : undefined,
              role: values.role,
              designation: values.designation || '',
            },
          });
        }
      });
    },
    [createUserMutation, validateFields],
  );
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Name is required' }],
        })(<Input placeholder="Name" />)}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator('email', {
          // @ts-ignore
          validate: [
            {
              trigger: 'onBlur',
              rules: [
                {
                  validator: async (rules: any, value: string) => {
                    if (value && !validator.isEmail(value)) {
                      throw new Error('Email is not valid');
                    }
                  },
                  message: 'Email is not valid',
                },
              ],
            },
          ],
        })(<Input type="email" placeholder="Email" />)}
      </Form.Item>
      <div className="ant-form-item-label">
        <label>Phone Number</label>
      </div>
      <PhoneInputContainer className="flex">
        <Form.Item>
          {getFieldDecorator('countryCode')(
            <Select
              placeholder="Country Code"
              style={{ minWidth: 120, maxWidth: 120 }}
              showSearch
              filterOption={(input: string, option: any) => {
                return (
                  (option.props.children as string)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {countryCodes.map((code) => (
                <Select.Option key={code.dialCode}>
                  {`${code.dialCode} - ${code.name}`}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('phoneNumber')(
            <Input placeholder="Phone Number" />,
          )}
        </Form.Item>
      </PhoneInputContainer>
      <Form.Item label="Designation">
        {getFieldDecorator('designation')(<Input placeholder="Designation" />)}
      </Form.Item>
      <Form.Item label="User Type">
        {getFieldDecorator('userType')(
          <Select placeholder="User Role">
            <Select.Option key="CONTRACTOR">Contractor</Select.Option>
            <Select.Option key="PMC">PMC</Select.Option>
            <Select.Option key="ARCHITECT">Architect</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="User Role">
        {getFieldDecorator('role', {
          rules: [{ required: true, message: 'Role is required' }],
        })(
          <Select placeholder="User Role">
            <Select.Option key="FIELD_SUPERVISOR">
              Field Supervisor
            </Select.Option>
            <Select.Option key="ORG_ADMIN">Org Admin</Select.Option>
            <Select.Option key="DASHBOARD_USER">Dashboard User</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Submit
      </Button>
    </Form>
  );
};

export default Form.create<Props>({ name: 'createUser' })(CreateUserForm);

const PhoneInputContainer = styled.div`
  & .ant-select-selection {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & input {
    border-left-color: transparent;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;
