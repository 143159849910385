import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Icon } from 'antd';
import { useTransition, animated, useSpring } from 'react-spring';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import usePrevious from '../../hooks/usePrevious';
import { GalleryProgressInfoFragment } from '../../types/GalleryProgressInfoFragment';
import getThumbnail from '../../utils/getThumbnail';
import MiniMap from '../MiniMap';

interface Props {
  visible?: boolean;
  onClose?: () => void;
  images: Array<{
    image: string;
    progressInfo: GalleryProgressInfoFragment;
  }>;
  startIndex?: number;
}

const portalRoot = document.createElement('div');
portalRoot.id = 'gallery-root-container';
portalRoot.style.position = 'relative';
// TODO: Fix z-index order, I really hate this kind of 99999sssss
portalRoot.style.zIndex = '99999999';
document.body.appendChild(portalRoot);

const ImageGallery: React.FC<Props> = ({
  visible,
  images,
  onClose,
  startIndex,
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(startIndex || 0);

  const container = useRef<HTMLDivElement | null>(null);

  const imageContainer = useRef<HTMLDivElement | null>(null);

  const [direction, setDirection] = useState<'FORWARD' | 'BACKWARD'>('FORWARD');

  const { opacity, pointerEvents } = useSpring({
    opacity: visible ? 1 : 0,
    pointerEvents: visible ? 'auto' : 'none',
  });

  const transitions = useTransition(
    images[activeImageIndex],
    (image) => image.image,
    {
      from: {
        opacity: 0,
        transform:
          direction === 'FORWARD'
            ? 'translate3d(100%,0,0)'
            : 'translate3d(-100%,0,0)',
      },
      enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      leave: {
        opacity: 0,
        transform:
          direction === 'FORWARD'
            ? 'translate3d(-50%,0,0)'
            : 'translate3d(50%,0,0)',
      },
    },
  );

  const prevStartIndex = usePrevious(startIndex);

  const history = useHistory();

  useEffect(() => {
    const { pathname, search, hash } = history.location;
    let unsubscribe: () => void | undefined;
    if (visible) {
      history.push(`${pathname}${search}#gallery`);
      unsubscribe = history.listen((location, action) => {
        if (action === 'POP' && onClose) {
          onClose();
        }
      });
    } else if (!visible && hash) {
      history.goBack();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [history, onClose, visible]);

  const handleNextImageClick = useCallback(() => {
    setDirection('FORWARD');
    setActiveImageIndex(
      (activeImageIndexState) => (activeImageIndexState + 1) % images.length,
    );
  }, [images.length]);

  const handlePrevImageClick = useCallback(() => {
    setDirection('BACKWARD');
    setActiveImageIndex(
      (activeImageIndexState) =>
        (((activeImageIndexState - 1) % images.length) + images.length) %
        images.length,
    );
  }, [images.length]);

  const handleWrapperClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === container.current && onClose) {
        onClose();
      }
    },
    [onClose],
  );

  const handleThumbnailClick = useCallback(
    (index: number) => {
      if (index !== activeImageIndex) {
        if (index < activeImageIndex) {
          setDirection('BACKWARD');
        } else if (index > activeImageIndex) {
          setDirection('FORWARD');
        }
        setActiveImageIndex(index);
      }
    },
    [activeImageIndex],
  );

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode === 27 && onClose) {
        onClose();
      } else if (event.keyCode === 39) {
        handleNextImageClick();
      } else if (event.keyCode === 37) {
        handlePrevImageClick();
      }
    };

    if (visible) {
      window.addEventListener('keydown', handleKeydown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [handleNextImageClick, handlePrevImageClick, onClose, visible]);

  useEffect(() => {
    const activeThumbnail = document.getElementById(
      images[activeImageIndex].image,
    );
    if (activeThumbnail) {
      activeThumbnail.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }
  }, [activeImageIndex, images]);

  useEffect(() => {
    if (startIndex !== undefined && prevStartIndex !== startIndex) {
      setActiveImageIndex(startIndex);
    }
  }, [prevStartIndex, startIndex]);

  return createPortal(
    <animated.div
      style={{
        opacity,
        pointerEvents,
      }}
      className="fixed inset-0 flex flex-col p-0 bg-dark-background-color-opacity-95 md:p-4"
      ref={container}
      onClick={handleWrapperClick}
    >
      {/* Close button */}
      <div
        className="absolute top-0 right-0 z-10 flex items-center justify-center w-8 h-8 m-2 bg-white rounded-full cursor-pointer md:m-4 text-heading-color"
        onClick={onClose}
      >
        <Icon type="close" />
      </div>

      {/* Prev Button */}
      <div
        className="absolute left-0 z-10 p-4 text-white rounded-sm cursor-pointer fill-current bg-dark-background-color-opacity-25"
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
        }}
        onClick={handlePrevImageClick}
      >
        <Icon type="left" className="text-2xl md:text-3xl" />
      </div>

      {/* Next Button */}
      <div
        className="absolute right-0 z-10 p-4 text-white rounded-sm cursor-pointer fill-current bg-dark-background-color-opacity-25"
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
        }}
        onClick={handleNextImageClick}
      >
        <Icon type="right" className="text-2xl md:text-3xl" />
      </div>

      {/* Gallery */}
      <div className="relative flex-1 w-full mb-0 md:mb-4">
        {transitions.map(({ item, props, key }) => {
          return (
            <animated.div
              key={key}
              style={props}
              className="absolute inset-0 w-full h-full select-none"
              ref={imageContainer}
            >
              <img
                src={item.image}
                alt=""
                className="object-contain w-full h-full"
              />
              {item.progressInfo.geoLocation ? (
                <MiniMap
                  latitude={
                    item.progressInfo.geoLocation.geometry.coordinates[1]
                  }
                  longitude={
                    item.progressInfo.geoLocation.geometry.coordinates[0]
                  }
                  className="absolute bottom-0 right-0 mb-16 mr-3"
                />
              ) : null}
              <div className="absolute bottom-0 left-0 right-0 flex items-center p-2 text-xs bg-dark-background-color-opacity-75">
                <div className="flex-1" />
                <span className="text-white">
                  <span className="mr-2 font-medium">
                    {moment(item.progressInfo.entryDate).format(
                      'DD MMM YYYY, hh:mm a',
                    )}{' '}
                    -
                  </span>
                  <span className="mr-2">
                    {item.progressInfo.workItem.name} (
                    {item.progressInfo.projectElement.name} -{' '}
                    {item.progressInfo.category.name}) -
                  </span>
                  <span>
                    {item.progressInfo.progress}{' '}
                    {item.progressInfo.workItem.scopeUnit}
                  </span>
                </span>
                <div className="flex-1" />
                <a
                  href={item.image}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block p-2 ml-2 border rounded-sm border-white-opacity-50"
                >
                  <span className="text-white-opacity-50">
                    <Icon type="download" className="text-lg fill-current" />
                  </span>
                </a>
              </div>
            </animated.div>
          );
        })}
      </div>

      {/* Thumbnails list */}
      <div className="justify-center hidden -mx-1 overflow-x-auto md:flex">
        {images.map((image, index) => (
          <div
            key={image.image}
            className={`min-w-20 w-20 h-20 mx-1 border-4 cursor-pointer ${
              activeImageIndex === index
                ? 'border-primary-color'
                : 'border-transparent'
            }`}
            id={image.image}
            onClick={handleThumbnailClick.bind(null, index)}
          >
            <img
              src={getThumbnail({ url: image.image, mimeType: 'image/*' }).url}
              alt=""
              className="object-cover w-full h-full"
            />
          </div>
        ))}
      </div>
    </animated.div>,
    portalRoot,
  );
};

export default ImageGallery;
