import React, { useContext } from 'react';
import {
  RouteProps,
  Route as ReactRouterRoute,
  Redirect,
} from 'react-router-dom';
import invariant from 'tiny-invariant';

import AuthContext from '../../contexts/AuthContext';
import { isModuleEnabled } from '../../utils/permission';

interface Props extends RouteProps {
  protectedRoute?: boolean;
  moduleName?: string;
}

const Route: React.FC<Props> = ({
  protectedRoute,
  moduleName,
  location,
  ...routeProps
}) => {
  invariant(
    typeof moduleName === 'string' ? protectedRoute : true,
    'Module protection is only available for protectedRoute',
  );

  const { authToken, user, leadInfoCaptured } = useContext(AuthContext);

  if (protectedRoute) {
    const isDemoVersion =
      process.env.REACT_APP_DEMO_PASSWORD &&
      process.env.REACT_APP_DEMO_USER_NAME;

    if (isDemoVersion && !leadInfoCaptured) {
      return (
        <Redirect
          to={{
            pathname: '/get-started',
            state: { redirectedFrom: location ? location.pathname : undefined },
          }}
        />
      );
    }

    if (!(authToken && user)) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { redirectedFrom: location ? location.pathname : undefined },
          }}
        />
      );
    }
  }

  if (moduleName && user && !isModuleEnabled(user, moduleName)) {
    return <Redirect to={{ pathname: '/no-access' }} />;
  }

  return <ReactRouterRoute {...routeProps} />;
};

Route.defaultProps = {
  protectedRoute: false,
};

export default Route;
