import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import LeadInfoModal from './components/LeadInfoModal';
import AuthContext from '../../contexts/AuthContext';

interface Props extends RouteComponentProps {}

const GetStarted: React.FC<Props> = ({ location }) => {
  const isDemoVersion =
    process.env.REACT_APP_DEMO_PASSWORD && process.env.REACT_APP_DEMO_USER_NAME;

  const { leadInfoCaptured } = useContext(AuthContext);

  if (!(isDemoVersion && !leadInfoCaptured)) {
    return (
      <Redirect
        to={{
          pathname:
            location && location.state ? location.state.redirectedFrom : '/',
        }}
      />
    );
  }

  return (
    <div className="bg-dark-background-color w-full h-screen flex items-center p-4 md:p-0">
      <Row className="w-full">
        <Col xxl={8} xl={8} md={4} />
        <Col xxl={8} xl={8} md={16}>
          <div className="flex items-center justify-center mb-4">
            <img
              src={require('../../images/logo.svg')}
              alt="BOCS"
              className="h-6 mr-4"
            />
            <div className="text-gray-100 text-lg">BOCS</div>
          </div>
          <h1 className="text-gray-300 text-lg md:text-2xl font-thin text-center mb-6">
            Monitor Multiple Construction Projects <br />
            on a Single Platform
          </h1>
          <LeadInfoModal />
        </Col>
        <Col xxl={8} xl={8} md={4} />
      </Row>
    </div>
  );
};

export default GetStarted;
