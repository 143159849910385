import React from 'react';
import { Icon } from 'antd';
import clsx from 'clsx';

interface Props {
  curScale: number;
  updateScale: (changeUnit: number) => void;
  className?: string;
  style?: React.CSSProperties;
}

const TreeControlPanel: React.FC<Props> = ({
  curScale,
  updateScale,
  className,
  style,
}) => {
  return (
    <div
      className={clsx(className, '')}
      style={style}
    >
      <div
        className={clsx(
          'mb-2 map-button',
          curScale === 1 ? 'bg-caption-color opacity-50 cursor-default' : undefined,
        )}
        onClick={() => {
          if (curScale < 1) {
            updateScale(+0.1);
          }
        }}
      >
        <Icon type="plus" className="fill-current" />
      </div>
      <div
        className={clsx(
          'mb-2 map-button',
          curScale === 0.4 ? 'bg-caption-color opacity-50 cursor-default' : undefined,
        )}
        onClick={() => {
          if (curScale >= 0.5) {
            updateScale(-0.1);
          }
        }}
      >
        <Icon type="minus" className="fill-current" />
      </div>
    </div>
  );
};

export default TreeControlPanel;
