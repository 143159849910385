import gql from 'graphql-tag';

export const DOWNLOAD_REPORT_MUTATION = gql`
  mutation DownloadReport(
    $columns: [String!]!
    $startDate: Float!
    $endDate: Float!
    $projectId: String!
    $header: String
    $footer: String
  ) {
    exportReport(
      exportReportData: {
        fields: $columns
        startDate: $startDate
        endDate: $endDate
        header: $header
        footer: $footer
      }
      project: $projectId
    ) {
      url
      id
    }
  }
`;

export const REPORT_FIELDS_QUERY = gql`
  query ReportFields {
    reportFields {
      key
      label
    }
  }
`;
