import gql from 'graphql-tag';

export const USER_AVATAR_QUERY = gql`
  query UserAvatarInfo($id: String!) {
    user(id: $id) {
      id
      name
      profileImage
      phone
      email
    }
  }
`;
