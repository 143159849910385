import React, { useState, useEffect } from 'react';
import MediaContext from '../../contexts/MediaContext';

export type DeviceSize = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';

const getDeviceSize = (viewport: number): DeviceSize => {
  if (viewport >= 1600) {
    return 'xxl';
  }
  if (viewport >= 1200) {
    return 'xl';
  }
  if (viewport >= 992) {
    return 'lg';
  }
  if (viewport >= 768) {
    return 'md';
  }
  if (viewport >= 576) {
    return 'sm';
  }
  return 'xs';
};

const Media: React.FC = ({ children }) => {
  const [viewportWidth, setViewportWidth] = useState<number>(
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
  );
  const [viewportHeight, setViewportHeight] = useState<number>(
    window.innerHeight,
  );

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(
        Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      );
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const deviceSize = getDeviceSize(viewportWidth);

  return (
    <MediaContext.Provider
      value={{
        deviceSize,
        isMobile: deviceSize === 'xs' || deviceSize === 'sm',
        isTablet: deviceSize === 'lg' || deviceSize === 'md',
        isDesktop: deviceSize === 'xl' || deviceSize === 'xxl',
        viewportWidth,
        viewportHeight,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export default Media;
