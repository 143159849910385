import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { Result, Table, Button } from 'antd';

import { Projects } from '../../../../../types/Projects';
import { PROJECTS_QUERY } from '../../../../../queries/project';
import UpdateProjectLength from './UpdateProjectLength';
import TableLoader from '../../../../../components/TableLoader';

const ProjectLenghtsTable = () => {
  const { loading, data, error } = useQuery<Projects>(PROJECTS_QUERY);

  const content = useMemo(() => {
    if (loading) {
      return <TableLoader columnCount={2} rowCount={4} />;
    }

    if (error) {
      return <Result status="warning" subTitle={error.message} />;
    }

    if (data) {
      return (
        <div className="space-y-4">
          <Table
            rowKey="id"
            dataSource={data.projects}
            className="overflow-hidden border rounded-md"
            columns={[
              {
                dataIndex: 'name',
                title: <span className="table-title">Project Name</span>,
                render: (text) => (
                  <span className="text-xs font-medium text-heading-color">
                    {text}
                  </span>
                ),
              },
              {
                dataIndex: 'projectLength',
                title: <span className="table-title">Project Length</span>,
                render: (text, record) => (
                  <div className="flex items-center justify-between space-x-4">
                    <span className="text-xs">
                      {text ? `${text} RKm` : 'N/A'}
                    </span>
                    <UpdateProjectLength
                      trigger={<Button icon="edit" />}
                      project={record}
                    />
                  </div>
                ),
              },
            ]}
            scroll={{ y: 300 }}
            pagination={false}
          />
          <div className="flex text-sm font-medium text-heading-color">
            <div className="w-1/2 pr-4 text-right">Total Length</div>
            <div className="w-1/2 pl-4">
              {data.projects.reduce(
                (acc, project) => (project.projectLength || 0) + acc,
                0,
              )}{' '}
              RKm
            </div>
          </div>
        </div>
      );
    }

    return null;
  }, [data, error, loading]);

  return (
    <div className="p-4 bg-white rounded shadow">
      <div className="mb-4 font-medium text-heading-color">Projects Length</div>
      {content}
    </div>
  );
};

export default ProjectLenghtsTable;
