import { createContext } from 'react';

export type DeviceSize = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';

const MediaContext = createContext<{
  deviceSize: DeviceSize;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  viewportWidth: number;
  viewportHeight: number;
}>({
  deviceSize: 'xl',
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  viewportWidth: 0,
  viewportHeight: 0,
});

export default MediaContext;
