import {
  PhysicalProgress,
  Analytics,
  Category,
  Location,
  GanttChart,
  Gallery,
  Leaderboard,
  FinancialProgress,
  IssueManagement,
  Drawing,
  Drive,
  Dashboard,
  Projects,
  MaterialManagement,
  RFI,
  Checklist,
  Hierarchy,
  Report,
  Testing,
  Concrete,
  Steel,
  Purchase,
} from '../icons';

export interface AppRoute {
  id: string;
  name: string;
  icon: {
    type?: string;
    component?: React.ComponentType<any>;
  };
  patterns: Array<string>;
  defaultRoute: string | ((projectId: string) => string);
  hideOnMobile?: boolean;
  moduleName?: string;
  children?: Array<AppRoute>;
}

export const ORGANISATION_ROUTES: Array<AppRoute> = [
  {
    id: 'projects',
    icon: {
      component: Projects,
    },
    name: 'All Projects',
    defaultRoute: '',
    patterns: ['/'],
  },
  {
    id: 'project-tree',
    icon: {
      component: Hierarchy,
    },
    name: 'Project Tree',
    defaultRoute: '/project-tree',
    patterns: ['/project-tree', '/project-tree/heirarchy/:groupId'],
    moduleName: 'MASTER_DASHBOARD',
  },
  {
    id: 'project-locations',
    icon: {
      component: Location,
    },
    name: 'Project Locations',
    defaultRoute: '/project-locations',
    patterns: ['/project-locations'],
    moduleName: 'MASTER_DASHBOARD',
  },
  {
    id: 'users',
    icon: {
      type: 'usergroup-add',
    },
    name: 'Manage Users',
    defaultRoute: '/users',
    patterns: ['/users'],
    moduleName: 'USER_MANAGEMENT',
  },
];

export const PROJECT_ROUTES: Array<AppRoute> = [
  {
    id: 'project-dashboard',
    icon: {
      component: Dashboard,
    },
    name: 'Dashboard',
    defaultRoute: (projectId: string) => `/projects/${projectId}/dashboard`,
    patterns: [
      '/projects/:projectId/dashboard',
      '/projects/:projectId/dashboard/overview',
      '/projects/:projectId/dashboard/mr-dashboard',
    ],
  },
  {
    id: 'physical-progress',
    name: 'Physical Progress',
    icon: {
      component: PhysicalProgress,
    },
    patterns: ['/projects/:projectId/physical-progress'],
    defaultRoute: (projectId: string) =>
      `/projects/${projectId}/physical-progress`,
    moduleName: 'PHYSICAL_PROGRESS',
    children: [
      {
        id: 'physical-progress-analytics',
        name: 'Analytics',
        icon: {
          component: Analytics,
        },
        patterns: ['/projects/:projectId/physical-progress'],
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/physical-progress`,
      },
      {
        id: 'physical-progress-categories',
        name: 'Project Setup',
        icon: {
          component: Category,
        },
        patterns: [
          '/projects/:projectId/physical-progress/categories',
          '/projects/:projectId/physical-progress/categories/:categoryId',
          '/projects/:projectId/physical-progress/project-elements/:projectElementId',
        ],
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/physical-progress/categories`,
      },
      {
        id: 'physical-progress-live-bar-chart',
        name: 'Live Bar Chart',
        icon: {
          component: GanttChart,
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/physical-progress/bar-chart`,
        patterns: ['/projects/:projectId/physical-progress/bar-chart'],
      },
      {
        id: 'physical-progress-geotagged-updates',
        name: 'Geotagged Updates',
        icon: {
          component: Location,
        },
        patterns: ['/projects/:projectId/physical-progress/geotagged-updates'],
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/physical-progress/geotagged-updates`,
      },
      {
        id: 'physical-progress-gallery',
        name: 'Gallery',
        icon: {
          component: Gallery,
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/physical-progress/gallery/latest`,
        patterns: [
          '/projects/:projectId/physical-progress/gallery/latest',
          '/projects/:projectId/physical-progress/gallery/list',
          '/projects/:projectId/physical-progress/gallery/:parentType/:parentId',
        ],
      },
      {
        id: 'physical-progress-supervisors-activities',
        name: "Supervisors' Activities",
        icon: {
          component: Leaderboard,
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/physical-progress/supervisors-activities`,
        patterns: [
          '/projects/:projectId/physical-progress/supervisors-activities',
        ],
      },
    ],
  },
  {
    id: 'financial-progress',
    name: 'Financial Progress',
    icon: {
      component: FinancialProgress,
    },
    defaultRoute: (projectId: string) =>
      `/projects/${projectId}/financial-progress`,
    patterns: [
      '/projects/:projectId/financial-progress',
      '/projects/:projectId/financial-progress/bills/:billId',
      '/projects/:projectId/financial-progress/setup',
    ],
    moduleName: 'FINANCIAL_PROGRESS',
  },
  {
    id: 'issues',
    name: 'Issues',
    icon: {
      component: IssueManagement,
    },
    defaultRoute: (projectId: string) => `/projects/${projectId}/issues`,
    patterns: [
      '/projects/:projectId/issues',
      '/projects/:projectId/issues/:issueId',
    ],
    moduleName: 'ISSUE_MANAGEMENT',
  },
  {
    id: 'drawings',
    name: 'Drawings',
    icon: {
      component: Drawing,
    },
    defaultRoute: (projectId: string) => `/projects/${projectId}/drawings`,
    patterns: [
      '/projects/:projectId/drawings',
      '/projects/:projectId/drawings/:drawingId',
    ],
    moduleName: 'DRAWING_MANAGEMENT',
  },
  {
    id: 'material-management',
    name: 'Material Management',
    icon: {
      component: MaterialManagement,
    },
    defaultRoute: (projectId: string) =>
      `/projects/${projectId}/material-management`,
    patterns: ['/projects/:projectId/material-management/:parentId?'],
  },
  {
    id: 'bocs-drive',
    name: 'BOCS Drive',
    icon: {
      component: Drive,
    },
    defaultRoute: (projectId: string) =>
      `/projects/${projectId}/drive/my-drive`,
    patterns: [
      '/projects/:projectId/drive/my-drive/:parentId?',
      '/projects/:projectId/drive/trash',
    ],
    moduleName: 'DRIVE_MANAGEMENT',
    children: [
      {
        id: 'bocs-drive-my-drive',
        name: 'My Drive',
        icon: {
          component: Drive,
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/drive/my-drive`,
        patterns: ['/projects/:projectId/drive/my-drive'],
      },
      {
        id: 'bocs-drive-trash',
        name: 'Trash',
        icon: {
          type: 'delete',
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/drive/trash`,
        patterns: ['/projects/:projectId/drive/trash'],
      },
    ],
  },
  {
    id: 'reports',
    name: 'Reports',
    icon: {
      component: Report,
    },
    defaultRoute: (projectId: string) =>
      `/projects/${projectId}/reports/instant-report`,
    patterns: ['/projects/:projectId/reports/instant-report'],
    moduleName: 'REPORTS',
  },
  {
    id: 'rfi',
    name: 'RFI',
    icon: {
      component: RFI,
    },
    // TODO: Change default route to RFI page
    defaultRoute: (projectId: string) => `/projects/${projectId}/rfi/rfis`,
    patterns: ['/projects/:projectId/rfi/checklists'],
    children: [
      {
        id: 'rfi-rfis',
        name: 'RFIs',
        icon: {
          component: RFI,
        },
        defaultRoute: (projectId: string) => `/projects/${projectId}/rfi/rfis`,
        patterns: [
          '/projects/:projectId/rfi/rfis',
          '/projects/:projectId/rfi/rfis/:rfiId',
        ],
      },
      {
        id: 'rfi-checklist',
        name: 'Checklists',
        icon: {
          component: Checklist,
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/rfi/checklists`,
        patterns: ['/projects/:projectId/rfi/checklists'],
      },
    ],
  },
  {
    id: 'NCR',
    name: 'NCR',
    icon: { type: 'file-search' },
    defaultRoute: (projectId: string) => `/projects/${projectId}/ncr/ncrs`,
    patterns: [
      '/projects/:projectId/ncr/ncrs/:ncrId?',
      '/projects/:projectId/ncr/checklists/:folderId?',
    ],
    children: [
      {
        id: 'NCRs',
        name: 'NCRs',
        icon: {
          type: 'file-search',
        },
        defaultRoute: (projectId: string) => `/projects/${projectId}/ncr/ncrs`,
        patterns: ['/projects/:projectId/ncr/ncrs/:ncrId?'],
      },
      {
        id: 'Checklists',
        name: 'Checklists',
        icon: {
          component: Checklist,
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/ncr/checklists`,
        patterns: ['/projects/:projectId/ncr/checklists/:folderId?'],
      },
    ],
  },
  {
    id: 'Material Testing',
    name: 'Material Testing',
    icon: {
      component: Testing,
    },
    defaultRoute: (projectId: string) =>
      `/projects/${projectId}/material-testing/concrete`,
    patterns: [
      '/projects/:projectId/material-testing/concrete',
      '/projects/:projectId/material-testing/steel',
    ],
    children: [
      {
        id: 'Concrete Cube Result',
        name: 'Concrete Cube Result',
        icon: {
          component: Concrete,
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/material-testing/concrete`,
        patterns: ['/projects/:projectId/material-testing/concrete'],
      },
      {
        id: 'Steel Received',
        name: 'Steel Received',
        icon: {
          component: Steel,
        },
        defaultRoute: (projectId: string) =>
          `/projects/${projectId}/material-testing/steel`,
        patterns: ['/projects/:projectId/material-testing/steel'],
      },
    ],
  },
  {
    id: 'Purchase Projections',
    name: 'Purchase Projections',
    icon: {
      component: Purchase,
    },
    defaultRoute: (projectId: string) =>
      `/projects/${projectId}/purchase-projections`,
    patterns: ['/projects/:projectId/purchase-projections'],
  },
];
