import React from 'react';
import { range } from 'lodash-es';

interface Props {
  title?: boolean;
  titleClassName?: string;
  titleStyle?: React.CSSProperties;
  paragraph?:
    | {
        rows: number;
      }
    | boolean;
  avatar?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const Skeleton: React.FC<Props> = ({
  title,
  titleClassName,
  titleStyle,
  paragraph,
  avatar,
  className,
  style,
}) => {
  const paragraphsCount = paragraph
    ? typeof paragraph === 'boolean'
      ? 3
      : paragraph.rows
    : 0;
  return (
    <div className={className} style={style}>
      <div className="flex items-center">
        {avatar ? (
          <div className="skeleton w-8 h-8 my-3 rounded-full mr-2" />
        ) : null}
        <div className="flex-1">
          <div>
            {title ? (
              <div
                className={`skeleton my-3 w-7/12 ${titleClassName || ''}`}
                style={titleStyle}
              />
            ) : null}
          </div>
          {paragraph
            ? range(paragraphsCount).map(val => (
                <div
                  key={val}
                  className={`skeleton my-3 ${
                    val === paragraphsCount - 1 ? 'w-4/5' : 'w-full'
                  }`}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

Skeleton.defaultProps = {
  title: true,
  paragraph: {
    rows: 3,
  },
};

export default Skeleton;
