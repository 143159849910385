import React, { useState, useEffect } from 'react';

import deltaDuration from '../../utils/deltaDuration';

interface Props {
  timestamp: number;
  children: (durationFromNow: string) => JSX.Element;
  updateInterval?: number;
}

const DurationFromNow: React.FC<Props> = ({
  children,
  timestamp,
  updateInterval,
}) => {
  const [durationFromNow, setDurationFromNow] = useState<string>('');

  useEffect(() => {
    const updateDuration = () => {
      setDurationFromNow(deltaDuration(timestamp));
    };

    updateDuration();
    // update the duration after each minute
    const intervalId = setInterval(updateDuration, updateInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [timestamp, updateInterval]);

  return children(durationFromNow);
};

DurationFromNow.defaultProps = {
  updateInterval: 1000 * 60,
};

export default DurationFromNow;
