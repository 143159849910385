import React from 'react';

const Project: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3125 5H11.4609L10.875 3.17422C10.6523 2.47344 10.0031 2 9.26719 2H1.6875C0.754687 2 0 2.75469 0 3.6875V19.8125C0 20.7453 0.754687 21.5 1.6875 21.5H22.3125C23.2453 21.5 24 20.7453 24 19.8125V6.6875C24 5.75469 23.2453 5 22.3125 5ZM1.6875 3.6875H9.26719L9.68672 5H1.6875V3.6875ZM1.6875 19.8125H22.3125V6.6875H1.6875V19.8125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Project;
