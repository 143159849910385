import React, { useCallback } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Form, Result, Checkbox, Button, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { capitalize } from 'lodash-es';
import { PermissionsInfo } from '../../../../../types/PermissionsInfo';
import { PERMISSIONS_INFO_QUERY } from '../queries';
import Loader from '../../../../../components/Loader';
import {
  UpdateUserPermissions,
  UpdateUserPermissionsVariables,
} from '../../../../../types/UpdateUserPermissions';
import {
  UPDATE_USER_PERMISSIONS_MUTATION,
  USER_DETAIL_INFO_FRAGEMENT,
} from '../../../../../queries/user';
import { UserDetailInfoFragment } from '../../../../../types/UserDetailInfoFragment';

interface Props {
  user: UserDetailInfoFragment;
  onUpdate?: () => void;
  form: WrappedFormUtils;
}

const EditUserPermission: React.FC<Props> = ({
  user,
  onUpdate,
  form: { getFieldDecorator, validateFields },
}) => {
  const { loading, error, data } = useQuery<PermissionsInfo>(
    PERMISSIONS_INFO_QUERY,
  );

  const [
    updateUserPermissionsMutation,
    { loading: updatingUserPermissions },
  ] = useMutation<UpdateUserPermissions, UpdateUserPermissionsVariables>(
    UPDATE_USER_PERMISSIONS_MUTATION,
    {
      update: (cache, { data: updateUserPermissionData }) => {
        if (updateUserPermissionData && user) {
          cache.writeFragment<UserDetailInfoFragment>({
            fragment: USER_DETAIL_INFO_FRAGEMENT,
            fragmentName: 'UserDetailInfoFragment',
            id: user.id,
            data: updateUserPermissionData.updateAccount,
          });
        }
      },
      onError: (updateUserPermissionsError) => {
        message.error(updateUserPermissionsError.message);
      },
      onCompleted: () => {
        message.success('User permissions updated successfully');
        if (onUpdate) {
          onUpdate();
        }
      },
    },
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields((fieldErrors, values) => {
        if (!fieldErrors && user) {
          const permissions = Object.keys(values)
            .filter((module) => values[module])
            .map((module) => ({
              module,
              scope: values[module] as Array<string>,
            }));
          updateUserPermissionsMutation({
            variables: { permissions, userId: user.id },
          });
        }
      });
    },
    [updateUserPermissionsMutation, user, validateFields],
  );

  if (loading) {
    return <Loader text="Loading Permissions" />;
  }

  if (error) {
    return <Result status="warning" subTitle={error.message} />;
  }

  if (data) {
    return (
      <Form onSubmit={handleSubmit}>
        {data.permissions.modules.map((module) => {
          const userPermissionsForModule = user.permissions
            ? user.permissions.find(
                (permission) => permission.module === module,
              )?.scope || []
            : [];
          return (
            <Form.Item
              key={module}
              label={
                <span className="font-medium text-heading-color">
                  {module
                    .split('_')
                    .map((name) => capitalize(name))
                    .join(' ')}
                </span>
              }
            >
              {getFieldDecorator(module, {
                initialValue: userPermissionsForModule,
              })(
                <Checkbox.Group
                  options={data.permissions.scope.map((scope) => ({
                    value: scope,
                    label: capitalize(scope),
                  }))}
                />,
              )}
            </Form.Item>
          );
        })}
        <Button
          type="primary"
          htmlType="submit"
          loading={updatingUserPermissions}
          className="mr-4"
        >
          Update Permissions
        </Button>
        <Button htmlType="button" onClick={onUpdate}>
          Cancel
        </Button>
      </Form>
    );
  }

  return null;
};

export default Form.create<Props>({ name: 'editUserPermission' })(
  EditUserPermission,
);
