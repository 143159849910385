import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const NoAccess = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Result
        status="403"
        subTitle="You do not access to this module. Please contact your project administrator"
      />
      <Link to="/">
        <Button type="link" icon="arrow-left">
          Back to Home
        </Button>
      </Link>
    </div>
  );
};

export default NoAccess;
