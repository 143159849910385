const SHADOW_COLOR = 'rgba(0, 0, 0, 0.05)';

export const shadows = {
  shadow1Up: `0 -2px 8px ${SHADOW_COLOR}`,
  shadow1Down: `0 2px 8px ${SHADOW_COLOR}`,
  shadow1Left: `-2px 0 8px ${SHADOW_COLOR}`,
  shadow1Right: `2px 0 8px ${SHADOW_COLOR}`,
  shadow2: `0 4px 12px ${SHADOW_COLOR}`,
};

export const EXPANDED_DRAWER_WIDTH = 256;

export const CONTRACTED_DRAWER_WIDTH = 64;

export const NAV_BAR_HEIGHT = 48;
