import React from 'react';
import { useQuery } from 'react-apollo';
import { Icon, Badge } from 'antd';

import {
  Notifications as NotificationsInterface,
  NotificationsVariables,
} from '../../../types/Notifications';
import { NOTIFICATIONS_QUERY } from '../../../queries/notification';

const Notifications = () => {
  const { data } = useQuery<NotificationsInterface, NotificationsVariables>(
    NOTIFICATIONS_QUERY,
    {
      variables: {
        page: 1,
      },
    },
  );

  return (
    <Badge count={data ? data.notifications.stats.unread : undefined}>
      <Icon type="bell" />
    </Badge>
  );
};

export default Notifications;
