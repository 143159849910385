// TODO: Merge all the theming files to one
const theme = {
  lightPrimaryColor: '#DBEEFF',
  primaryColor: '#1890ff',
  darkPrimaryColor: '#0077EF',

  darkBackgroundColor: '#202020',
  lightBackgroundColor: '#ffffff',
  triggerBackground: '#343434',
  bodyBackgroundColor: '#FAFAFA',
  menuDarkSubmenuBackground: '#121212',
  borderRadius: '4px',

  errorColor: '#F56565',

  lighterSuccessColor: '#C6F6D5',
  lightSuccessColor: '#68D391',
  successColor: '#48BB78',
  darkSuccessColor: '#38A169',
  darkerSuccessColor: '#2F855A',

  warningColor: '#F6E05E',

  normalColor: '#E4E4E4',

  // TODO: Please get a good name
  alertColor: '#F6AD55',
  headingColor: 'rgba(0, 0, 0, 0.80)',
  textColor: 'rgba(0, 0, 0, 0.60)',
  captionColor: 'rgba(0, 0, 0, 0.30)',
  inverseTextColor: '#fff',
  borderBaseColor: '#D9D9D9',
  borderSplitColor: '#E8E8E8',
};

export default theme;

export type Theme = typeof theme;

export type ThemeColor =
  | 'primaryColor'
  | 'successColor'
  | 'warningColor'
  | 'errorColor'
  | 'primaryColor'
  | 'normalColor'
  | 'alertColor';
