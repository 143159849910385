import gql from 'graphql-tag';
import { USER_BASIC_INFO_FRAGMENT } from './user';
import { PROJECT_BASIC_INFO_FRAGMENT } from './project';

export const MR_ITEM_INFO_FRAGMENT = gql`
  fragment MRItemInfoFragment on MRItem {
    cpd {
      ...UserBasicInfoFragment
    }
    documents
    entryDate
    id
    type
    progress
    project {
      id
      name
      ...ProjectBasicInfoFragment
    }
  }
  ${USER_BASIC_INFO_FRAGMENT}
  ${PROJECT_BASIC_INFO_FRAGMENT}
`;

export const MR_ITEMS_QUERY = gql`
  query MRItems($projectId: String, $cpdId: String) {
    mrItems(project: $projectId, cpd: $cpdId) {
      ...MRItemInfoFragment
    }
  }
  ${MR_ITEM_INFO_FRAGMENT}
`;

export const CREATE_MR_ITEM_MUTATION = gql`
  mutation CreateMRItem(
    $type: String!
    $progress: Float!
    $entryDate: Float!
    $documents: [String!]
    $projectId: String!
  ) {
    createMRItem(
      createMRItemData: {
        type: $type
        progress: $progress
        project: $projectId
        entryDate: $entryDate
        documents: $documents
      }
    ) {
      ...MRItemInfoFragment
    }
  }
  ${MR_ITEM_INFO_FRAGMENT}
`;

export const UPDATE_MR_ITEM_MUTATION = gql`
  mutation UpdateMRItemMutation(
    $mrItemId: String!
    $progress: Float!
    $entryDate: Float!
    $documents: [String!]
  ) {
    updateMRItem(
      updateMRItemData: {
        progress: $progress
        entryDate: $entryDate
        documents: $documents
      }
      id: $mrItemId
    ) {
      ...MRItemInfoFragment
    }
  }
  ${MR_ITEM_INFO_FRAGMENT}
`;

export const DELETE_MR_ITEM_MUTATION = gql`
  mutation DeleteMRItem($mrItemId: String!) {
    deleteMRItem(id: $mrItemId) {
      ...MRItemInfoFragment
    }
  }
  ${MR_ITEM_INFO_FRAGMENT}
`;
