import React, { useState, useCallback } from 'react';
import { Modal, Input, Button } from 'antd';

interface Props {
  trigger: JSX.Element;
  loading: boolean;
  createNewHierarchy: (name: string) => void;
}

const CreateHierarchy: React.FC<Props> = ({
  trigger,
  loading,
  createNewHierarchy,
}) => {
  const [name, setName] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);

  const handleSumbit = useCallback(async () => {
    await createNewHierarchy(name);

    setName('');
    setShow(false);
  }, [name, createNewHierarchy]);

  const handleCancel = useCallback(() => {
    setShow(false);

    setName('');
  }, []);

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => setShow(true),
      })}

      {show ? (
        <Modal
          title="Create Hierarchy"
          visible={show}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              loading={loading}
              key="submit"
              type="primary"
              disabled={name === ''}
              onClick={handleSumbit}
            >
              Submit
            </Button>,
          ]}
        >
          <Input
            value={name}
            placeholder="Hierarchy name"
            onChange={(event) => {
              setName(event.target.value);
            }}
            autoFocus
          />
        </Modal>
      ) : null}
    </>
  );
};

export default CreateHierarchy;
