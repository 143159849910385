const getThumbnail = ({
  url,
  mimeType,
}: {
  url: string;
  mimeType: string;
}): { url: string; imageStyle: 'cover' | 'contain' } => {
  if (
    !url.startsWith('https://res.cloudinary') &&
    !url.startsWith('http://res.cloudinary')
  ) {
    return { url, imageStyle: 'cover' };
  }

  if (mimeType.startsWith('image') || mimeType === 'application/pdf') {
    const fileType = url.split('.').slice(-1)[0];
    const publicId = url
      .replace(/http|s/, '')
      .replace(`.${fileType}`, '')
      .replace(
        `://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/`,
        '',
      )
      .split('/')
      .slice(1)
      .join('/');
    return {
      url: `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_thumb,g_face,w_400/v1571567353/${publicId}.png`,
      imageStyle: 'cover',
    };
  }

  if (
    mimeType === 'application/vnd.ms-powerpoint' ||
    mimeType ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ) {
    return {
      url: require('../images/powerpoint.svg'),
      imageStyle: 'contain',
    };
  }

  if (
    mimeType === 'application/vnd.ms-excel' ||
    mimeType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return {
      url: require('../images/excel.svg'),
      imageStyle: 'contain',
    };
  }

  if (
    mimeType === 'application/msword' ||
    mimeType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return {
      url: require('../images/word.svg'),
      imageStyle: 'contain',
    };
  }

  if (mimeType === 'image/vnd.dwg') {
    return {
      url: require('../images/dwg.svg'),
      imageStyle: 'contain',
    };
  }

  return { url: '', imageStyle: 'contain' };
};

export default getThumbnail;
