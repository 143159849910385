import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ModuleHeader from '../../../../components/ModuleHeader';
import CreateInstantReportForm from './components/CreateInstantReportForm';
import { Report } from '../../../../components/icons';

interface Props extends RouteComponentProps {}

const InstantReport: React.FC<Props> = ({ match: { params } }) => {
  const { projectId } = params as { projectId: string };

  return (
    <div className="w-full max-w-5xl p-4 mx-auto">
      <ModuleHeader
        className="mb-6"
        projectId={projectId}
        name="BOCS - Instant Report"
        icon={{
          component: Report,
        }}
      />
      <CreateInstantReportForm projectId={projectId} />
    </div>
  );
};

export default InstantReport;
