import React from 'react';

const WorkingItem = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2C14 3.10457 13.1046 4 12 4C10.8954 4 10 3.10457 10 2C10 0.89543 10.8954 0 12 0C13.1046 0 14 0.89543 14 2Z"
        fill="currentColor"
      />
      <path
        d="M24 22C24 23.1046 23.1046 24 22 24C20.8954 24 20 23.1046 20 22C20 20.8954 20.8954 20 22 20C23.1046 20 24 20.8954 24 22Z"
        fill="currentColor"
      />
      <path
        d="M4 22C4 23.1046 3.10457 24 2 24C0.89543 24 0 23.1046 0 22C0 20.8954 0.89543 20 2 20C3.10457 20 4 20.8954 4 22Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.881958L22.809 22.5H1.19098L12 0.881958ZM2.80901 21.5H21.191L12 3.11803L2.80901 21.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WorkingItem;
