import React, { useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AppShellContext from '../../contexts/AppShellContext';
import Map from './components/Map';

interface Props extends RouteComponentProps {}

const ProjectLocations: React.FC<Props> = () => {
  const { setDrawerExpanded } = useContext(AppShellContext);

  useEffect(() => {
    setDrawerExpanded(false);
    return () => {
      setDrawerExpanded(true);
    };
  }, [setDrawerExpanded]);

  return <Map />;
};

export default ProjectLocations;
