import gql from 'graphql-tag';

export const ORGANISATION_INFO_FRAGMENT = gql`
  fragment OrganisationInfoFragment on Organisation {
    id
    name
  }
`;

export const ORGANISATION_QUERY = gql`
  query Organisation($organisationId: String!) {
    organisation(id: $organisationId) {
      ...OrganisationInfoFragment
    }
  }
  ${ORGANISATION_INFO_FRAGMENT}
`;
