import React from 'react';
import { Result } from 'antd';

const WorkInProgress = () => {
  return (
    <Result
      status="404"
      title="Work In Progress"
      subTitle="Visit later for updated page"
    />
  );
};

export default WorkInProgress;
