import React from 'react';
import color from 'color';
import { Icon, Tooltip } from 'antd';
import { round } from 'lodash-es';
import clsx from 'clsx';

import useTheme from '../../hooks/useTheme';

interface Props {
  progress: number;
  expectedProgress?: number;
  lastProgress?: number;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
  status?: Array<string>;
  showStats?: boolean;
  additionalStats?: JSX.Element;
}

const Progress: React.FC<Props> = ({
  progress,
  expectedProgress,
  lastProgress,
  className,
  style,
  size,
  status,
  showStats,
  additionalStats,
}) => {
  const theme = useTheme();

  const barColor = status
    ? status.includes('bursted')
      ? theme.errorColor
      : status.includes('completed')
      ? theme.successColor
      : status.includes('delayed')
      ? theme.warningColor
      : status.includes('notYetStarted')
      ? theme.alertColor
      : theme.primaryColor
    : theme.primaryColor;

  const leadPercentage = progress - (expectedProgress || 0);

  return (
    <div className={clsx('w-full', className)} style={style}>
      <div
        className="relative flex items-center w-full bg-body-background-color"
        style={{ height: size || 4 }}
      >
        <div
          className="relative h-full"
          style={{
            backgroundColor: color(barColor).alpha(0.85).toString(),
            width: `${progress}%`,
          }}
        >
          {lastProgress ? (
            <div
              className="absolute top-0 bottom-0 right-0"
              style={{
                backgroundColor: barColor,
                width: `${lastProgress}%`,
              }}
            />
          ) : null}
        </div>
        {expectedProgress !== undefined ? (
          <Tooltip title={`Expected Progress - ${expectedProgress}%`}>
            <div
              style={{
                left: `${expectedProgress}%`,
                top: -2,
                bottom: -2,
              }}
              className="absolute p-2 -m-2 cursor-pointer"
            >
              <div
                style={{
                  width: 2,
                  backgroundColor: barColor,
                }}
                className="h-full"
              />
            </div>
          </Tooltip>
        ) : null}
      </div>
      {showStats && (
        <div className="flex items-center mt-2">
          <Icon
            type={leadPercentage >= 0 ? 'caret-up' : 'caret-down'}
            style={{
              color:
                leadPercentage >= 0 ? theme.primaryColor : theme.warningColor,
            }}
            className="mr-2"
          />
          <div className="font-medium uppercase text-caption">
            {leadPercentage >= 0 ? 'Lead' : 'Lag'}{' '}
            {Math.abs(round(leadPercentage))}%
          </div>
          <div className="flex-1" />
          {additionalStats}
        </div>
      )}
    </div>
  );
};

Progress.defaultProps = {
  status: [],
  size: 4,
};

export default Progress;
