import React, { useState, useCallback } from 'react';
import { Button, Modal, Icon } from 'antd';
import { useQuery } from 'react-apollo';

import usePermission from '../../../hooks/usePermission';
import EditGroupForm from './EditGroupForm';
import AssociateProjectForm from './AssociateProjectForm';
import GroupDetail from './GroupDetail';
import { GROUP_QUERY } from '../queries';
import { Group, GroupVariables } from '../../../types/Group';

type NodeType = {
  id: string;
  name: string;
  parentId: string | null;
};

interface Props {
  group: NodeType;
  updateName: (name: string) => void;
  deleteNode: () => void;
}

const ActiveProjectGroup: React.FC<Props> = ({
  group : nodeGroup,
  updateName,
  deleteNode,
}) => {
  const { canEditProject, canDeleteProject } = usePermission();

  const [mode, setMode] = useState<'view' | 'edit' | 'associateProjects'>(
    'view',
  );

  const { data } = useQuery<Group, GroupVariables>(
    GROUP_QUERY,
    {
      variables: { groupId: nodeGroup.id },
    },
  );
  
  const handleAssignProjectsClick = useCallback(() => {
    setMode('associateProjects');
  }, []);
  
  const handleEditGroupClick = useCallback(() => {
    setMode('edit');
  }, []);
  
  const handleDeleteGroupClick = useCallback(() => {
    Modal.confirm({
      title: 'Delete Group',
      content: 'Group once deleted cannot be recovered. Do you want to continue?',
      okText: 'Yes',
      cancelText: 'No',
      icon: <Icon type="delete" className="fill-current text-error-color" />,
      onOk: deleteNode,
    });
  }, [deleteNode]);
  
  const setViewMode = useCallback(() => {
    setMode('view');
  }, []);
  
  if (nodeGroup) {
    if(data){
      const cachedGroup = data.group;
    
      if (mode === 'edit') {
        return (
          <EditGroupForm
            group={nodeGroup}
            onEdit={(name) => {
              updateName(name);
              setViewMode();
            }}
            onCancel={setViewMode}
          />
        );
      }
  
      if (mode === 'associateProjects') {
        return (
          <AssociateProjectForm
            group={cachedGroup}
            onSubmit={setViewMode}
            onCancel={setViewMode}
          />
        );
      }
    }
    
    return (
      <div className="flex flex-col h-full">
        <div className="flex-1 mb-4 overflow-x-hidden overflow-y-auto">
          <GroupDetail groupId={nodeGroup.id} />
        </div>
        {canEditProject ? (
          <div className="flex items-center">
            {canEditProject ? (
              <Button
                type="link"
                icon="user-add"
                style={{ paddingRight: 0, paddingLeft: 0 }}
                onClick={handleAssignProjectsClick}
              >
                Associate Project
              </Button>
            ) : null}
            <div className="flex-1" />
            {canEditProject ? (
              <Button type="link" icon="edit" disabled={nodeGroup.parentId === null} onClick={handleEditGroupClick} />
            ) : null}
            {canDeleteProject ? (
                <Button type="link" icon="delete" disabled={nodeGroup.parentId === null} onClick={handleDeleteGroupClick} />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};

export default ActiveProjectGroup;
