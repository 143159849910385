import React from 'react';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import tailwindTheme from 'tailwindcss/defaultTheme';
import ProjectLenghtsTable from './components/ProjectsLengthTable';
import TypeStats from './components/TypeStats';
import WNSStats from './components/WNSStats';

const typeMap = {
  CRS: {
    name: 'CRS Inspection Done',
    fill: tailwindTheme.colors.green['500'],
  },
  LET: {
    name: 'Light Engine Trial Done',
    fill: tailwindTheme.colors.blue['500'],
  },
  WIP: {
    name: 'Work in Progress',
    fill: tailwindTheme.colors.yellow['500'],
  },
};

const typeOrder: Array<keyof typeof typeMap> = ['WIP', 'LET', 'CRS'];

const MRDashboard = () => {
  return (
    <div className="max-w-5xl p-4 mx-auto">
      <div className="grid grid-cols-2 gap-4">
        <ProjectLenghtsTable />
        <WNSStats />
        {typeOrder.map((type) => (
          <TypeStats
            key={type}
            type={type}
            typeColor={typeMap[type].fill}
            typeLabel={typeMap[type].name}
          />
        ))}
      </div>
    </div>
  );
};

export default MRDashboard;
