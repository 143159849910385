import moment from 'moment';

const steps: Array<{
  value: 'years' | 'months' | 'days' | 'hours' | 'minutes';
  label: string;
}> = [
  { value: 'years', label: 'year' },
  { value: 'months', label: 'month' },
  { value: 'days', label: 'day' },
  { value: 'hours', label: 'hour' },
  { value: 'minutes', label: 'min' },
];

const deltaDuration = (timestamp: number): string => {
  for (const step of steps) {
    const diff = moment().diff(timestamp, step.value);
    if (diff > 0) {
      return `${diff} ${diff === 1 ? step.label : `${step.label}s`} ago`;
    }
  }

  return 'few seconds ago';
};

export default deltaDuration;
