import React, { forwardRef } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  bordered?: boolean;
}

const Card: React.FC<Props> = forwardRef(
  ({ bordered, className, ...restProps }: Props, ref: React.Ref<any>) => {
    return (
      <div
        className={`bg-white xs:p-4 sm:p-4 md:p-6 rounded ${
          bordered ? 'shadow' : ''
        } ${className}`}
        ref={ref}
        {...restProps}
      />
    );
  },
);

Card.defaultProps = {
  bordered: true,
};

Card.displayName = 'Card';

export default Card;
