import React from 'react';

const Projects: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H4V4H0V0Z" fill="currentColor" />
      <path d="M8 0H12V4H8V0Z" fill="currentColor" />
      <path d="M16 0H20V4H16V0Z" fill="currentColor" />
      <path d="M0 8H4V12H0V8Z" fill="currentColor" />
      <path d="M8 8H12V12H8V8Z" fill="currentColor" />
      <path d="M16 8H20V12H16V8Z" fill="currentColor" />
      <path d="M0 16H4V20H0V16Z" fill="currentColor" />
      <path d="M8 16H12V20H8V16Z" fill="currentColor" />
      <path d="M16 16H20V20H16V16Z" fill="currentColor" />
    </svg>
  );
};

export default Projects;
