import { Theme, ThemeColor } from '../styles/theme';

export const statusCategories: Record<
  string,
  {
    label: string;
    value:
      | 'delayed'
      | 'bursted'
      | 'notYetStarted'
      | 'onTrack'
      | 'upcoming'
      | 'completed'
      | 'active';
    color: ThemeColor;
    description: (itemName?: string) => string;
    lighten?: number;
  }
> = {
  active: {
    label: 'Active',
    value: 'active',
    color: 'primaryColor',
    description: (itemName = 'Project Elements') => `${itemName} in progress`,
  },
  bursted: {
    label: 'Bursted',
    value: 'bursted',
    color: 'errorColor',
    description: (itemName = 'Project Elements') =>
      `${itemName} that have exceeded their TDC`,
  },
  delayed: {
    label: 'Delayed',
    value: 'delayed',
    color: 'warningColor',
    lighten: 0.3,
    description: (itemName = 'Project Elements') =>
      `${itemName} that are running late`,
  },
  notYetStarted: {
    label: 'Not Started',
    value: 'notYetStarted',
    color: 'alertColor',
    description: (itemName = 'Project Elements') =>
      `${itemName} that should have been started but not started`,
  },
  onTrack: {
    label: 'On Schedule',
    value: 'onTrack',
    color: 'primaryColor',
    description: (itemName = 'Project Elements') =>
      `${itemName} running on schedule`,
  },
  upcoming: {
    label: 'Upcoming',
    value: 'upcoming',
    color: 'normalColor',
    description: (itemName = 'Project Elements') =>
      `${itemName} that are not started`,
  },
  completed: {
    label: 'Completed',
    value: 'completed',
    color: 'successColor',
    description: (itemName = 'Project Elements') => `Completed ${itemName}`,
  },
};

export const statusCategoriesOrder: Array<keyof typeof statusCategories> = [
  'active',
  'bursted',
  'delayed',
  'notYetStarted',
  'onTrack',
  'upcoming',
  'completed',
];

export const getStatusColor = (
  status: Array<string> | null,
  theme: Theme,
): { backgroundColor: string; color: string } => {
  if (status) {
    if (status.includes('onTrack')) {
      return {
        backgroundColor: theme[statusCategories.onTrack.color],
        color: theme.inverseTextColor,
      };
    }

    if (status.includes('completed')) {
      return {
        backgroundColor: theme[statusCategories.completed.color],
        color: theme.inverseTextColor,
      };
    }

    if (status.includes('delayed')) {
      return {
        backgroundColor: theme[statusCategories.delayed.color],
        color: theme.textColor,
      };
    }

    if (status.includes('bursted')) {
      return {
        backgroundColor: theme[statusCategories.bursted.color],
        color: theme.inverseTextColor,
      };
    }

    if (status.includes('notYetStarted')) {
      return {
        backgroundColor: theme[statusCategories.notYetStarted.color],
        color: theme.inverseTextColor,
      };
    }

    if (status.includes('upcoming')) {
      return {
        backgroundColor: theme[statusCategories.upcoming.color],
        color: theme.textColor,
      };
    }
  }

  return {
    backgroundColor: theme.primaryColor,
    color: theme.inverseTextColor,
  };
};
