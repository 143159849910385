import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

interface Props extends React.ComponentProps<typeof Typography.Title> {}

const Heading: React.FC<Props> = (props) => {
  return <StyledTitle {...props} />;
};

export default Heading;

const StyledTitle = styled(Typography.Title)`
  &&& {
    line-height: ${({ level }) =>
      level === 2 ? 32 : level === 3 ? 24 : level === 4 ? 24 : undefined}px;
    font-weight: 500;
    letter-spacing: ${({ level }) => (level === 4 ? '-0.08px' : undefined)};
  }
`;
