import React, { useState, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { Icon } from 'antd';
import { useLocation, matchPath, Link } from 'react-router-dom';

import { AppRoute } from '../utils';
import useProjectId from '../../../hooks/useProjectId';
import useMedia from '../../../hooks/useMedia';
import AppShellContext from '../../../contexts/AppShellContext';

interface Props {
  drawerExpanded: boolean;
  menuOption: AppRoute;
  subMenu?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const MenuOption: React.FC<Props> = ({
  menuOption,
  subMenu,
  className,
  style,
  drawerExpanded,
}) => {
  const projectId = useProjectId();

  const { pathname } = useLocation();

  const { isMobile } = useMedia();

  const [childrenVisible, setChildrenVisible] = useState<boolean>(
    !!(menuOption.children && menuOption.children.length > 0),
  );

  const { setDrawerExpanded } = useContext(AppShellContext);

  const isMenuActive = menuOption.patterns.some((pattern) =>
    matchPath(pathname, {
      exact: true,
      strict: true,
      path: pattern,
    }),
  );

  const isChildActive = menuOption.children
    ? menuOption.children.some((child) =>
        child.patterns.some((pattern) =>
          matchPath(pathname, { exact: true, strict: true, path: pattern }),
        ),
      )
    : false;

  const toggleChildrenVisibility = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setChildrenVisible((visibilityState) => !visibilityState);
    },
    [],
  );

  const handleOptionClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setDrawerExpanded(true);
      if (
        menuOption.children &&
        menuOption.children.length > 0 &&
        (event.target as HTMLElement).tagName !== 'BUTTON'
      ) {
        setChildrenVisible(true);
      }
    },
    [menuOption.children, setDrawerExpanded],
  );

  if (menuOption.hideOnMobile && isMobile) {
    return null;
  }

  return (
    <div className={className} style={style}>
      <Link
        to={
          typeof menuOption.defaultRoute === 'string'
            ? menuOption.defaultRoute
            : projectId
            ? menuOption.defaultRoute(projectId)
            : '/'
        }
        className="block"
      >
        <div
          className={clsx(
            'flex items-center py-2 w-full overflow-hidden',
            subMenu ? 'pl-10' : 'px-4',
            isMenuActive && !isChildActive
              ? 'bg-light-primary-color text-primary-color'
              : undefined,
            isChildActive ? 'text-primary-color' : undefined,
          )}
          style={style}
          onMouseDown={handleOptionClick}
        >
          <div
            className={clsx(
              'mr-4 flex items-center justify-center',
              subMenu ? 'w-4 h-4' : 'w-5 h-5',
              !drawerExpanded && !subMenu ? 'ml-2 mr-2' : undefined,
            )}
          >
            <Icon
              type={menuOption.icon.type}
              component={menuOption.icon.component}
              className={clsx(
                'fill-current',
                subMenu ? 'text-base' : 'text-base',
              )}
            />
          </div>
          <div
            className={clsx(
              'overflow-hidden transition-ease-in transition-ease-in',
              drawerExpanded
                ? 'flex-1 opacity-100 pointer-events-auto'
                : 'flex-none opacity-0 pointer-events-none',
            )}
          >
            <span className="text-sm truncate">{menuOption.name}</span>
          </div>
          {menuOption.children && menuOption.children.length > 0 ? (
            <button
              className={clsx(
                'focus:outline-none py-1 px-2 -mr-2 transition-250 transition-ease-in',
                drawerExpanded
                  ? 'opacity-100 pointer-events-auto'
                  : 'opacity-0 pointer-events-none',
              )}
              type="button"
              onClick={toggleChildrenVisibility}
            >
              <Icon
                type="down"
                className="pointer-events-none text-caption transition-ease-in transition-250"
                style={{
                  transform: childrenVisible
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                }}
              />
            </button>
          ) : null}
        </div>
      </Link>
      <div
        className={clsx(
          'transition-250 transition-ease-in overflow-hidden',
          childrenVisible &&
            menuOption.children &&
            menuOption.children.length > 0 &&
            drawerExpanded
            ? 'max-h-screen'
            : 'max-h-0',
        )}
      >
        {(menuOption.children || []).map((childOption) => (
          <MenuOption
            menuOption={childOption}
            key={childOption.id}
            subMenu
            drawerExpanded={drawerExpanded}
          />
        ))}
      </div>
    </div>
  );
};

export default MenuOption;
