import React from 'react';

const Drive: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 17.866c0-.454.257-.87.663-1.073l1.737-.868v3.6h19.2v-3.6l1.74.875a1.2 1.2 0 01.66 1.072v2.852a1.2 1.2 0 01-1.2 1.2H1.2a1.2 1.2 0 01-1.2-1.2v-2.858z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.734 12.073V3.571a1.2 1.2 0 112.4 0v8.502l.952-.951a1.2 1.2 0 111.697 1.697l-3.849 3.849-3.951-3.952a1.2 1.2 0 111.697-1.697l1.054 1.054z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Drive;
