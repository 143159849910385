import React, { useState, memo, useCallback } from 'react';
import { Point } from '@turf/turf';
import { Marker } from 'react-map-gl';
import { Popover } from 'antd';
import { useQuery } from 'react-apollo';

import useMedia from '../../../hooks/useMedia';
import ProjectPopoverInfo from './ProjectPopoverInfo';
import { PROJECT_LOCATION_DRAWER_DETAIL_QUERY } from '../queries';
import {
  ProjectLocationDrawerDetail,
  ProjectLocationDrawerDetailVariables,
} from '../../../types/ProjectLocationDrawerDetail';

interface Props {
  projectId: string;
  onShowMoreInfo: (projectId: string) => void;
}

const ProjectNameMarker: React.FC<Props> = ({ projectId, onShowMoreInfo }) => {
  const { isDesktop } = useMedia();

  const [contentVisible, setContentVisible] = useState(false);

  const { data } = useQuery<
    ProjectLocationDrawerDetail,
    ProjectLocationDrawerDetailVariables
  >(PROJECT_LOCATION_DRAWER_DETAIL_QUERY, {
    variables: {
      projectId,
    },
  });

  const handleShowMoreInfo = useCallback(() => {
    onShowMoreInfo(projectId);
  }, [onShowMoreInfo, projectId]);

  if (!data) {
    return null;
  }

  const { location } = data.project;

  // in case logitude or latitude or both not available
  if (!(location && location.coordinates && location.coordinates.length >= 2)) {
    return null;
  }

  return (
    <Marker
      longitude={(location as Point).coordinates[0]}
      latitude={(location as Point).coordinates[1]}
    >
      <Popover
        title={data.project.name}
        content={
          contentVisible ? (
            <ProjectPopoverInfo
              project={data.project}
              onShowMoreInfo={handleShowMoreInfo}
            />
          ) : null
        }
        onVisibleChange={setContentVisible}
        trigger={isDesktop ? 'hover' : 'click'}
      >
        <span
          className="relative flex items-center text-white"
          style={{ transform: 'translate(-50%, -50%)' }}
        >
          <span className="text-white text-caption">{data.project.name}</span>
        </span>
      </Popover>
    </Marker>
  );
};

export default memo(ProjectNameMarker);
