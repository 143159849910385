import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useRect } from '@reach/rect';
import ReactMapGL, { Marker } from 'react-map-gl';
import { Icon } from 'antd';

enum Size {
  LARGE = 'LARGE',
  MAXIMIZED = 'MAXIMIZED',
  MINIMIZED = 'MINIMIZED',
}

interface Props {
  latitude: number;
  longitude: number;
  className?: string;
  style?: React.CSSProperties;
}

const MiniMap: React.FC<Props> = ({
  latitude,
  longitude,
  className,
  style,
}) => {
  const container = useRef<HTMLDivElement | null>(null);

  const containerRect = useRect(container);

  const [size, setSize] = useState<Size>(Size.MINIMIZED);

  const [viewState, setViewState] = useState({ latitude, longitude, zoom: 12 });

  return (
    <div className={clsx('rounded overflow-hidden', className)} style={style}>
      <div className="flex items-center w-full p-2 space-x-4 bg-dark-background-color">
        {size === Size.MINIMIZED ? (
          <button
            className="text-white focus:outline-none"
            type="button"
            onClick={() => {
              setSize(Size.MAXIMIZED);
            }}
          >
            <Icon type="environment" className="text-lg" />
          </button>
        ) : (
          <>
            <button
              className="text-white focus:outline-none"
              type="button"
              onClick={() => {
                setSize(Size.MINIMIZED);
              }}
            >
              <Icon type="minus" className="text-lg" />
            </button>
            <button
              className="text-white focus:outline-none"
              type="button"
              onClick={() => {
                setSize((prevState) =>
                  prevState === Size.LARGE ? Size.MAXIMIZED : Size.LARGE,
                );
              }}
            >
              <Icon
                type={size === Size.LARGE ? 'shrink' : 'arrows-alt'}
                className="text-lg"
              />
            </button>
          </>
        )}
      </div>
      <div
        ref={container}
        className={clsx(
          size === Size.LARGE
            ? 'w-80 h-80'
            : size === Size.MAXIMIZED
            ? 'w-40 h-40'
            : 'w-0 h-0',
        )}
      >
        {containerRect &&
        containerRect.width > 0 &&
        containerRect.height > 0 ? (
          <ReactMapGL
            viewState={viewState}
            onViewStateChange={({ viewState: updatedViewState }) => {
              setViewState({
                latitude: updatedViewState.latitude,
                longitude: updatedViewState.longitude,
                zoom: updatedViewState.zoom,
              });
            }}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN}
            mapStyle="mapbox://styles/mapbox/satellite-streets-v10"
            width={containerRect.width}
            height={containerRect.height}
          >
            <Marker latitude={latitude} longitude={longitude}>
              <img
                alt="Marker"
                src={require('../../images/marker.svg')}
                className="relative w-6"
                style={{
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </Marker>
          </ReactMapGL>
        ) : null}
      </div>
    </div>
  );
};

export default MiniMap;
