import React, { useRef, useState, useCallback, useEffect } from 'react';
import ReactMapGL, {
  ViewportProps,
  FlyToInterpolator,
  Marker,
} from 'react-map-gl';
import { useRect } from '@reach/rect';
import { Feature } from 'geojson';
import { useQuery } from 'react-apollo';
import { Drawer, Icon } from 'antd';
import { WebMercatorViewport } from 'viewport-mercator-project';
import { bbox, featureCollection, Point } from '@turf/turf';

import { ProjectLocationsDetail } from '../../../types/ProjectLocationsDetail';
import { PROJECT_LOCATIONS_DETAIL_QUERY } from '../queries';
import PlacesAutocomplete from '../../../components/PlacesAutocomplete';
import MapControlPanel from './MapControlPanel';
import BaseMaps from '../../../modules/PhysicalProgress/scenes/GeoTaggedUpdates/components/BaseMaps';
import ProjectNameMarker from './ProjectNameMarker';
import ActiveProject from './ActiveProject';

type MapType = 'light-v9' | 'dark-v9' | 'streets-v10' | 'satellite-streets-v10';

interface Props {}

const Map: React.FC<Props> = () => {
  const container = useRef<HTMLDivElement | null>(null);
  const containerRect = useRect(container);

  const [baseMap, setBaseMap] = useState<MapType>('satellite-streets-v10');

  const [viewport, setViewport] = useState<ViewportProps>({
    latitude: 28.7041,
    longitude: 77.1025,
    width: 0,
    height: 0,
    bearing: 0,
    altitude: 1.5,
    zoom: 8,
    pitch: 0,
    maxPitch: 0,
    minPitch: 0,
    maxZoom: 22,
    minZoom: 0,
  });
  const handleLocationChange = useCallback(
    ({
      latitude,
      longitude,
      zoom = 18,
    }: {
      latitude: number;
      longitude: number;
      zoom?: number;
    }) => {
      setViewport((viewportState) => {
        return {
          ...viewportState,
          latitude,
          longitude,
          zoom,
          transitionInterpolator: new FlyToInterpolator(),
        };
      });
    },
    [],
  );

  const { data } = useQuery<ProjectLocationsDetail>(
    PROJECT_LOCATIONS_DETAIL_QUERY,
  );

  useEffect(() => {
    if (containerRect && data && data.projects.length > 0) {
      const progressFeatureCollection = featureCollection(
        data.projects
          .filter(
            (project) =>
              project.location &&
              project.location.coordinates &&
              project.location.coordinates.length >= 2,
          ) // in case logitude or latitude or both not available
          .map(
            (project) =>
              ({
                geometry: {
                  coordinates:
                    project.location &&
                    (project.location.coordinates as Array<number>),
                  type: 'Point',
                },
                type: 'Feature',
              } as Feature<Point>),
          ),
      );

      if (progressFeatureCollection.features.length > 0) {
        const featureBbox = bbox(progressFeatureCollection);
        const { latitude, longitude, zoom } = new WebMercatorViewport({
          width: containerRect.width,
          height: containerRect.height,
        }).fitBounds([
          [featureBbox[0], featureBbox[1]],
          [featureBbox[2], featureBbox[3]],
        ]);

        setViewport((viewportState) => ({
          ...viewportState,
          latitude,
          longitude,
          zoom: progressFeatureCollection.features.length === 1 ? 8 : zoom - 1,
        }));
      }
    }
  }, [containerRect, data]);

  const [activeProjectId, setActiveProjectId] = useState<string | undefined>(
    undefined,
  );
  const handleShowMoreInfo = useCallback((projectId: string) => {
    setActiveProjectId(projectId);
  }, []);
  const handleMoreInfoDrawerClose = useCallback(() => {
    setActiveProjectId(undefined);
  }, []);

  return (
    <>
      <div className="relative w-full h-full" ref={container}>
        <div className="absolute top-0 left-0 right-0 z-40 flex items-center justify-center px-4 py-4">
          <PlacesAutocomplete
            onChange={handleLocationChange}
            className="w-80"
            inputProps={{
              suffix: <Icon type="search" />,
            }}
          />
        </div>
        {containerRect ? (
          <ReactMapGL
            {...viewport}
            width={containerRect.width}
            height={containerRect.height}
            onViewportChange={setViewport}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN}
            mapStyle={`mapbox://styles/mapbox/${baseMap}`}
          >
            {data
              ? data.projects
                  .filter(
                    (project) =>
                      project.location &&
                      project.location.coordinates &&
                      project.location.coordinates.length >= 2,
                  )
                  .map((project) => {
                    return (
                      <Marker
                        key={project.id}
                        // @ts-ignore
                        longitude={project.location.coordinates[0]}
                        // @ts-ignore
                        latitude={project.location.coordinates[1]}
                      >
                        <img
                          alt="Marker"
                          src={require('../../../images/marker.svg')}
                          className="relative w-3"
                          style={{
                            transform: 'translate(-50%, -50%)',
                          }}
                        />
                      </Marker>
                    );
                  })
              : null}

            {data
              ? data.projects.map((project) => (
                  <ProjectNameMarker
                    key={project.id}
                    projectId={project.id}
                    onShowMoreInfo={handleShowMoreInfo}
                  />
                ))
              : null}
          </ReactMapGL>
        ) : null}
        <div className="absolute bottom-0 right-0 mb-8 mr-4">
          <MapControlPanel className="mb-2" setViewport={setViewport} />
          <BaseMaps baseMap={baseMap} setBaseMap={setBaseMap} />
        </div>
      </div>
      <Drawer
        visible={!!activeProjectId}
        title="Project"
        onClose={handleMoreInfoDrawerClose}
        width={320}
        destroyOnClose
        style={{
          zIndex: 99999,
        }}
      >
        {activeProjectId ? <ActiveProject projectId={activeProjectId} /> : null}
      </Drawer>
    </>
  );
};

export default Map;
