import React, { useMemo, useCallback } from 'react';
import { Empty, Result, Button, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo';
import { range } from 'lodash-es';
import InfiniteScroll from 'react-infinite-scroller';

import {
  NOTIFICATIONS_QUERY,
  MARK_ALL_READ_NOTIFICATIONS_MUTATION,
} from '../../queries/notification';
import {
  Notifications as NotificationsInterface,
  NotificationsVariables,
} from '../../types/Notifications';
import Skeleton from '../../components/Skeleton';
import NotificationCard from './components/NotificationCard';
import { MarkAllReadNotifications } from '../../types/MarkAllReadNotifications';

interface Props extends RouteComponentProps {}

const Notifications: React.FC<Props> = () => {
  const { loading, error, data, fetchMore } = useQuery<
    NotificationsInterface,
    NotificationsVariables
  >(NOTIFICATIONS_QUERY, {
    variables: {
      page: 1,
    },
  });

  const [markAllReadNotificationsMutation] = useMutation<
    MarkAllReadNotifications
  >(MARK_ALL_READ_NOTIFICATIONS_MUTATION, {
    optimisticResponse: {
      readAllNotifications: {
        __typename: 'ReorderItem',
        success: true,
      },
    },
    update: (cache, { data: mutationUpdateData }) => {
      if (
        mutationUpdateData &&
        mutationUpdateData.readAllNotifications.success
      ) {
        let allNotifications;
        try {
          allNotifications = cache.readQuery<
            NotificationsInterface,
            NotificationsVariables
          >({
            query: NOTIFICATIONS_QUERY,
            variables: {
              page: 1,
            },
          });
        } catch (cacheReadError) {
          allNotifications = undefined;
        }

        if (allNotifications) {
          cache.writeQuery<NotificationsInterface, NotificationsVariables>({
            query: NOTIFICATIONS_QUERY,
            variables: {
              page: 1,
            },
            data: {
              notifications: {
                ...allNotifications.notifications,
                data: allNotifications.notifications.data.map(
                  (notification) => ({
                    ...notification,
                    read: true,
                  }),
                ),
                stats: {
                  ...allNotifications.notifications.stats,
                  unread: 0,
                },
              },
            },
          });
        }
      }
    },
    onError: (mutationError) => {
      message.error(mutationError.message);
    },
  });

  const handleReadAllNotifications = useCallback(() => {
    markAllReadNotificationsMutation();
  }, [markAllReadNotificationsMutation]);

  const handleLoadMore = useCallback(() => {
    if (data) {
      fetchMore({
        variables: {
          page: data.notifications.stats.page + 1,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            return {
              notifications: {
                ...fetchMoreResult.notifications,
                data: [
                  ...prevResult.notifications.data,
                  ...fetchMoreResult.notifications.data,
                ],
              },
            };
          }
          return prevResult;
        },
      });
    }
  }, [data, fetchMore]);

  const content = useMemo(() => {
    if (loading) {
      return (
        <>
          {range(4).map((val) => (
            <Skeleton key={val} title={false} avatar paragraph={{ rows: 2 }} />
          ))}
        </>
      );
    }

    if (error) {
      return <Result status="warning" subTitle={error.message} />;
    }

    if (data) {
      if (data.notifications.data.length === 0) {
        return (
          <div className="flex items-center justify-center h-full">
            <Empty description="No notifications" />
          </div>
        );
      }

      return (
        <InfiniteScroll
          hasMore={data.notifications.stats.hasMore}
          initialLoad={false}
          loadMore={handleLoadMore}
          loader={
            <div key="loader" className="my-4">
              {range(4).map((val) => (
                <Skeleton
                  key={val}
                  title={false}
                  avatar
                  paragraph={{ rows: 2 }}
                />
              ))}
            </div>
          }
          useWindow={false}
          pageStart={1}
          getScrollParent={() => {
            const scrollParent = document.getElementById(
              'notifications-container',
            ) as HTMLElement;
            return scrollParent;
          }}
        >
          <div className="bg-white shadow">
            {data.notifications.data.map((notification, index) => {
              return (
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                  className={
                    index !== data.notifications.data.length - 1
                      ? 'border-b'
                      : undefined
                  }
                />
              );
            })}
          </div>
        </InfiniteScroll>
      );
    }

    return null;
  }, [data, error, handleLoadMore, loading]);

  return (
    <div className="h-full p-4 overflow-auto" id="notifications-container">
      <div className="max-w-3xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-lg font-medium">Notifications</h1>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={handleReadAllNotifications}
          >
            Mark All as Read
          </Button>
        </div>
        <div className="flex-1">{content}</div>
      </div>
    </div>
  );
};

export default Notifications;
