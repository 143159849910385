import React from 'react';

const Category: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1C0 0.447715 0.447715 0 1 0H3C3.55228 0 4 0.447715 4 1V3C4 3.55228 3.55228 4 3 4H1C0.447715 4 0 3.55228 0 3V1Z"
        fill="currentColor"
      />
      <path
        d="M6 1C6 0.447715 6.44772 0 7 0H9C9.55228 0 10 0.447715 10 1V3C10 3.55228 9.55228 4 9 4H7C6.44772 4 6 3.55228 6 3V1Z"
        fill="currentColor"
      />
      <path
        d="M6 7C6 6.44772 6.44772 6 7 6H9C9.55228 6 10 6.44772 10 7V9C10 9.55228 9.55228 10 9 10H7C6.44772 10 6 9.55228 6 9V7Z"
        fill="currentColor"
      />
      <path
        d="M0 7C0 6.44772 0.447715 6 1 6H3C3.55228 6 4 6.44772 4 7V9C4 9.55228 3.55228 10 3 10H1C0.447715 10 0 9.55228 0 9V7Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Category;
