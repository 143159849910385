import React, { useCallback } from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import validator from 'validator';
import { useMutation } from 'react-apollo';

import styled from 'styled-components';
import { UserDetailInfoFragment } from '../../../../../types/UserDetailInfoFragment';
import {
  UPDATE_USER_MUTATION,
  USER_DETAIL_INFO_FRAGEMENT,
} from '../../../../../queries/user';
import {
  UpdateUser,
  UpdateUserVariables,
} from '../../../../../types/UpdateUser';
import countryCodes from '../../../../../utils/countryCodes';

interface Props {
  user: UserDetailInfoFragment;
  onEdit: () => void;
  onCancel: () => void;
  form: WrappedFormUtils;
}

const EditUserForm: React.FC<Props> = ({
  user,
  onEdit,
  onCancel,
  form: { getFieldDecorator, validateFields },
}) => {
  const [updateUserMutation, { loading }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UPDATE_USER_MUTATION, {
    update: (cache, { data }) => {
      if (data) {
        cache.writeFragment<UserDetailInfoFragment>({
          id: user.id,
          fragment: USER_DETAIL_INFO_FRAGEMENT,
          data: data.updateAccount,
        });
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('User data updated successfully');
      onEdit();
    },
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields((fieldErrors, values) => {
        if (!fieldErrors) {
          updateUserMutation({
            variables: {
              userId: user.id,
              name: values.name,
              email: values.email || undefined,
              countryCode: values.countryCode,
              phone:
                values.countryCode && values.phoneNumber
                  ? `${values.countryCode.replace('+', '')}${
                      values.phoneNumber
                    }`
                  : undefined,
              role: values.role,
              userType: values.userType,
              designation: values.designation || '',
            },
          });
        }
      });
    },
    [updateUserMutation, user.id, validateFields],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          initialValue: user.name,
          rules: [{ required: true, message: 'Name is required' }],
        })(<Input placeholder="Name" />)}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator('email', {
          initialValue: user.email,
          // @ts-ignore
          validate: [
            {
              trigger: 'onBlur',
              rules: [
                {
                  validator: async (rules: any, value: string) => {
                    if (value && !validator.isEmail(value)) {
                      throw new Error('Email is not valid');
                    }
                  },
                  message: 'Email is not valid',
                },
              ],
            },
          ],
        })(<Input type="email" placeholder="Email" />)}
      </Form.Item>
      <div className="ant-form-item-label">
        <label>Phone Number</label>
      </div>
      <PhoneInputContainer className="flex">
        <Form.Item>
          {getFieldDecorator('countryCode', {
            initialValue: user.countryCode ?? '+91',
          })(
            <Select
              placeholder="Country Code"
              style={{ minWidth: 120, maxWidth: 120 }}
              showSearch
              filterOption={(input: string, option: any) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {countryCodes.map((code) => (
                <Select.Option key={code.dialCode}>
                  {`${code.dialCode} - ${code.name}`}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('phoneNumber', {
            initialValue: user.phone?.replace(
              (user.countryCode || '+91')?.replace('+', ''),
              '',
            ),
          })(<Input placeholder="Phone Number" />)}
        </Form.Item>
      </PhoneInputContainer>
      <Form.Item label="Designation">
        {getFieldDecorator('designation', {
          initialValue: user.designation || '',
        })(<Input placeholder="Designation" />)}
      </Form.Item>
      <Form.Item label="User Type">
        {getFieldDecorator('userType', {
          initialValue: user.userType,
        })(
          <Select placeholder="User Role">
            <Select.Option key="CONTRACTOR">Contractor</Select.Option>
            <Select.Option key="PMC">PMC</Select.Option>
            <Select.Option key="ARCHITECT">Architect</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="User Role">
        {getFieldDecorator('role', {
          initialValue: user.role,
        })(
          <Select placeholder="User Role">
            <Select.Option key="FIELD_SUPERVISOR">
              Field Supervisor
            </Select.Option>
            <Select.Option key="ORG_ADMIN">Org Admin</Select.Option>
            <Select.Option key="DASHBOARD_USER">Dashboard User</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <div className="flex">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          className="mr-4"
        >
          Submit
        </Button>
        <Button htmlType="button" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default Form.create<Props>({ name: 'editUser' })(EditUserForm);

const PhoneInputContainer = styled.div`
  & .ant-select-selection {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & input {
    border-left-color: transparent;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;
