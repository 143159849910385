import React from 'react';
import { useQuery } from 'react-apollo';
import { Omit } from 'utility-types';
import Helmet from 'react-helmet';

import Header from '../Header';
import { PROJECT_BASIC_INFO_QUERY } from '../../queries/project';
import {
  ProjectBasicInfo,
  ProjectBasicInfoVariables,
} from '../../types/ProjectBasicInfo';
import Skeleton from '../Skeleton';

interface Props extends Omit<React.ComponentProps<typeof Header>, 'label'> {
  projectId: string;
}

const ModuleHeader: React.FC<Props> = ({
  projectId,
  className,
  ...restProps
}) => {
  const { loading, data } = useQuery<
    ProjectBasicInfo,
    ProjectBasicInfoVariables
  >(PROJECT_BASIC_INFO_QUERY, {
    variables: {
      projectId,
    },
  });

  if (loading) {
    return <Skeleton title paragraph={{ rows: 2 }} className={className} />;
  }

  if (data) {
    return (
      <>
        <Helmet>
          <title>
            {`BOCS - ${data.project.name} ${
              restProps.name ? `- ${restProps.name}` : ''
            }`}
          </title>
        </Helmet>
        <Header
          label={data.project.name}
          className={className}
          {...restProps}
        />
      </>
    );
  }

  return <div />;
};

export default ModuleHeader;
