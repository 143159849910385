import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { Result, Skeleton, Icon } from 'antd';
import {
  ResponsiveContainer,
  Line,
  LineChart,
  XAxis,
  Tooltip,
  CartesianGrid,
  YAxis,
} from 'recharts';
import { range } from 'lodash-es';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import useTheme from '../../../hooks/useTheme';
import { PROJECT_PHYSICAL_PROGRESSES_QUERY } from '../../../queries/physicalProgress';
import getThumbnail from '../../../utils/getThumbnail';
import ImageGallery from '../../../components/ImageGallery';
import Status from '../../../components/Status';
import { PROJECT_PROGRESS_S_CURVE } from '../../../queries/project';
import {
  ProjectProgressSCurve,
  ProjectProgressSCurveVariables,
} from '../../../types/ProjectProgressSCurve';
import {
  ProjectPhysicalProgresses,
  ProjectPhysicalProgressesVariables,
} from '../../../types/ProjectPhysicalProgresses';
import { ProjectLocationDetailFragment } from '../../../types/ProjectLocationDetailFragment';

interface Props {
  project: ProjectLocationDetailFragment;
  className?: string;
  style?: React.CSSProperties;
}

const ProjectDetail: React.FC<Props> = ({ project, className, style }) => {
  const [activeImage, setActiveImage] = useState<string | undefined>(undefined);

  const { id: projectId } = project;

  const theme = useTheme();

  const {
    loading: loadingTrendData,
    data: trendData,
    error: trendDataError,
  } = useQuery<ProjectProgressSCurve, ProjectProgressSCurveVariables>(
    PROJECT_PROGRESS_S_CURVE,
    {
      variables: {
        projectId,
      },
    },
  );

  const {
    loading: loadingImages,
    data: imagesData,
    error: imagesError,
  } = useQuery<ProjectPhysicalProgresses, ProjectPhysicalProgressesVariables>(
    PROJECT_PHYSICAL_PROGRESSES_QUERY,
    {
      variables: {
        projectId,
        page: 1,
        limit: 4,
      },
    },
  );

  const handleImageGalleryClose = useCallback(() => {
    setActiveImage(undefined);
  }, []);

  const trend = useMemo(() => {
    if (loadingTrendData) {
      return <Skeleton />;
    }

    if (trendDataError) {
      return <Result status="warning" subTitle={trendDataError.message} />;
    }

    if (trendData) {
      return (
        <div>
          <div className="mb-2 text-sm font-medium text-text-color">
            Cummulative Progress
          </div>
          <div className="flex items-center mb-4">
            <div className="w-2 h-2 mr-2 rounded-full bg-primary-color" />
            <div className="mr-4 text-caption">Actual Progress</div>
            <div className="w-2 h-2 mr-2 rounded-full bg-success-color" />
            <div className="text-caption">Planned Progress</div>
          </div>
          <div className="-mx-6">
            <ChartContainer width="100%" height={200}>
              <LineChart
                data={trendData.projectTrend}
                margin={{ right: 32, left: -8, top: 8 }}
              >
                <Tooltip
                  labelFormatter={(val) => (
                    <span className="font-medium">
                      {moment(val).format('MMM YYYY')}
                    </span>
                  )}
                  formatter={(val) => `${val} %`}
                  contentStyle={{
                    fontSize: 12,
                  }}
                />
                <Line
                  dataKey="cummActualProgressPercentage"
                  stroke={theme.primaryColor}
                  strokeWidth={2}
                  dot={false}
                  name="Actual Progress"
                />
                <Line
                  dataKey="cummPlannedProgressPercentage"
                  stroke={theme.successColor}
                  dot={false}
                  strokeWidth={2}
                  strokeOpacity={0.75}
                  name="Planned Progress"
                />
                <XAxis
                  dataKey="tick"
                  tickFormatter={(val) => moment(val).format('MM/YYYY')}
                />
                <YAxis
                  axisLine={false}
                  ticks={[0, 25, 50, 75, 100]}
                  tickFormatter={(val) => `${val}%`}
                />
                <CartesianGrid vertical={false} strokeOpacity={0.25} />
              </LineChart>
            </ChartContainer>
          </div>
        </div>
      );
    }

    return null;
  }, [
    loadingTrendData,
    theme.primaryColor,
    theme.successColor,
    trendData,
    trendDataError,
  ]);

  const gallery = useMemo(() => {
    if (loadingImages) {
      return (
        <div className="flex items-center overflow-auto">
          {range(4).map((val) => (
            <div key={val} className="h-24 mr-4 rounded skeleton min-w-24" />
          ))}
        </div>
      );
    }

    if (imagesError) {
      return <Result status="warning" subTitle={imagesError.message} />;
    }

    if (imagesData) {
      if (imagesData.physicalProgresses.data.length === 0) {
        return null;
      }

      const allImages = imagesData.physicalProgresses.data.flatMap((progress) =>
        (progress.photos || []).map((image) => ({
          image,
          progressInfo: progress,
        })),
      );

      return (
        <>
          <div className="flex items-center overflow-auto">
            {allImages.map((image) => {
              return (
                <img
                  className="object-cover h-24 mr-4 rounded cursor-pointer min-w-24"
                  src={
                    getThumbnail({ url: image.image, mimeType: 'image/*' }).url
                  }
                  key={image.image}
                  alt="Physical Progress"
                  onClick={() => {
                    setActiveImage(image.image);
                  }}
                />
              );
            })}
            <Link
              className="flex items-center justify-center h-24 shadow min-w-24"
              to={`/projects/${projectId}/physical-progress/gallery/latest`}
            >
              <span className="text-primary-color">See All</span>
            </Link>
          </div>
          {allImages.length > 0 ? (
            <ImageGallery
              images={allImages}
              visible={!!activeImage}
              onClose={handleImageGalleryClose}
              startIndex={
                activeImage
                  ? allImages.findIndex((image) => image.image === activeImage)
                  : undefined
              }
            />
          ) : null}
        </>
      );
    }

    return null;
  }, [
    loadingImages,
    imagesError,
    imagesData,
    projectId,
    activeImage,
    handleImageGalleryClose,
  ]);

  return (
    <div className={className} style={style}>
      <div className="mb-4">
        <div className="mb-2 text-base font-medium text-heading-color">
          {project.name || 'N/A'}
        </div>
        <Link to={`/projects/${project.id}/dashboard`} className="block mb-2">
          <span className="flex items-center text-xs text-primary-color">
            <span className="mr-2">View Project</span>
            <Icon type="right" />
          </span>
        </Link>
        <div className="flex items-center mb-4">
          <span className="mr-2 text-xs font-medium text-heading-color">
            {project.cummProgressPercentage || 0}%
          </span>
          <Status status={project.status} />
        </div>
        <div className="text-xs">
          {project.startDate
            ? moment(project.startDate).format('DD MMM YYYY')
            : 'N/A'}{' '}
          -{' '}
          {project.targetDateOfCompletion
            ? moment(project.targetDateOfCompletion).format('DD MMM YYYY')
            : 'N/A'}
        </div>
        {project.projectedTDC ? (
          <div className="mt-1 text-xs text-caption-color">
            Projected TDC - {moment(project.projectedTDC).format('DD MMM YYYY')}{' '}
          </div>
        ) : null}
      </div>
      <div className="pb-4 mb-4 text-sm border-b">
        <div className="mb-4 text-sm font-medium text-text-color">Agencies</div>
        {project.agencies && project.agencies.length > 0 ? (
          project.agencies.map((agency, index, agencies) => (
            <div
              key={agency.id}
              className={clsx(
                'flex items-center',
                index !== agencies.length - 1 ? 'mb-2' : undefined,
              )}
            >
              <div className="mb-1 text-xs font-medium text-primary-color">
                {/* TODO- attach link's to agencies name later */}
                {agency.name}
              </div>
            </div>
          ))
        ) : (
          <span className="text-sm">N/A</span>
        )}
      </div>
      <div className="mb-4">{trend}</div>
      {gallery}
    </div>
  );
};

export default ProjectDetail;

const ChartContainer = styled(ResponsiveContainer)`
  &&& {
    & .recharts-cartesian-axis-tick-value {
      font-size: 10px;
    }

    & .recharts-xAxis,
    & .recharts-yAxis {
      opacity: 0.5;
    }

    & .recharts-cartesian-axis-tick-value tspan {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;
