import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

import omitProps from '../../utils/omitProps';

type Level = 1 | 2;

interface Props extends React.ComponentProps<typeof Typography.Text> {
  level?: Level;
  uppercase?: boolean;
}

const Caption: React.FC<Props> = ({ level, uppercase, ...restProps }) => {
  return <StyledCaption level={level} uppercase={uppercase} {...restProps} />;
};

Caption.defaultProps = {
  level: 1,
  uppercase: false,
};

export default Caption;

const StyledCaption = styled(
  omitProps(Typography.Text, ['level', 'uppercase']),
)<{
  level?: Level;
  uppercase?: boolean;
}>`
  &&& {
    font-size: ${({ level }) => (level === 1 ? 12 : 10)}px;
    font-weight: ${({ uppercase }) => (uppercase ? 500 : 400)};
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    color: ${({ theme }) => theme.textColor};
    line-height: ${({ level }) => (level === 1 ? 16 : 14)}px;
    letter-spacing: ${({ uppercase }) => (uppercase ? '0.42px' : 'initial')};
  }
`;
