import React from 'react';
import { Form, Input, Button } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

type NodeType = {
  id: string;
  name: string;
  parentId: string | null;
};

interface Props {
  form: WrappedFormUtils;
  group: NodeType;
  onEdit: (name: string) => void;
  onCancel: () => void;
}

const ActiveProjectGroup: React.FC<Props> = ({
  form: { getFieldDecorator, validateFields },
  onCancel,
  onEdit,
  group,
}) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields(async (fieldErrors, values) => {
      if (!fieldErrors) {
        const { name } = values;

        const issueData: {
          name: string;
        } = {
          name,
        };

        onEdit(issueData.name);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Edit Name">
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: 'Name is required',
            },
          ],
          initialValue: group.name,
        })(<Input />)}
      </Form.Item>
      <div className="flex">
        <Button
          type="primary"
          htmlType="submit"
          className="mr-4"
        >
          Submit
        </Button>
        <Button htmlType="button" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default Form.create<Props>({ name: 'editGroup' })(ActiveProjectGroup);
