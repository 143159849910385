/* eslint-disable camelcase */
import React, { useMemo, useContext } from 'react';
import { useQuery } from 'react-apollo';
import clsx from 'clsx';
import { range } from 'lodash-es';
import { Result, Empty, Button } from 'antd';
import { MR_ITEMS_QUERY } from '../../../../../queries/mrItems';
import { MRItems, MRItemsVariables } from '../../../../../types/MRItems';
import ItemsListItem from './ItemsListItem';
import { OverallMRItemStats_mrItemStats } from '../../../../../types/OverallMRItemStats';
import CreateMRItem from './CreateMRItem';
import AuthContext from '../../../../../contexts/AuthContext';
import { CPD_MR_ITEM_STATS_QUERY } from '../../../../../modules/MRDashboard/queries';
import {
  CPDMrItemStats,
  CPDMrItemStatsVariables,
} from '../../../../../types/CPDMrItemStats';

interface Props {
  type: string;
  typeColor: string;
  typeLabel: string;
  className?: string;
  style?: React.CSSProperties;
}

const TypeStats: React.FC<Props> = ({
  type,
  typeColor,
  typeLabel,
  className,
  style,
}) => {
  const { user } = useContext(AuthContext);

  const {
    loading: loadingMRItems,
    data: mrItemsData,
    error: mrItemsError,
  } = useQuery<MRItems, MRItemsVariables>(MR_ITEMS_QUERY, {
    variables: { cpdId: user?.id },
  });

  const { data: overallMRItemStatsData } = useQuery<
    CPDMrItemStats,
    CPDMrItemStatsVariables
  >(CPD_MR_ITEM_STATS_QUERY, {
    variables: {
      cpdId: user?.id || '',
    },
  });

  const itemStats = overallMRItemStatsData
    ? overallMRItemStatsData.mrItemStats[
        // @ts-ignore
        `total${type}` as OverallMRItemStats_mrItemStats
      ]
    : undefined;

  const content = useMemo(() => {
    if (loadingMRItems) {
      return (
        <>
          {range(4).map((val) => (
            <div className="w-7/12 h-6 skeleton" key={val} />
          ))}
        </>
      );
    }

    if (mrItemsError) {
      return <Result status="warning" subTitle={mrItemsError.message} />;
    }

    if (mrItemsData) {
      const itemsToRender = mrItemsData.mrItems.filter(
        (item) => item.type === type,
      );

      if (itemsToRender.length === 0) {
        return (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No entries present"
          />
        );
      }

      return (
        <>
          {itemsToRender.map((item) => (
            <ItemsListItem
              key={item.id}
              type={type}
              label={typeLabel}
              listItem={item}
            />
          ))}
        </>
      );
    }

    return null;
  }, [loadingMRItems, mrItemsData, mrItemsError, type, typeLabel]);

  return (
    <div
      className={clsx('bg-white rounded-md shadow', className)}
      style={style}
    >
      <div className="flex items-center justify-between px-4 py-2 border-b">
        <div className="font-medium text-heading-color">{typeLabel}</div>
        <div
          className="w-6 h-6 rounded-full"
          style={{ backgroundColor: typeColor }}
        />
      </div>
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="text-lg font-semibold text-heading-color">
            {Math.max(itemStats, 0) || 0} RKm
          </div>
          {type !== 'WNS' ? (
            <CreateMRItem
              type={type}
              typeLabel={typeLabel}
              trigger={<Button icon="plus">Add Entry</Button>}
            />
          ) : null}
        </div>
        <div className="space-y-4 overflow-auto max-h-64">{content}</div>
      </div>
    </div>
  );
};

export default TypeStats;
