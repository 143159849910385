import React from 'react';
import { omit } from 'lodash-es';

const omitProps = <P>(
  component: React.ComponentType<P>,
  propsToOmit: Array<string>,
): React.ComponentType<P> => {
  return props => {
    return React.createElement(component, omit(props, propsToOmit) as any);
  };
};

export default omitProps;
