import React, { useCallback } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Form, Input, Button, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import {
  PROJECT_BASIC_INFO_QUERY,
  PROJECT_BASIC_INFO_FRAGMENT,
  UPDATE_PROJECT,
} from '../../queries/project';
import {
  ProjectBasicInfo,
  ProjectBasicInfoVariables,
} from '../../types/ProjectBasicInfo';
import ImageUploader from '../ImageUploader';
import {
  UpdateProject,
  UpdateProjectVariables,
} from '../../types/UpdateProject';
import { ProjectBasicInfoFragment } from '../../types/ProjectBasicInfoFragment';
import PlacesAutocomplete from '../PlacesAutocomplete';

interface Props {
  projectId: string;
  form: WrappedFormUtils;
  onUpdate?: () => void;
}

const EditProject: React.FC<Props> = ({
  projectId,
  form: { getFieldDecorator, validateFields, resetFields },
  onUpdate,
}) => {
  const { data } = useQuery<ProjectBasicInfo, ProjectBasicInfoVariables>(
    PROJECT_BASIC_INFO_QUERY,
    {
      variables: {
        projectId,
      },
    },
  );

  const [updateProjectMutation, { loading }] = useMutation<
    UpdateProject,
    UpdateProjectVariables
  >(UPDATE_PROJECT, {
    update: (cache, { data: updateProjectData }) => {
      if (updateProjectData) {
        cache.writeFragment<ProjectBasicInfoFragment>({
          fragment: PROJECT_BASIC_INFO_FRAGMENT,
          id: projectId,
          data: updateProjectData.updateProject,
        });
      }
    },
    onCompleted: () => {
      if (onUpdate) {
        message.success('Project updated successfully');
        resetFields();
        onUpdate();
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields((fieldErrors, values) => {
        if (!fieldErrors) {
          const { name, photo, location } = values as {
            name: string;
            photo?: Array<any>; // TODO: Change any to proper definition
            location?: { latitude: number; longitude: number };
          };
          updateProjectMutation({
            variables: {
              name,
              thumbnail:
                photo && photo.length > 0
                  ? [typeof photo[0] === 'string' ? photo[0] : photo[0].url]
                  : [],
              projectId,
              location: location
                ? [location.longitude, location.latitude]
                : undefined,
            },
          });
        }
      });
    },
    [projectId, updateProjectMutation, validateFields],
  );

  if (!data) {
    return null;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Project Name">
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: 'Name is required',
            },
          ],
          initialValue: data.project.name,
        })(<Input placeholder="Project Name" />)}
      </Form.Item>
      <Form.Item label="Project Location">
        {getFieldDecorator('location', {
          getValueFromEvent: ({ latitude, longitude }) => {
            return { longitude, latitude };
          },
          valuePropName: 'initialValue',
          initialValue:
            data.project.location && data.project.location.coordinates
              ? {
                  latitude: data.project.location.coordinates[1],
                  longitude: data.project.location.coordinates[0],
                }
              : undefined,
        })(<PlacesAutocomplete />)}
      </Form.Item>
      <Form.Item label="Project Thumbnail">
        {getFieldDecorator('photo', {
          initialValue: data.project.photos
            ? data.project.photos.map((photo) => ({
                uid: photo,
                name: photo,
                status: 'done',
                url: photo,
              }))
            : undefined,
          valuePropName: 'fileList',
        })(
          <ImageUploader
            multiple={false}
            accept="image/png,image/jpg,image/jpeg,image/bmp"
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<Props>({ name: 'editProject' })(EditProject);
