import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Switch } from 'react-router-dom';
import Logrocket from 'logrocket';

import theme from './styles/theme';
import Route from './components/Route';
import Loader from './components/Loader';
import Auth from './components/Auth';
import Apollo from './components/Apollo';
import Media from './components/Media';
import ServiceWorker from './components/ServiceWorker';
import Intercom from './components/Intercom';
import AppShell from './components/AppShell';

import Login from './scenes/Login';
import UpdatePassword from './scenes/UpdatePassword';
import Home from './scenes/Home';
import WorkInProgress from './scenes/WorkInProgress';
import Notifications from './scenes/Notifications';
import Users from './scenes/Users';
import NoAccess from './scenes/NoAccess';
import GetStarted from './scenes/GetStarted';
import ProjectLocations from './scenes/ProjectLocations';
import ProjectTrees from './scenes/ProjectTrees';
import Reports from './modules/Reports';

const MasterDashboard = React.lazy(() => import('./modules/MasterDashboard'));

const ProjectDashboard = React.lazy(() => import('./modules/ProjectDashboard'));

const PhysicalProgress = React.lazy(() => import('./modules/PhysicalProgress'));

const FinancialProgress = React.lazy(() =>
  import('./modules/FinancialProgress'),
);

const Drawings = React.lazy(() => import('./modules/Drawings'));

const Issues = React.lazy(() => import('./modules/Issues'));

const Drive = React.lazy(() => import('./modules/Drive'));

const MaterialManagement = React.lazy(() =>
  import('./modules/MaterialManagement'),
);

const RFI = React.lazy(() => import('./modules/RFI'));

const NCR = React.lazy(() => import('./modules/NCR'));

const MRDashboard = React.lazy(() => import('./modules/MRDashboard'));

const MaterialTesting = React.lazy(() => import('./modules/MaterialTesting'));

const PurchaseProjections = React.lazy(() =>
  import('./modules/PurchaseProjections'),
);

if (process.env.REACT_APP_LOGROCKET_ID) {
  Logrocket.init(process.env.REACT_APP_LOGROCKET_ID);
}

const App: React.FC = () => {
  return (
    <Media>
      <ThemeProvider theme={theme}>
        <>
          <Auth>
            <>
              <Apollo>
                <AppShell>
                  <React.Suspense
                    fallback={
                      <div className="relative w-full h-full">
                        <Loader text="Loading Module" />
                      </div>
                    }
                  >
                    <>
                      <Switch>
                        <Route path="/login" component={Login} />

                        <Route
                          path="/update-password"
                          component={UpdatePassword}
                        />

                        <Route path="/get-started" component={GetStarted} />

                        <Route
                          path={['/', '/mr-data']}
                          exact
                          component={Home}
                          protectedRoute
                        />

                        <Route
                          path="/master-dashboard"
                          component={MasterDashboard}
                          protectedRoute
                          moduleName="MASTER_DASHBOARD"
                        />

                        <Route
                          path="/mr-dashboard"
                          component={MRDashboard}
                          protectedRoute
                        />

                        <Route
                          path={[
                            '/project-tree',
                            '/project-tree/heirarchy/:groupId',
                          ]}
                          exact
                          component={ProjectTrees}
                          protectedRoute
                        />

                        <Route
                          path="/project-locations"
                          exact
                          component={ProjectLocations}
                          protectedRoute
                          moduleName="PROJECT_LOCATIONS"
                        />

                        <Route
                          path="/users"
                          exact
                          component={Users}
                          protectedRoute
                          moduleName="USER_MANAGEMENT"
                        />

                        <Route
                          path="/notifications"
                          component={Notifications}
                          protectedRoute
                        />

                        <Route
                          path={[
                            '/projects/:projectId/dashboard',
                            '/projects/:projectId/dashboard/overview',
                            '/projects/:projectId/dashboard/mr-dashboard',
                          ]}
                          exact
                          component={ProjectDashboard}
                          protectedRoute
                        />

                        <Route
                          path={[
                            '/projects/:projectId/physical-progress',
                            '/projects/:projectId/physical-progress/categories',
                            '/projects/:projectId/physical-progress/categories/:categoryId',
                            '/projects/:projectId/physical-progress/project-elements/:projectElementId',
                            '/projects/:projectId/physical-progress/bar-chart',
                            '/projects/:projectId/physical-progress/geotagged-updates',
                            '/projects/:projectId/physical-progress/gallery/latest',
                            '/projects/:projectId/physical-progress/gallery/list',
                            '/projects/:projectId/physical-progress/gallery/:parentType/:parentId',
                            '/projects/:projectId/physical-progress/supervisors-activities',
                          ]}
                          exact
                          component={PhysicalProgress}
                          protectedRoute
                          moduleName="PHYSICAL_PROGRESS"
                        />

                        <Route
                          path="/projects/:projectId/issues/:issueId?"
                          component={Issues}
                          moduleName="ISSUE_MANAGEMENT"
                          protectedRoute
                        />

                        <Route
                          path={[
                            '/projects/:projectId/financial-progress/bills/:billId',
                            '/projects/:projectId/financial-progress/setup',
                            '/projects/:projectId/financial-progress',
                          ]}
                          component={FinancialProgress}
                          protectedRoute
                          moduleName="FINANCIAL_PROGRESS"
                        />

                        <Route
                          path={[
                            '/projects/:projectId/drawings/:drawingId',
                            '/projects/:projectId/drawings',
                          ]}
                          component={Drawings}
                          protectedRoute
                          moduleName="DRAWING_MANAGEMENT"
                        />

                        <Route
                          path="/projects/:projectId/material-management/:parentId?"
                          component={MaterialManagement}
                          protectedRoute
                        />

                        <Route
                          path={[
                            '/projects/:projectId/drive/my-drive/:parentId?',
                            '/projects/:projectId/drive/trash',
                            '/projects/:projectId/drive/my-drive/shared-with-me',
                          ]}
                          component={Drive}
                          protectedRoute
                          moduleName="DRIVE_MANAGEMENT"
                        />

                        <Route
                          path={['/projects/:projectId/reports/instant-report']}
                          component={Reports}
                          moduleName="REPORTS"
                          protectedRoute
                        />

                        <Route
                          path={[
                            '/projects/:projectId/rfi/checklists/:folderId?',
                            '/projects/:projectId/rfi/rfis/:rfiId?',
                          ]}
                          protectedRoute
                          moduleName="PHYSICAL_PROGRESS"
                          component={RFI}
                        />

                        <Route
                          path={[
                            '/projects/:projectId/ncr/checklists/:folderId?',
                            '/projects/:projectId/ncr/ncrs/:ncrId?',
                          ]}
                          protectedRoute
                          moduleName="PHYSICAL_PROGRESS"
                          component={NCR}
                        />

                        <Route
                          path={[
                            '/projects/:projectId/material-testing/concrete',
                            '/projects/:projectId/material-testing/steel',
                          ]}
                          protectedRoute
                          moduleName="PHYSICAL_PROGRESS"
                          component={MaterialTesting}
                        />

                        <Route
                          path={['/projects/:projectId/purchase-projections']}
                          protectedRoute
                          moduleName="PHYSICAL_PROGRESS"
                          component={PurchaseProjections}
                        />

                        <Route
                          path="/no-access"
                          exact
                          component={NoAccess}
                          protectedRoute
                        />

                        <Route component={WorkInProgress} />
                      </Switch>
                    </>
                  </React.Suspense>
                </AppShell>
              </Apollo>
              <Intercom />
            </>
          </Auth>
          <ServiceWorker />
        </>
      </ThemeProvider>
    </Media>
  );
};

export default App;
