import React from 'react';

const Drawing: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.045 2.577a3.563 3.563 0 01.57 6.456l1.888 5.3a8.316 8.316 0 004.348-6.168c.093-.65.615-1.184 1.27-1.184.657 0 1.195.534 1.123 1.186a10.692 10.692 0 01-5.938 8.421l.975 2.737c.52 1.46.295 3.08-.603 4.343l-.235.332-4.818-13.488-1.649 4.615a8.346 8.346 0 002.092.152l.825 2.312a10.812 10.812 0 01-3.722-.209L6.808 24l-.236-.332a4.748 4.748 0 01-.602-4.343l.974-2.737a10.691 10.691 0 01-5.938-8.421C.935 7.515 1.473 6.98 2.13 6.98c.655 0 1.178.535 1.27 1.184a8.317 8.317 0 004.348 6.168l1.975-5.548a3.563 3.563 0 01.949-6.209V1.188a1.187 1.187 0 012.374 0v1.39zm-1.187 2.171a1.187 1.187 0 100 2.375 1.187 1.187 0 000-2.375z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Drawing;
