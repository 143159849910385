import React, { useCallback, forwardRef } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

interface Props extends SelectProps {
  query: any;
  extractOptions: (
    data: any,
  ) => Array<{ value: string; label: string; image?: JSX.Element }>;
  variables?: any;
}

const QueryAsyncSelect = forwardRef(
  (
    { query, extractOptions, variables, value, ...restProps }: Props,
    ref: any,
  ) => {
    const { loading, data } = useQuery(query, { variables });

    const filterOption = useCallback(
      (input: string, option: any) =>
        (option.props.title as string)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0,
      [],
    );

    const items = data ? extractOptions(data) : [];

    return (
      <Select
        ref={ref}
        loading={loading}
        value={loading ? undefined : value}
        showSearch={restProps.showSearch}
        filterOption={restProps.showSearch ? filterOption : undefined}
        {...restProps}
      >
        {items.map((item) => (
          <Select.Option key={item.value} value={item.value} title={item.label}>
            {item.image ? <ImageContainer>{item.image}</ImageContainer> : null}
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  },
);

QueryAsyncSelect.displayName = 'QueryAsyncSelect';

export default QueryAsyncSelect;

const ImageContainer = styled.span`
  margin-right: 8px;
`;
