import React from 'react';

const DragHandle: React.FC = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
    >
      <circle cx="9" cy="2" r="2" fill="currentColor" />
      <circle cx="16" cy="2" r="2" fill="currentColor" />
      <circle cx="9" cy="9" r="2" fill="currentColor" />
      <circle cx="16" cy="9" r="2" fill="currentColor" />
      <circle cx="9" cy="16" r="2" fill="currentColor" />
      <circle cx="16" cy="16" r="2" fill="currentColor" />
      <circle cx="9" cy="22" r="2" fill="currentColor" />
      <circle cx="16" cy="22" r="2" fill="currentColor" />
    </svg>
  );
};

export default DragHandle;
