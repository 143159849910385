import React from 'react';

const ProjectElement: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 2.25H9.75V9.75H2.25V2.25ZM2.75 2.75V9.25H9.25V2.75H2.75Z"
        fill="currentColor"
      />
      <path d="M8.5 1.5H10.5V3.5H8.5V1.5Z" fill="currentColor" />
      <path d="M8.5 8.5H10.5V10.5H8.5V8.5Z" fill="currentColor" />
      <path d="M1.5 8.5H3.5V10.5H1.5V8.5Z" fill="currentColor" />
      <path d="M1.5 1.5H3.5V3.5H1.5V1.5Z" fill="currentColor" />
    </svg>
  );
};

export default ProjectElement;
