import React, { useCallback } from 'react';
import { Form, Button, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useMutation } from 'react-apollo';

import QueryAsyncSelect from '../../../components/QueryAsyncSelect';
import { ProjectBasicInfoFragment } from '../../../types/ProjectBasicInfoFragment';
import {
  ASSOCIATE_PROJECTS_MUTATION,
  PROJECT_BASIC_INFO_FRAGMENT,
  PROJECTS_FOR_ORGANISATION_QUERY,
} from '../../../queries/project';
import {
  AssociateProjects,
  AssociateProjectsVariables,
} from '../../../types/AssociateProjects';
import { GroupInfoFragment } from '../../../types/GroupInfoFragment';
import { GROUP_INFO_FRAGMENT } from '../queries';

interface Props {
  group: GroupInfoFragment;
  onSubmit: () => void;
  onCancel: () => void;
  form: WrappedFormUtils;
}

const AssociateProjectForm: React.FC<Props> = ({
  group,
  onSubmit,
  onCancel,
  form: { getFieldDecorator, validateFields, getFieldValue },
}) => {
  const [associateProjectsMutation, { loading }] = useMutation<
    AssociateProjects,
    AssociateProjectsVariables
  >(ASSOCIATE_PROJECTS_MUTATION, {
    update: (cache, { data }) => {
      if (data) {
        const associateProjectIds = (getFieldValue('projects') || []) as Array<
          string
        >;

        const associatedProjects = associateProjectIds
          .map((projectId) => {
            try {
              return cache.readFragment<ProjectBasicInfoFragment>({
                fragment: PROJECT_BASIC_INFO_FRAGMENT,
                id: projectId,
              });
            } catch (cacheReadError) {
              return null;
            }
          })
          .filter((project) => project) as Array<ProjectBasicInfoFragment>;

        cache.writeFragment<GroupInfoFragment>({
          fragment: GROUP_INFO_FRAGMENT,
          fragmentName: 'GroupInfoFragment',
          id: group.id,
          data: {
            ...group,
            projects: associatedProjects,
          },
        });
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Projects successfully associated');
      onSubmit();
    },
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields((fieldErrors, values) => {
        if (!fieldErrors) {
          associateProjectsMutation({
            variables: {
              groupId: group.id,
              projectIds: values.projects || null,
            },
          });
        }
      });
    },
    [associateProjectsMutation, group.id, validateFields],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Projects Associated">
        {getFieldDecorator('projects', {
          initialValue: (group.projects || []).map((project) => project.id),
        })(
          <QueryAsyncSelect
            query={PROJECTS_FOR_ORGANISATION_QUERY}
            variables={{
              organisationId: group.organisation.id,
            }}
            extractOptions={({
              projects,
            }: {
              projects: Array<ProjectBasicInfoFragment>;
            }) => {
              return projects.map((project) => ({
                value: project.id,
                label: project.name,
              }));
            }}
            mode="multiple"
            placeholder="Select Projects"
          />,
        )}
      </Form.Item>
      <div className="flex">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          className="mr-4"
        >
          Submit
        </Button>
        <Button htmlType="button" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default Form.create<Props>({ name: 'associateProject' })(
  AssociateProjectForm,
);
