import React from 'react';
import { Icon } from 'antd';

import Status from '../../../components/Status';
import Progress from '../../../components/Progress';
import { ProjectLocationDetailFragment } from '../../../types/ProjectLocationDetailFragment';

interface Props {
  project: ProjectLocationDetailFragment;
  onShowMoreInfo: () => void;
}

const ProjectPopoverInfo: React.FC<Props> = ({ project, onShowMoreInfo }) => {
  return (
    <div className="w-48">
      <div className="flex items-center justify-between mb-4 text-caption">
        <div className="font-medium uppercase text-heading-color">Status</div>
        <div>
          <Status status={project.status} />
        </div>
      </div>
      <div className="mb-2">
        <Progress
          progress={project.cummProgressPercentage || 0}
          expectedProgress={project.cummPlannedProgressPercentage || 0}
          status={project.status || []}
          size={8}
          showStats
          additionalStats={
            <>
              <span className="mr-2 uppercase text-caption text-caption-color">
                Planned
              </span>
              <span className="font-medium uppercase text-caption">
                {project.cummPlannedProgressPercentage || 0}%
              </span>
            </>
          }
        />
      </div>
      <div
        className="flex items-center justify-between text-xs cursor-pointer select-none text-primary-color"
        onClick={onShowMoreInfo}
      >
        <span>More Info</span>
        <Icon type="right" className="text-xs fill-current" />
      </div>
    </div>
  );
};

export default ProjectPopoverInfo;
