import { useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo';

import { Groups, GroupsVariables } from '../types/Groups';
import { GROUPS_QUERY } from '../scenes/ProjectTreeDetailedView/queries';

interface GroupNode {
  id: string;
  name: string;
  parentId?: string;
}

const useGroups = (rootId: string) => {
  const [groups, setGroups] = useState<Array<GroupNode>>([]);

  const client = useApolloClient();

  useEffect(() => {
    const fetchGroupsForParent = async (
      parentId: string,
    ): Promise<Array<GroupNode>> => {
      const { data } = await client.query<Groups, GroupsVariables>({
        query: GROUPS_QUERY,
        variables: { parentGroupId: parentId },
        context: {
          batch: true,
        },
      });

      if (data) {
        const groupsData = data.groups.map((group) => ({
          id: group.id,
          name: group.name,
          parentId,
        }));

        const groupsChildrenData = data.groups.flatMap((group) =>
          group.children
            ? group.children.map((child) => ({
                id: child.id,
                name: child.name,
                parentId: group.id,
              }))
            : [],
        );

        const allData = [...groupsData, ...groupsChildrenData];

        if (groupsChildrenData.length > 0) {
          const nestedChildrenData = await Promise.all(
            groupsChildrenData.map((group) => fetchGroupsForParent(group.id)),
          );
          return [
            ...allData,
            ...nestedChildrenData.flatMap((childrenData) => childrenData),
          ];
        }

        return allData;
      }

      return [];
    };

    fetchGroupsForParent(rootId).then(setGroups);
  }, [client, rootId]);

  return groups;
};

export default useGroups;