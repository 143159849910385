import { createContext } from 'react';

const AppShellContext = createContext<{
  drawerExpanded: boolean;
  setDrawerExpanded: (expandedState: boolean) => void;
}>({
  drawerExpanded: true,
  setDrawerExpanded: () => {},
});

export default AppShellContext;
