import React from 'react';
import { useQuery } from 'react-apollo';

import ProjectDetail from './ProjectDetail';
import { PROJECT_LOCATION_DRAWER_DETAIL_QUERY } from '../queries';
import {
  ProjectLocationDrawerDetail,
  ProjectLocationDrawerDetailVariables,
} from '../../../types/ProjectLocationDrawerDetail';

interface Props {
  projectId: string;
}

const ActiveProject: React.FC<Props> = ({ projectId }) => {
  const { data } = useQuery<
    ProjectLocationDrawerDetail,
    ProjectLocationDrawerDetailVariables
  >(PROJECT_LOCATION_DRAWER_DETAIL_QUERY, {
    variables: {
      projectId,
    },
  });

  if (!data) {
    return null;
  }

  return <ProjectDetail project={data.project} className="mb-4" />;
};

export default ActiveProject;
