import React, { useMemo } from 'react';
import color from 'color';
import { upperCase } from 'lodash-es';
import clsx from 'clsx';

import { getStatusColor } from '../../utils/status';
import useTheme from '../../hooks/useTheme';

interface Props {
  status: Array<string> | null;
  count?: number;
  resizeOnMobile?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const Status: React.FC<Props> = ({
  status,
  count,
  resizeOnMobile,
  className,
  style,
}) => {
  const theme = useTheme();

  const statusColor = useMemo(() => {
    return getStatusColor(status, theme);
  }, [status, theme]);

  const statusValue = status
    ? status.length > 1
      ? status.filter((val) => val !== 'active')[0]
      : status[0]
    : '';

  const label = statusValue !== 'notYetStarted' ? statusValue : 'Not Started';

  return (
    <div style={style} className={className}>
      <div
        className={clsx(
          'w-2 h-2 rounded-full',
          resizeOnMobile ? 'block md:hidden' : 'hidden',
        )}
        style={{ backgroundColor: statusColor.backgroundColor }}
      />
      <div
        style={{
          backgroundColor: color(statusColor.backgroundColor)
            .alpha(0.15)
            .toString(),
        }}
        className={clsx(
          'rounded-full inline-block py-1 px-2',
          resizeOnMobile ? 'hidden md:block' : undefined,
        )}
      >
        <div
          className="font-semibold tracking-wide text-center uppercase whitespace-no-wrap text-caption"
          style={{
            color: color(statusColor.backgroundColor).darken(0.15).toString(),
          }}
        >
          {count ? `${count} ${upperCase(label)}` : upperCase(label)}
        </div>
      </div>
    </div>
  );
};

export default Status;
