import React, { useCallback, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd';
import Helmet from 'react-helmet';

import CreateHierarchyTree from './components/CreateHierarchyTree';
import MediaContext from '../../contexts/MediaContext';
import { NAV_BAR_HEIGHT } from '../../styles/common';

interface Props extends RouteComponentProps {
  params: string;
}

const Home: React.FC<Props> = ({ match: { params }, history }) => {
  const { groupId } = params as { groupId: string };

  const { viewportHeight } = useContext(MediaContext);

  const appContainerHeight = viewportHeight - NAV_BAR_HEIGHT;

  const close = useCallback(() => {
    history.replace('/project-tree');
  }, [history]);

  return (
    <div className="relative w-full">
      <Helmet>
        <title>BOCS</title>
      </Helmet>

      <div className="absolute top-0 left-0 z-20 pt-8 pl-8">
        <Button key="submit" type="primary" onClick={close}>
          Go Back
        </Button>
      </div>

      <CreateHierarchyTree height={appContainerHeight} groupId={groupId} />
    </div>
  );
};

export default Home;
