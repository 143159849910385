import gql from 'graphql-tag';

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    id
    actor {
      id
      name
      profileImage
      email
    }
    author {
      id
      name
      profileImage
      email
    }
    read
    createdAt
    type
    message
    data
    project {
      id
    }
  }
`;

export const NOTIFICATIONS_QUERY = gql`
  query Notifications($page: Int!) {
    notifications(sort: "createdAt", order: "desc", page: $page, limit: 10) {
      stats {
        unread
        hasMore
        page
      }
      data {
        ...NotificationFragment
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const MARK_READ_NOTIFICATION_MUTATION = gql`
  mutation MarkReadNotification($notificationId: String!) {
    updateNotification(
      updateNotificationData: { read: true }
      id: $notificationId
    ) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const MARK_ALL_READ_NOTIFICATIONS_MUTATION = gql`
  mutation MarkAllReadNotifications {
    readAllNotifications {
      success
    }
  }
`;
