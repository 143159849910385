import React from 'react';
import { range, random } from 'lodash-es';
import clsx from 'clsx';

interface Props {
  columnCount: number;
  rowCount: number;
  className?: string;
  style?: React.CSSProperties;
}

const TableLoader: React.FC<Props> = ({
  columnCount,
  rowCount,
  className,
  style,
}) => {
  return (
    <div className={clsx('border', className)} style={style}>
      <div className="flex bg-gray-100 border-b">
        {range(columnCount).map((columnIndex) => (
          <div
            key={columnIndex}
            className={clsx(
              'flex-1 px-3 py-2',
              columnIndex !== columnCount - 1 ? 'border-r' : undefined,
            )}
          >
            <div className={clsx('h-3 skeleton', `w-${random(5, 11)}/12`)} />
          </div>
        ))}
      </div>
      {range(rowCount).map((rowIndex) => (
        <div
          className={clsx(
            'flex bg-white',
            rowIndex !== rowCount - 1 ? 'border-b' : undefined,
          )}
          key={rowIndex}
        >
          {range(columnCount).map((columnIndex) => (
            <div
              key={columnIndex}
              className={clsx(
                'flex-1 px-3 py-2',
                columnIndex !== columnCount - 1 ? 'border-r' : undefined,
              )}
            >
              <div className={clsx('h-3 skeleton', `w-${random(5, 11)}/12`)} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TableLoader;
