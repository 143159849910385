import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Upload, Icon, Button } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Omit } from 'utility-types';

import { Caption } from '../Typography';

interface Props
  extends Omit<
    React.ComponentProps<typeof Upload>,
    'action' | 'data' | 'onChange'
  > {
  onChange?: (files: Array<string>) => void;
}

const ImageUploader: React.FC<Props> = React.forwardRef(
  (
    { onChange, fileList: fileListProps, listType, ...restProps }: Props,
    ref: any,
  ) => {
    const [fileList, setFileList] = useState<Array<UploadFile>>(
      fileListProps || [],
    );

    useEffect(() => {
      if (onChange) {
        const isUploading = fileList.some(
          (file) => file.status === 'uploading',
        );
        const uploadedFiles = fileList
          .map((file) => file?.response?.url || file?.url)
          .filter((url) => !!url);
        if (!isUploading) {
          onChange(uploadedFiles);
        }
      }
    }, [fileList, onChange]);

    const renderUploader = restProps.multiple ? true : fileList.length < 1;

    return (
      <StyledUpload
        ref={ref}
        action={`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`}
        data={{
          upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        }}
        listType={listType || 'picture-card'}
        onChange={(event) => {
          const { fileList: inputFileList } = event;
          setFileList([
            ...inputFileList
              .filter((file) => file.status !== 'removed')
              .map((file) => {
                if (file.response) {
                  const isPDF = (file.response.url as string).endsWith('pdf');
                  if (isPDF) {
                    return {
                      ...file,
                      thumbUrl: `http://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/v1571570982/${file.response.public_id}.jpg`,
                    };
                  }
                }

                return file;
              }),
          ]);
        }}
        fileList={fileList}
        {...restProps}
      >
        {renderUploader ? (
          listType === 'picture-card' ? (
            <div>
              <Icon type="plus" />
              <UploadCaption>Upload</UploadCaption>
            </div>
          ) : (
            <Button type="link" icon="paper-clip" style={{ padding: 0 }}>
              Add Photos
            </Button>
          )
        ) : null}
      </StyledUpload>
    );
  },
);

ImageUploader.displayName = 'ImageUploader';

ImageUploader.defaultProps = {
  listType: 'picture-card',
};

export default ImageUploader;

const StyledUpload = styled(Upload)`
  ${(props) =>
    props.listType === 'picture'
      ? css`
          &&& {
            & .ant-upload-list {
              display: inline;
            }

            & .ant-upload-list-item,
            & .ant-upload.ant-upload-select-picture-card {
              width: 96px;
              height: 96px;
              padding: 0;
            }

            & .ant-upload-list-picture-card .ant-upload-list-item-progress {
              width: 100%;
            }

            &
              .ant-upload-list-picture-card
              .ant-upload-list-item-uploading-text {
              margin-top: 24px;
              margin-left: 8px;
            }
          }
        `
      : undefined}
`;

const UploadCaption = styled(Caption)`
  &&& {
    display: block;
  }
`;
