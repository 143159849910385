import React from 'react';

const PhysicalProgress: React.FC = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.981 3.336c0-.738.598-1.336 1.337-1.336H24v6.682a1.337 1.337 0 01-2.673 0V6.563l-7.372 6.923a1.336 1.336 0 01-1.86-.029L8.333 9.695l-8.07 8.069a4.556 4.556 0 011.049-4.727l6.077-6.163a1.337 1.337 0 011.896-.007l3.726 3.726 6.426-5.92h-2.12a1.336 1.336 0 01-1.336-1.337z"
      fill="currentColor"
    />
    <path
      d="M15.684 13.59l2.673-2.673v9.585c0 .738-.598 1.336-1.337 1.336h-1.336V13.59zM9.002 12.254l2.673 2.62v5.628c0 .738-.599 1.336-1.337 1.336H9.002v-9.584zM2.32 17.587c.055-.047.109-.097.162-.15l2.51-2.51v5.575c0 .738-.598 1.336-1.336 1.336H2.319v-4.251z"
      fill="currentColor"
    />
  </svg>
);

export default PhysicalProgress;
