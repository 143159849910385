import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Icon, Button, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import validator from 'validator';
import Axios from 'axios';
import { get } from 'lodash-es';

interface Props {
  form: WrappedFormUtils;
}

const ResetPasswordForm: React.FC<Props> = ({
  form: { validateFields, getFieldDecorator, resetFields },
}) => {
  useEffect(() => {
    return () => {
      message.destroy();
    };
  }, []);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          setLoading(true);
          try {
            // TODO: Update data structure
            const { data: success } = await Axios.post<string>(
              '/reset-password/initiate',
              {
                email: values.email,
                redirectUrl: `${window.location.origin}/update-password`,
              },
              { baseURL: process.env.REACT_APP_API_BASE_URL },
            );
            if (success) {
              message.success(
                'Please check your email for instructions to reset password',
                0,
              );
              resetFields();
            }
          } catch (error) {
            const errorMessage = get(
              error,
              'response.data.message',
              'Something went wrong. Please try again',
            );
            message.error(errorMessage);
          } finally {
            setLoading(false);
          }
        }
      });
    },
    [resetFields, validateFields],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator('email', {
          // @ts-ignore
          validate: [
            {
              trigger: 'onChange',
              rules: [
                {
                  required: true,
                  message: 'Email is required',
                },
              ],
            },
            {
              trigger: 'onBlur',
              rules: [
                {
                  validator: async (rules: any, value: string) => {
                    const isValid = validator.isEmail(value);

                    if (!isValid) {
                      throw new Error('Email must be a valid email');
                    }
                  },
                  message: 'Email must be a valid email',
                },
              ],
            },
          ],
          validateFirst: true,
        })(
          <Input
            placeholder="Email"
            prefix={<Icon type="user" className="opacity-50" />}
          />,
        )}
      </Form.Item>
      <Button
        type="primary"
        className="w-full"
        htmlType="submit"
        loading={loading}
      >
        Reset Password
      </Button>
    </Form>
  );
};

export default Form.create<Props>({ name: 'resetPassword' })(ResetPasswordForm);
