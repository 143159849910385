import React from 'react';
import { Dropdown, Menu, Icon, Modal } from 'antd';
import { Link } from 'react-router-dom';

import { Body } from '../../../components/Typography';
import useMedia from '../../../hooks/useMedia';

interface Props {
  groupId: string;
  groupName: string;
  deleteHierarchy: (id: string) => void;
}

const ProjectHierarchyCard: React.FC<Props> = ({
  groupId,
  groupName,
  deleteHierarchy,
}) => {
  const canEditProject = true;

  const { isDesktop } = useMedia();

  const infoModal = () => {
    Modal.info({
      title: 'Information',
      content: (
        <div>
          <p>Details can only be viewed in desktop mode!</p>
        </div>
      ),
      onOk() {},
    });
  };

  const content = (
    <div className="flex flex-col overflow-hidden bg-white rounded shadow cursor-pointer">
      <div className="flex justify-between p-4">
        <Body ellipsis strong>
          {groupName}
        </Body>
        {canEditProject ? (
          <span
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="opacity-50 hover:opacity-100"
          >
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    {isDesktop ? (
                      <Link to={`/project-tree/heirarchy/${groupId}`}>
                        Edit Project
                      </Link>
                    ) : (
                      <span onClick={infoModal}>Edit Project</span>
                    )}
                  </Menu.Item>
                  <Menu.Item onClick={() => deleteHierarchy(groupId)}>
                    Delete
                  </Menu.Item>
                  <Menu.Item onClick={() => 'assign'}>
                    Assign Projects
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Icon type="more" />
            </Dropdown>
          </span>
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      {isDesktop ? (
        <Link to={`/project-tree/heirarchy/${groupId}`}>{content}</Link>
      ) : (
        <div onClick={infoModal}>{content}</div>
      )}
    </>
  );
};

export default ProjectHierarchyCard;
