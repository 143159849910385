import React from 'react';
import { Spin } from 'antd';

interface Props {
  text?: string;
}

const Loader: React.FC<Props> = ({ text }) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <Spin tip={text} />
    </div>
  );
};

export default Loader;
